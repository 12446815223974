import React from "react";
import cx from "classnames";
import styles from "./screen.module.scss";
import trackingError from "../../../images/trackingError.svg";

const Screen = props => {
  const { map, tray, hideMap, tracking } = props;
  let enableMessageView = {};
  if (tracking) {
    enableMessageView = { height: "0vh" };
  } else {
    enableMessageView = { height: "50vh" };
  }

  return (
    <>
      {tracking ? (
        <div className={styles.mapContainer} style={enableMessageView}>
          <div className={styles.shiftTrackingTray} />
          <img
            src={trackingError}
            style={{ marginTop: "4vh" }}
            alt="Tracking Error Image"
          />
        </div>
      ) : null}

      <div className={cx(styles.mapContainer, hideMap ? styles.hideMap : "")}>
        <div className={styles.mapContainer} style={enableMessageView}>
          {map()}
        </div>
      </div>
      <div
        className={cx(
          styles.trackingTray,
          tracking ? styles.shiftTrackingTray : ""
        )}
      >
        {tray()}
      </div>
    </>
  );
};

export default Screen;
