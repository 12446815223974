import React, { Component } from "react";
import { Modal, Tabs } from "antd";
import "antd/dist/antd.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselTab from "../CarouselVideo";

const { TabPane } = Tabs;

class ViewAttachments extends Component {
  state = {
    loading: true,
    bookings: [],
    images: [],
    audio: [],
    selectedRowKeys: [],
    video: [],
    editBooking: false,
    bookingToEdit: {},
    bookingEdit: false,
    viewAttachments: false
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const { task } = this.props;

      if (task && task.taskMaster && task.taskMaster.attachmentType) {
        const images = [],
          audio = [],
          video = [],
          taskAttachmentBody = {};
        taskAttachmentBody.type = task.taskMaster.attachmentType;
        taskAttachmentBody.path = task.attachment;
        if (task.taskMaster.attachmentType === "image") {
          images.push(taskAttachmentBody);
        } else if (task.taskMaster.attachmentType === "audio") {
          audio.push(taskAttachmentBody);
        } else if (task.taskMaster.attachmentType === "video") {
          video.push(taskAttachmentBody);
        }

        let defaulttab =
          images.length > 0
            ? "1"
            : audio.length > 0
            ? "2"
            : video.length > 0
            ? "3"
            : "1";

        this.setState({
          isTask: true,
          loading: false,
          images,
          video,
          audio,
          activeKey: defaulttab
        });
      } else if (!task.taskMaster) {
        const images = [],
          audio = [],
          video = [];
        for (let i = 0; i < task.attachments.length; i++) {
          if (task.attachments[i].type === "image") {
            images.push(task.attachments[i]);
          } else if (task.attachments[i].type === "audio") {
            audio.push(task.attachments[i]);
          } else if (task.attachments[i].type === "video") {
            video.push(task.attachments[i]);
          }
        }

        let defaulttab =
          images.length > 0
            ? "1"
            : audio.length > 0
            ? "2"
            : video.length > 0
            ? "3"
            : "1";

        this.setState({
          isTask: "false",
          loading: false,
          images,
          video,
          audio,
          activeKey: defaulttab
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
    }
  }
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
  render() {
    const {
      loading,
      viewAttachments = false,
      isTask,
      activeKey,
      images,
      video,
      audio
    } = this.state;

    if (viewAttachments) {
      this.props.onCancel();
    }

    return (
      <Modal
        visible={true}
        onCancel={this.props.onCancel}
        footer={null}
        header={null}
        width={"50%"}
      >
        {loading ? (
          <h1>loading ...</h1>
        ) : (
          <>
            <Tabs
              activeKey={activeKey}
              onChange={key => this.setState({ activeKey: key })}
              centered
            >
              <TabPane
                tab={"Images (" + images.length + ")"}
                key="1"
                renderItem={this.customRenderItem}
              >
                <CarouselTab
                  activeKey={this.state.activeKey}
                  isTask={isTask}
                  data={images}
                />
              </TabPane>
              <TabPane
                tab={"Audio (" + audio.length + ")"}
                key="2"
                renderItem={this.customRenderItem}
              >
                <CarouselTab
                  activeKey={this.state.activeKey}
                  isTask={isTask}
                  data={audio}
                />
              </TabPane>
              <TabPane
                tab={"Video (" + video.length + ")"}
                key="3"
                renderItem={this.customRenderItem}
              >
                <CarouselTab
                  activeKey={this.state.activeKey}
                  isTask={isTask}
                  data={video}
                />
              </TabPane>
            </Tabs>
          </>
        )}
      </Modal>
    );
  }
}

export default ViewAttachments;
