import React from "react";
import cx from "classnames";
import { getHoursAndMinFromMillis, getWayPoints } from "../../utils/common";
import Logo from "../../images/logo-zingbus.svg";
import styles from "./header.module.scss";

const OldHeader = props => {
  const { showEta, eta, busRoute, booking, upcomingStation, busMissed } = props;

  let title, etaTime, nextStation;

  if (showEta) {
    title = "Reaching your Destination in";
    etaTime = eta.early
      ? Math.abs(
          booking.dropPoint.time -
            upcomingStation.reachingTime +
            eta.etaSec * 1000
        )
      : eta.delay
      ? Math.abs(
          booking.dropPoint.time -
            upcomingStation.reachingTime +
            eta.etaSec * 1000
        )
      : Math.abs(booking.dropPoint.time - Date.now());

    if (busMissed) {
      title = "You missed your bus, you can catch your bus from";
      nextStation = upcomingStation.station.name;
      etaTime = eta.delay
        ? Math.abs(eta.etaSec * 1000)
        : Math.abs(booking.boardingPoint.time - Date.now());
    } else if (!booking.isOnboarded) {
      title = "Arriving at Pickup Station in";
      etaTime = eta.early
        ? Math.abs(
            booking.boardingPoint.time -
              upcomingStation.reachingTime +
              eta.etaSec * 1000
          )
        : eta.delay
        ? Math.abs(
            booking.boardingPoint.time -
              upcomingStation.reachingTime +
              eta.etaSec * 1000
          )
        : Math.abs(booking.boardingPoint.time - Date.now());
    } else {
      const wayPoints = getWayPoints(
        busRoute,
        upcomingStation.stationId,
        booking.dropPoint.stationId
      );

      for (let i = 0; i < wayPoints.length; ++i) {
        const station = wayPoints[i];

        if (station.type === "RESTSTOP") {
          title = "Rest Stop Arriving in";
          etaTime = eta.delay
            ? Math.abs(
                station.reachingTime -
                  upcomingStation.reachingTime +
                  eta.etaSec * 1000
              )
            : Math.abs(station.reachingTime - Date.now());
          break;
        }
      }
    }
  }

  return (
    <div id="header" className={cx(styles.header, "pb-2")}>
      {!showEta || booking.trip.isTrackingWorking ? (
        <img width="25%" src={Logo} alt="zingbus logo" />
      ) : busMissed ? (
        <div>
          {`${title}`}
          <br />
          {`${nextStation} in `}
          {getHoursAndMinFromMillis(etaTime)}
        </div>
      ) : (
        <div className={styles.etaContainer}>
          <div className={styles.row}>
            <div className={styles.stationDetails}>
              {`${title}`}
              <br />
              <div>{getHoursAndMinFromMillis(etaTime)}</div>
            </div>
            {eta.delay ? (
              <div className="red">
                Delay
                {/* <br />
                 <div>{getHoursAndMinFromMillis(eta.delayTimeSec * 1000)}</div> */}
              </div>
            ) : eta.early ? (
              <div className="green">Early</div>
            ) : (
              <div className="blue">On Time</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OldHeader;

{
  /* <div>
        {`${title}`}
        <br />
        {`${nextStation} in `}
        {getHoursAndMinFromMillis(etaTime)}
      </div> */
}
