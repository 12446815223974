import React, { Component } from "react";
import moment from "moment";
import { Modal, Button, Select, message, Input, DatePicker } from "antd";
import Fetch from "../../utils/fetch";
import jwt from "jsonwebtoken";

const { Option } = Select;

class EditTask extends Component {
  state = {
    loading: true,
    taskUploadLoader: false,
    station: [],
    date: "",
    selectedFile: null,
    selectedStationId: "",
    input: "",
    comment: ""
  };
  token = localStorage.getItem("idToken");
  decodedToken = jwt.decode(this.token);

  async componentDidMount() {
    try {
      this.setState({ loading: false });
    } catch (err) {
      message.error(err);
    }
  }

  updateTaskClose = async () => {
    try {
      const { task } = this.props;
      let { input, selectedFile, comment } = this.state;
      if (task.taskMaster.attachmentType && selectedFile == null) {
        message.error("Please Upload Valid File");
        return;
      }
      this.setState({ taskUploadLoader: true });
      const formData = new FormData();

      formData.append("myFile", this.state.selectedFile);
      formData.append("attachmentType", task.taskMaster.attachmentType);
      formData.append("action", input);
      formData.append("closeComment", comment);
      formData.append("statusFilter", this.props.taskStatusFilter);
      formData.append("userprivileges", this.decodedToken.privileges);
      formData.append(
        "attachmentRequired",
        task.taskMaster.isAttachmentRequired
      );
      formData.append("taskId", task._id);

      const url = `/bus-tracker/updateTaskClose`;

      const options = {
        method: "post",
        data: formData,
        headers: { "content-type": "multipart/form-data" }
      };

      let response = await Fetch(url, options);
      if (response) {
        this.setState({ taskUploadLoader: false });
        if (Array.isArray(response)) {
          message.success("Task Updated");
          this.props.onTaskUpdate(response);
        } else {
          message.error(response);
        }
        // this.props.onTaskUpdate(response);
        this.afterUpdate();
      }
    } catch (err) {
      this.setState({ taskUploadLoader: false });

      console.log(err);
      message.error(err);
    }
  };
  updateTask = async () => {
    try {
      const { editField, task } = this.props;
      const { input, date } = this.state;
      const url = `/bus-tracker/updateTask`;

      const token = localStorage.getItem("idToken");
      const decodedToken = jwt.decode(token);

      let objforupdate,
        obj = [];

      if (editField === "comments") {
        objforupdate = {
          id: task._id,
          comments: input
        };
      } else if (editField === "status") {
        objforupdate = {
          id: task._id ? task._id : null,
          responseByUserId: decodedToken.id,
          responseDate: new Date().getTime(),
          status: input
        };
      } else if (editField === "tat") {
        objforupdate = {
          id: task._id ? task._id : null,
          etaByUserId: decodedToken.id,
          etaDate: new Date(date).setHours(0, 0, 0, 0),
          etaSubmissionDate: new Date().getTime()
        };
      }
      obj.push(objforupdate);
      console.log("obj", obj);
      const options = {
        method: "patch",
        data: {
          serviceId: task.service ? task.service._id : null,
          auditId: task.audit ? task.audit._id : null,
          generationDate: task.generationDate,
          busId: task.busId,
          obj: obj,
          pageNo: this.props.pageNumber
        }
      };

      const response = await Fetch(url, options);
      let editedTask = Math.random();
      if (response) {
        message.success("Task Updated");
        this.props.onTaskUpdate(response, editedTask);
        this.afterUpdate();
        // window.location.reload(false);
      }
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  afterUpdate = () => {
    const { onChange, closeParentModal } = this.props;
    onChange({
      editField: false,
      taskToEdit: {}
    });

    closeParentModal();
  };
  disabledDate(current) {
    // Can not select days before today and today
    return (
      current &&
      current <
        moment()
          .subtract(1, "days")
          .endOf("day")
    );
  }

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  fileData = () => {
    const { selectedFile } = this.state;
    const { task } = this.props;

    if (selectedFile !== null) {
      if (
        !selectedFile.type.includes(
          task.taskMaster.attachmentType.toLowerCase()
        )
      ) {
        this.setState({ selectedFile: null });
        message.error(`please select ${task.taskMaster.attachmentType}`);
      } else if (selectedFile.type.includes("mpeg")) {
        this.setState({ selectedFile: null });
        message.error(`File Format Not supported`);
      }
    }
  };

  render() {
    const { date, loading, input, taskUploadLoader, comment } = this.state;
    const { task, editField } = this.props;
    const name = `EDIT ${editField}`;

    return (
      <Modal
        title={name}
        visible={true}
        onCancel={this.props.onCancel}
        footer={null}
      >
        {loading ? (
          "...Loading"
        ) : (
          <>
            {editField === "tat" ? (
              <>
                {/* <Input
                  type={"number"}
                  value={input}
                  placeholder="Enter Number of Days"
                  style={{ width: 200 }}
                  onChange={e => this.handleChange("input", e.target.value)}
                /> */}
                <DatePicker
                  style={{ width: 200 }}
                  disabledDate={this.disabledDate}
                  value={date}
                  // onSelect={e => this.handleChange("input", e.target.value)}
                  onChange={date => this.setState({ date })}
                />

                <Button type="primary" onClick={this.updateTask}>
                  Update
                </Button>
              </>
            ) : null}
            {editField === "status" ? (
              <>
                <Select
                  style={{ width: 200 }}
                  onChange={value => this.handleChange("input", value)}
                >
                  <Option value="OPEN">OPEN</Option>
                  <Option value="CLOSED">CLOSED</Option>
                  <Option value="REJECTED">REJECTED</Option>
                  <Option value="NOTAPPLICABLE">NOTAPPLICABLE</Option>
                </Select>

                <Button type="primary" onClick={this.updateTask}>
                  Update
                </Button>
              </>
            ) : null}
            {editField === "Task Status" ? (
              <>
                <div>
                  <Select
                    style={{ width: 200, margin: "20px" }}
                    onChange={value => this.handleChange("input", value)}
                  >
                    <Option value="CLOSED">CLOSED</Option>
                    <Option value="REJECTED">REJECTED</Option>
                  </Select>
                </div>

                {task.taskMaster.isAttachmentRequired ? (
                  <div style={{ margin: "20px" }}>
                    {task.taskMaster.attachmentType} upload :
                    <input type="file" onChange={this.onFileChange} />
                  </div>
                ) : null}
                {this.fileData()}
                <div style={{ padding: 20 }}>
                  <Input.TextArea
                    rows={4}
                    value={comment}
                    placeholder="Comments"
                    onChange={e => this.handleChange("comment", e.target.value)}
                  />
                </div>
                <Button
                  type="primary"
                  loading={taskUploadLoader}
                  onClick={this.updateTaskClose}
                >
                  Update
                </Button>
              </>
            ) : null}
            {editField === "comments" ? (
              <>
                <Input
                  type={"text"}
                  value={input}
                  style={{ width: 200 }}
                  onChange={e => this.handleChange("input", e.target.value)}
                />
                <Button type="primary" onClick={this.updateTask}>
                  Update
                </Button>
              </>
            ) : null}
          </>
        )}
      </Modal>
    );
  }
}

export default EditTask;
