const prodServerURL = "https://tracer.zingbus.com/v1";
const devServerURL = "http://localhost:8030/v1";

const prodSocketURL = "https://tracer.zingbus.com/";
const devSocketURL = "http://localhost:8030/";

export const googleKey = `AIzaSyAh9Ud5KvyyCsLC_BwK520oVbz8AVWZpgw`;

export const openUser = "1111111111";
export const openPass = "c0f47695200d9ce4d04ed0631933309c";
export const busRoute = [
  { lat: 28.640034, lng: 77.207143 },
  { lat: 28.700354, lng: 77.227587 },
  { lat: 32.716541, lng: 74.744376 },
  { lat: 30.736363, lng: 76.778141 },
  { lat: 30.716541, lng: 76.744376 },
  { lat: 32.244174, lng: 77.190004 },
];

const { NODE_ENV } = process.env;

export let baseURL = prodServerURL;
export let socketURL = prodSocketURL;
export const marketingCardsPriority = ["PLATINUM", "GOLD", "SILVER", "BRONZE"];
export const b2cURL = "https://www.zingbus.com";
export const customerIssue = {
  marshalBehaviour: "5f13fbaecb93264b07e5d702",
  healthEmergency: "5f13fbaecb93264b07e5d703",
  contactPolice: "5f13fbaecb93264b07e5d704",
};
export const majnuKaTilaId = [
  "5d84c3657f0d66618bd3e99b",
  "5da0617860670321b658f7d5"
];
export const servicesToCheck = [
  "6022d63ad706921e480195fb", //Delhi - Manali 08:00 PM //live
  "61a3af69d3183758bc95d7f6", //Jaipur - Delhi 12:20 PM //live
  "61af1a6e5b87ad6f9e49cffd", //Delhi - Manali 04:00 PM //live
  "612dea3d85d449180c2ba50b", //Mumbai - Goa 05:00 PM //live
  "5daac5383d936026a74de0bb" //sonipat-panipat 11;45 //live
];
export const foodCarouselId = "614ff24afd87bb795bbfad14"; //Dec2 carousel

export const mixPanelToken = "acac5bf7cf4ccc3f795e31be3d4d13f1";
export const isStage = false;
export const assuredRefundId = "64b9223203ddc8bf44a776c9";

export const gdsLoungeName = [
  {
    _id: "65c4d0106d00da1a4f541029",
    value: "Zingbus Lounge Akshardham"
  },
  {
    _id: "65d86354d154130b7ec5cf08",
    value: "Zingbus lounge Kashmere Gate"
  },
  {
    _id: "5da0617860670321b658f7d5",
    value: "Zingbus Lounge Majnu ka Tilla"
  }
];

export const whatsAppTemplates = [
  "pickup_update_lt_24_hours_v1",
  "not_boarded_v1",
  "tech_related_issues_v1",
  "rest_stop_change_v1",
  "route_change_v1",
  "charging_point_wifi_v1",
  "ac_not_working_v1",
  "breakdown_v2",
  "crew_misbehaviour_v1",
  "share_marshal_number_v1",
  "drop_point_update_v1",
  "delay_in_drop_v1",
  "delay_in_pickup_v1",
  "connecting_booking_modify_v1",
  "trip_assignment_2nd_leg_v1",
  "trip_assignment_1st_leg_v1",
  "booking_confirm_connecting_v1",
  "after_24_hours_cancellation_v3",
  "booking_modify_seat_change_v1",
  "booking_modify_postponed_v1",
  "booking_modify_preponed_v1",
  "before_24_hours_cancellation_v3",
  "trip_assignment_v7",
  "booking_confirmation_v8_1"
];

export const SMSTemplates = [
  "pickup_update_lt_24_hours_v1",
  "not_boarded_v1",
  "tech_related_issues_v1",
  "rest_stop_change_v1",
  "route_change_v1",
  "charging_point_wifi_v1",
  "ac_not_working_v1",
  "breakdown_v2",
  "crew_misbehaviour_v1",
  "share_marshal_number_v1",
  "drop_point_update_v1",
  "delay_in_drop_v1",
  "delay_in_pickup_v1",
  "connecting_booking_modify_v1",
  "trip_assignment_2nd_leg_v1",
  "trip_assignment_1st_leg_v1",
  "booking_confirm_connecting_v1",
  "after_24_hours_cancellation_v3",
  "booking_modify_seat_change_v1",
  "booking_modify_postponed_v1",
  "booking_modify_preponed_v1",
  "before_24_hours_cancellation_v3",
  "trip_assignment_v7",
  "booking_confirmation_v8_1"
];
export const issueExpireTime = 2 * 60 * 1000;
export const prodServer = true;
