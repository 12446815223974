import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Fetch from "../../utils/fetch";
import { tokenIsExpired } from "../../utils/jwtUtils";

const ProtectedComponentWrapper = ComponentToProtect => {
  return class ProtectedComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isValidToken: false,
        loading: true,
        redirect: false
      };
    }

    async componentDidMount() {
      const token = localStorage.getItem("idToken");
      if (token && !tokenIsExpired(token)) {
        // Make request to server to validate token
        const options = {
          method: "get"
        };
        const response = await Fetch("/user/validjwt", options);
        if (response) {
          this.setState({ isValidToken: true, loading: false });
        } else {
          this.setState({ loading: false, redirect: true });
        }
      } else {
        // Redirect to login
        this.setState({ loading: false, redirect: true });
      }
    }

    render() {
      const { loading, isValidToken, redirect } = this.state;
      const { location } = this.props;

      if (loading) {
        return null;
      }

      if (redirect) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: `${location.pathname}${location.search}` }
            }}
          />
        );
      }

      if (isValidToken) {
        return (
          <>
            <ComponentToProtect {...this.props} />
          </>
        );
      }
    }
  };
};

export default ProtectedComponentWrapper;
