import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../images/logo-zingbus.svg";

const WebHeader = props => {
  const { onLogoClick = null } = props;
  const [logout, updateLogoutStatus] = useState(false);
  function refreshPage() {
    window.location.reload(false);
  }
  if (logout) {
    localStorage.removeItem("idToken");
    return (
      <Redirect
        to={{
          pathname: "/login"
        }}
      />
    );
  }

  return (
    <>
      <header>
        <div id="webHeader">
          <div className="webHeaderImageContainer">
            {typeof onLogoClick === "function" ? (
              <span onClick={refreshPage} style={{ cursor: "pointer" }}>
                <img src={Logo} alt="zingbus logo" />
              </span>
            ) : (
              <Link to="/">
                <img src={Logo} alt="zingbus logo" />
              </Link>
            )}
          </div>
          <div className="webHeaderOptionContainer">
            <span type="link" onClick={() => updateLogoutStatus(true)}>
              Logout
            </span>
          </div>
        </div>
      </header>
      <hr />
    </>
  );
};

export default WebHeader;
