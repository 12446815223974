import React from "react";
import styles from "./overlay.module.scss";
import BackIcon from "../../../images/back.png";

const OverlayScreen = props => {
  const { toggleOverlayScreen, overlayTitle } = props;

  return (
    <div className={styles.overlayContainer}>
      <div className={styles.header}>
        <button onClick={() => toggleOverlayScreen(false, null)}>
          <img src={BackIcon} alt="back" />
        </button>
        <span>{overlayTitle}</span>
      </div>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export default OverlayScreen;
