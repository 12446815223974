import React, { Component } from "react";
import {
  Button,
  Tabs,
  Input,
  Checkbox,
  Select,
  Col,
  Row,
  Modal,
  message
} from "antd";
import Fetch from "../../utils/fetch";
import { whatsAppTemplates, SMSTemplates } from "../../config";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

class SendMessage extends Component {
  state = {
    showMessageOption: false,
    customMessage: "",
    sendSMSMessage: false,
    sendWhatsAppMessage: false,
    templateType: "----Select Message----",
    trackingLink: false,
    zingcashCredit: false
  };

  handleChange = (key, value) => {
    if (value == "") {
      value = null;
    }
    this.setState({ [key]: value });
  };

  toggleMessageOption = () => {
    let { selectedCount, selectedBookings } = this.props;

    if (!selectedBookings) {
      return message.error("Please select booking ");
    }

    if (!selectedCount) {
      selectedCount = selectedBookings.length;
    }

    const { showMessageOption } = this.state;

    this.setState({ showMessageOption: !showMessageOption });
  };

  customWhatsAppMessage = templateType => {
    return (
      <>
        <Select
          className="mb-1"
          style={{ width: "500px" }}
          defaultValue="----Select Message----"
          onChange={value => this.setState({ templateType: value })}
        >
          <Option value="select">----Select Message----</Option>

          <Option value="booking_confirm">Booking Confirm</Option>
          <Option value="booking_cancel">Booking Cancel</Option>
          <Option value="assignment">Booking Assigment</Option>
          <Option value="delay">Delay</Option>
          <Option value="boarding"> Boarding</Option>
        </Select>
        <br />

        {templateType === "booking_confirm" ? (
          <div>
            Your zingbus ticket is confirmed.
            <br />
            Partner Operator:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            <br />
            Route:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            <br />
            PNR:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
            <br />
            Journey Date & Time::{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var5", e.target.value)}
            />{" "}
            ,{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var6", e.target.value)}
            />{" "}
            <br />
            Seat No:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var7", e.target.value)}
            />{" "}
            <br />
            Pickup Point Address:{" "}
            <TextArea
              placeholder="maxLength is 90"
              maxLength={90}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var8", e.target.value)}
            />{" "}
            <br />
            Marshal Number: Will be shared 4 hours before Journey
            <br />
            Zingbus Contact Number:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var9", e.target.value)}
            />
            {"(WhatsApp)"}
            <br />
            {"Team Zingbus"}
          </div>
        ) : null}

        {templateType === "booking_cancel" ? (
          <div>
            Your zingbus ticket with PNR -{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            has been cancelled.Applicable refund will be processed within 5-7
            days.
            <br />
            For any refund related queries, Please connect with us over the
            WhatsApp:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />
            {"(WhatsApp)"}
            <br />
            {"Team Zingbus"}
          </div>
        ) : null}

        {templateType === "assignment" ? (
          <div>
            zingbus Journey Details:
            <br />
            Partner Operator:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            <br />
            Route:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            <br />
            Bus No:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
            <br />
            Journey Date & Time::{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var5", e.target.value)}
            />{" "}
            ,{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var6", e.target.value)}
            />{" "}
            <br />
            PNR:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var7", e.target.value)}
            />{" "}
            <br />
            Seat No:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var8", e.target.value)}
            />{" "}
            <br />
            Pickup Address:{" "}
            <TextArea
              placeholder="maxLength is 90"
              maxLength={90}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var9", e.target.value)}
            />{" "}
            <br />
            Track your bus:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var10", e.target.value)}
            />
            Marshal Number:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var11", e.target.value)}
            />
            Cancellation & Refund Policy:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var12", e.target.value)}
            />
            Zingbus Contact Number:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var13", e.target.value)}
            />
            {"(WhatsApp)"}
            <br />
            {"Team Zingbus"}
          </div>
        ) : null}

        {templateType === "delay" ? (
          <div>
            We regret the delay in bus & crew details for upcoming journey.
            We're working on your upcoming trip and details will be shared
            shortly. We appreciate your patience in this regard.
            <br />
            {"Team Zingbus"}
          </div>
        ) : null}

        {templateType === "boarding" ? (
          <div>
            We hope you have boarded the Bus. In case of any query, Please reach
            out to us at WhatsApp on https://wa.me/+918287009889
            <br />
            {"Team Zingbus"}
          </div>
        ) : null}
      </>
    );
  };

  handleSendClick = () => {
    const {
      var1,
      var2,
      var3,
      var4,
      var5,
      var6,
      var7,
      var8,
      var9,
      var10,
      var11,
      var12,
      var13,
      templateType,
      sendSMSMessage,
      sendWhatsAppMessage
    } = this.state;
    const { onClick } = this.props;
    let vars = [],
      smsVariables = [];
    let parts = null;

    if (!sendSMSMessage && !sendWhatsAppMessage) {
      message.error("please select the platform for message delivery");
      return;
    }

    if (templateType === "booking_confirm") {
      vars = [var1, var2, var3, var4, var5, var6, var7, var8, var9];
      parts = var8.match(/[\s\S]{1,30}/g) || [];
      if (parts[1] === undefined) {
        parts[1] = "";
        parts[2] = "";
      }
      smsVariables = [
        var1,
        var2,
        var3,
        var4,
        var5,
        var6,
        var7,
        parts[0],
        parts[1],
        parts[2],
        var9
      ];
    }
    if (templateType === "booking_cancel") {
      vars = [var1, var2];
      smsVariables = [var1, var2];
    }
    if (templateType === "assignment") {
      vars = [
        var1,
        var2,
        var3,
        var4,
        var5,
        var6,
        var7,
        var8,
        var9,
        var10,
        var11,
        var12,
        var13
      ];
      parts = var9.match(/[\s\S]{1,30}/g) || [];
      if (parts[1] === undefined) {
        parts[1] = "";
        parts[2] = "";
      }
      smsVariables = [
        var1,
        var2,
        var3,
        var4,
        var5,
        var6,
        var7,
        var8,
        parts[0],
        parts[1],
        parts[2],
        var10,
        var11,
        var12,
        var13
      ];
    }

    onClick(
      templateType,
      vars,
      smsVariables,
      sendSMSMessage,
      sendWhatsAppMessage
    );

    this.setState({
      vars: [],
      var1: null,
      var2: null,
      var3: null,
      var4: null,
      var5: null,
      var6: null,
      var7: null,
      var8: null,
      var9: null,
      var10: null,
      var11: null,
      var12: null,
      var13: null,
      showMessageOption: false,
      templateType: "",
      sendSMSMessage: false,
      sendWhatsAppMessage: false,
      trackingLink: false
    });
  };

  render() {
    const {
      showMessageOption,
      templateType,
      sendSMSMessage,
      sendWhatsAppMessage
    } = this.state;

    return (
      <div>
        {showMessageOption ? (
          <Modal
            title="Send Message"
            footer={null}
            visible={true}
            centered
            width={"80%"}
            onCancel={() =>
              this.setState({
                showMessageOption: false,
                templateType: "",
                zingcashCreditValue: "",
                zingcashCredit: false
              })
            }
          >
            <div>{this.customWhatsAppMessage(templateType)}</div>
            <br />
            <Checkbox
              checked={sendWhatsAppMessage}
              onChange={e =>
                this.setState({
                  sendWhatsAppMessage: e.target.checked
                })
              }
            >
              WhatsApp
            </Checkbox>
            <Checkbox
              checked={sendSMSMessage}
              onChange={e =>
                this.setState({
                  sendSMSMessage: e.target.checked
                })
              }
            >
              SMS
            </Checkbox>
            <br />
            <Button
              type="primary"
              onClick={this.handleSendClick}
              style={{ margin: "1% 0" }}
            >
              Send
            </Button>
          </Modal>
        ) : (
          <Button type="primary" onClick={this.toggleMessageOption}>
            Send Message
          </Button>
        )}
      </div>
    );
  }
}

export default SendMessage;
