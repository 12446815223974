import React, { useState } from "react";
import cx from "classnames";
import support from "../../../../images/support.png";
import phoneNumber from "../../../../images/phoneImage.svg";
import styles from "./utils.module.scss";
import moment from "moment";
import { googleLayerAnalytics } from "../../../../utils/googleDataLayer";
import mixpanel from "mixpanel-browser";
import { mixPanelToken, isStage } from "../../../../config";
import Fetch from "../../../../utils/fetch";

import { useHistory } from "react-router-dom";
import { Modal, Input } from "antd";
const { TextArea } = Input;

const Utils = props => {
  const [isCallBackModalVisible, setCallBackModalVisible] = useState(false);
  const [showCallBackConfirmation, setCallBackConfirmation] = useState(false);

  const [reason, setCallBackReason] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { booking, toggleOverlayScreen, busType, isMaxService } = props;
  const history = useHistory();

  const handleOnClick = () => {
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Scan QR Code Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD")
    });
    toggleOverlayScreen(true, showQRCode, "QR Code");
    history.push("qr");
  };
  const showQRCode = () => (
    <img src={booking.boardingQr} width="100%" height="100%" />
  );

  const openWhatsApp = () => {
    googleLayerAnalytics("initiate_chat", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD")
    });
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Chat Support Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD")
    });
    window.open(
      "https://api.whatsapp.com/send?phone=918287009889&text=zingbus"
    );
  };
  const callMarshall = () => {
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Call Marshall Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD")
    });
    if (booking.boardingPoint.time - 3 * 60 * 60 * 1000 < Date.now()) {
      window.open("tel:+91-8287009889");
    } else {
      setIsModalVisible(true);
    }
  };

  const openCallBack = () => {
    setCallBackModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const sendEmail = async () => {
    setCallBackModalVisible(false);

    const body = {
      PNR: booking.bookingCode,
      Trip: booking.trip.service.name,
      "Pickup Time": moment(booking.boardingPoint.time).format("lll"),
      "Pickup Station": booking.boardingPoint.name,
      "From City": booking.boardingPoint.city,
      "To City": booking.dropPoint.city,
      "Request Reason": reason
    };
    const options = {
      method: "post",
      data: {
        body
      }
    };
    const response = await Fetch("/user/sendEmail", options);
    setCallBackConfirmation(true);
  };
  return (
    <div
      style={{
        padding: "4%"
      }}
    >
      <li className="row">
        <div
          onClick={callMarshall}
          className="col-4 border-right"
          style={{
            fontSize: "13px",
            padding: "0px",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            fontFamily: "SFProText"
          }}
        >
          <img style={{ margin: "5%" }} src={phoneNumber} alt="phn Image" />
          {busType == "SUV" || busType == "TEMPOTRAVELLER"
            ? "Call Driver"
            : "Call Marshal"}{" "}
        </div>
        {isMaxService ? (
          <div
            className="col-5 border-right"
            onClick={openCallBack}
            style={{
              padding: "0px",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              fontFamily: "SFProText"
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                fontFamily: "SFProText"
              }}
            >
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/selfHelp/max/callBack.svg"
                style={{ paddingRight: "5px" }}
                alt="CallSupport Image"
              />
              <div
                style={{
                  marginLeft: "1px",
                  fontSize: "13px",
                  fontFamily: "SFPROTEXT"
                }}
              >
                Request Callback
              </div>
            </div>
            <div style={{ fontSize: "9px", fontFamily: "SFProText" }}>
              Get callback in 5 mins
            </div>
          </div>
        ) : (
          <div
            className="col-5 border-right"
            onClick={openWhatsApp}
            style={{
              padding: "0px",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              fontFamily: "SFProText"
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                fontFamily: "SFProText"
              }}
            >
              <img
                src={support}
                style={{ paddingRight: "5px" }}
                alt="CallSupport Image"
              />
              <div
                style={{
                  marginLeft: "1px",
                  fontSize: "13px",
                  fontFamily: "SFPROTEXT"
                }}
              >
                Support
              </div>
            </div>
            <div style={{ fontSize: "9px", fontFamily: "SFProText" }}>
              Get reply within 30 sec
            </div>
          </div>
        )}
        <div
          className="col-3"
          style={{
            padding: "0px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            fontFamily: "SFProText"
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "13px",
              fontFamily: "SFPROTEXT"
            }}
          >
            OTP: {booking.boardingOtp}
          </div>
          <button
            className="blue"
            style={{
              fontSize: "9px",
              backgroundColor: "transparent",
              backgroundRepeat: "no-repeat",
              border: "none",
              cursor: "pointer",
              overflow: "hidden",
              outline: "none",
              width: "100%"
            }}
            onClick={handleOnClick}
          >
            Scan QR code
          </button>
        </div>
      </li>
      <div style={{ width: "20px" }}>
        <Modal
          className={styles.modal}
          centered
          width={"80%"}
          visible={isModalVisible}
          onOk={handleOk}
          closable={false}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{
            style: {
              background: "#7b2bff",
              right: "40%",
              border: "none"
            }
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontFamily: "SFProText",
              textAlign: "center",
              fontWeight: "400"
            }}
          >
            Hey, {booking.name}! Our Marshal's contact Number will be active at{" "}
            {moment(booking.boardingPoint.time - 3 * 60 * 60 * 1000).format(
              "Do MMMM YYYY, h:mm:ss a"
            )}
          </p>
        </Modal>
      </div>

      {isCallBackModalVisible ? (
        <div className={styles.fullscreen}>
          <div className={styles.callBackModal}>
            <div>
              <span>How can we help you?</span>
              <span onClick={() => setCallBackModalVisible(false)}>X</span>
            </div>
            <div>
              <span>Reason for requesting callback</span>
              <TextArea
                placeholder="Type Reason here (optional)"
                className="mb-1"
                autoSize={{ minRows: 4, maxRows: 4 }}
                onChange={e => setCallBackReason(e.target.value)}
              />{" "}
            </div>
            <div>
              <button onClick={sendEmail}>Request Callback</button>
            </div>
          </div>
        </div>
      ) : null}
      {showCallBackConfirmation ? (
        <Modal
          centered
          width={"100%"}
          visible={showCallBackConfirmation}
          footer={null}
          closable={false}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div className={styles.confirmationCallBackModal}>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/selfHelp/max/check.svg"
              alt="done"
            />
            <p>Callback Request Successfully Registered</p>
            <span>Our team will call you within the next 5 minutes</span>
            <button onClick={() => setCallBackConfirmation(false)}>
              Okay, Got It
            </button>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Utils;
