import React, { Component } from "react";
import moment from "moment";

import { Button, DatePicker, Select, message, AutoComplete, Input } from "antd";
import Fetch from "../../utils/fetch";
import { ThunderboltFilled } from "@ant-design/icons";
const { Search } = Input;

const { Option } = Select;

class crewTicketSearch extends Component {
  state = {
    showDatePicker: false
  };

  async componentDidMount() {
    try {
      const complains = await this.fetchComplainCategory();
      const serviceHub = await this.fetchServiceHub();
      this.setState({ complains, serviceHub });
    } catch (err) {
      message.error(err);
    }
  }

  fetchComplainCategory = async () => {
    try {
      this.setState({ cancelLoader: true });
      const url = `/bus-tracker/callCategory/crewCategory`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        this.setState({ cancelLoader: false });
        return response;
      } else {
        this.setState({ cancelLoader: false });
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  fetchServiceHub = async () => {
    try {
      this.setState({ serviceHub: true });
      const url = `/bus-tracker/serviceHub`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        this.setState({ cancelLoader: false });
        return response;
      } else {
        this.setState({ cancelLoader: false });
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  fetchComplainOnStatus = key => {
    this.setState({ complainStatus: key });
  };

  fetchComplainOnHub = key => {
    this.setState({ hub: key });
  };

  fetchComplainOnRole = key => {
    this.setState({ role: key });
  };
  fetchComplainCustomTime = key => {
    const newCheck = key.target.value.replace(/[^\w\s]/gi, "");
    let value = parseInt(newCheck);
    if (value) {
      if (value > 12) {
        message.error("max time is 12 hrs");
      } else {
        if (
          value == 1 ||
          value == 2 ||
          value == 3 ||
          value == 4 ||
          value == 5 ||
          value == 6 ||
          value == 7 ||
          value == 8 ||
          value == 9 ||
          value == 10 ||
          value == 11 ||
          value == 12
        ) {
          this.setState({ time: parseInt(value) });
        }
      }
    }
  };

  fetchComplainOnTime = async key => {
    if (key != "") {
      this.setState({
        time: parseInt(key),
        showDatePicker: false,
        fromDate: null,
        toDate: null
      });
    }
    if (key == "custom") {
      this.setState({
        showDatePicker: true,
        fromDate: null,
        toDate: null
      });
    }
    if (key == "today") {
      this.setState({
        showDatePicker: false,
        fromDate: new Date().setHours(0, 0, 0, 0),
        toDate: new Date().setHours(23, 59, 59, 59)
      });
    }
    if (key == "yesterday") {
      this.setState({
        showDatePicker: false,
        fromDate: new Date().setHours(0, 0, 0, 0) - 86400000,
        toDate: new Date().setHours(23, 59, 59, 59) - 86400000
      });
    }
  };

  showResult = async () => {
    const {
      role,
      hub,
      complainStatus,
      fromDate,
      toDate,
      time,
      searchedComplain,
      PNRSearchValue
    } = this.state;
    let body = {};
    this.props.showLoader();
    if (role) {
      body.role = role;
    }
    if (PNRSearchValue) {
      body.bookingCode = PNRSearchValue;
    }
    if (hub) {
      body.hub = hub;
    }
    if (
      searchedComplain &&
      Array.isArray(searchedComplain) &&
      searchedComplain.length > 0
    ) {
      body._id = searchedComplain[0]._id;
      if (searchedComplain[0]._id == "system") {
        body.type = searchedComplain[0].name;
      }
    }
    if (complainStatus) {
      body.complainStatus = complainStatus;
    }
    if (fromDate && toDate) {
      body.fromDate = fromDate.valueOf();
      body.toDate = toDate.valueOf();
    }
    if ((fromDate && !toDate) || (toDate && !fromDate)) {
      message.error("please select both date");
      return;
    }
    if (Number.isInteger(time)) {
      body.fromDate = Date.now() - time * 60 * 60 * 1000;
      body.toDate = Date.now();
    }
    this.props.onSearch(body);
  };

  setValue = value => {
    const { complains } = this.state;
    let id = complains.filter(complain => complain.name === value);
    this.setState({ searchedComplain: id });
  };

  handlePNRSearch = event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    this.setState({ PNRSearchValue: newCheck });
  };

  render() {
    const {
      fromDate,
      toDate,
      time,
      PNRSearchValue,
      showDatePicker,
      serviceHub,
      raisedBy,
      complains
    } = this.state;
    return (
      <>
        <div className="callTrigger">
          <div className="pnrSearchOutbound">
            <AutoComplete
              style={{
                width: "100%"
              }}
              dataSource={
                complains && Array.isArray(complains)
                  ? complains.map(complain => complain.name)
                  : null
              }
              onChange={value => this.setValue(value)}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </div>
          <div className="complainSelector">
            <Select
              style={{ margin: "0 0.2rem" }}
              placeholder="Complain Status"
              onChange={this.fetchComplainOnStatus}
            >
              <Option value="REJECTED">REJECTED</Option>
              <Option value="CLOSED">CLOSED</Option>
              <Option value="INPROGRESS">INPROGRESS</Option>
              <Option value="CREATED">CREATED</Option>
            </Select>
            <Select
              style={{ margin: "0 0.2rem", width: "100px" }}
              placeholder="Hub"
              onChange={this.fetchComplainOnHub}
            >
              {serviceHub && serviceHub.length > 0
                ? serviceHub.map(service => (
                    <Option value={service.name} key={service.name}>
                      {service.name}
                    </Option>
                  ))
                : null}
            </Select>
            <Input
              placeholder="PNR Search"
              value={PNRSearchValue}
              onChange={this.handlePNRSearch}
              style={{ width: 500 }}
            />
          </div>
          <div style={{ margin: "0 1%" }}>
            <Select
              style={{ margin: "0 0.2rem", width: "200px" }}
              placeholder="Role"
              onChange={this.fetchComplainOnRole}
            >
              <Option value="Crew Team">Crew Team</Option>
              <Option value="Customer Team">Customer Team</Option>
              <Option value="Route Manager">Route Manager</Option>
              <Option value="Incentive Team">Incentive Team</Option>
              <Option value="Cargo Team">Cargo Team</Option>
              <Option value="Ground Ops Team">Ground Ops Team</Option>
              <Option value="Legal Team">Legal Team</Option>
            </Select>
            <Select
              placeholder="Time Duration"
              onChange={this.fetchComplainOnTime}
            >
              <Option value="1">Last 60mins</Option>
              <Option value="4">Last 4hrs</Option>
              <Option value="today">Today</Option>
              <Option value="yesterday">Yesterday</Option>
              <Option value="custom">custom Select</Option>
            </Select>
          </div>
          {showDatePicker ? (
            <div style={{ margin: "0 1%" }}>
              <DatePicker
                showTime
                value={fromDate}
                onChange={fromDate => this.setState({ fromDate })}
              />
              {" to "}
              <DatePicker
                showTime
                value={toDate}
                onChange={toDate => this.setState({ toDate })}
              />
            </div>
          ) : null}

          <Button type="primary" onClick={this.showResult}>
            Search / Refresh
          </Button>
        </div>
        <hr />
      </>
    );
  }
}

export default crewTicketSearch;
