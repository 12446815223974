import React, { Component } from "react";
import CurrentLocation from "../../../images/activePin.svg";

const google = window.google;

class cancelledMap extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.initMap();
  }

  directionsService = null;
  directionsRenderer = [];

  initMap = async () => {
    try {
      if (typeof google !== "undefined") {
        const { wayPoints } = this.props;

        // Initialize google map
        this.map = new google.maps.Map(document.getElementById("map2"), {
          zoom: 16,
          center: wayPoints[0].coordinates,
          disableDefaultUI: true,
          gestureHandling: "greedy"
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { width = "100%", height = "100%", isTrackingWorking } = this.props;

    return (
      <>
        <div style={{ width, height, position: "absolute" }} id="map2" />
        <button onClick={this.moveToCurrentLocation} className="activeLocation">
          <img src={CurrentLocation} />
          <p
            style={{
              marginTop: "5%",
              fontSize: "15px",
              fontWeight: "500",
              fontFamily: "SFPROTEXT",
              color: "#292929"
            }}
          >
            It looks like your Trip has been cancelled. Please contact Customer
            Support
          </p>
        </button>
      </>
    );
  }
}

export default cancelledMap;
