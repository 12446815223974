import jwt from "jsonwebtoken";
import Moment from "moment";

const getJWT = () => localStorage.getItem("idToken");

const jwtDecode = () => {
  return jwt.decode(getJWT());
};

const tokenIsExpired = () => {
  const JWT = getJWT();
  if (JWT) {
    const { exp } = jwtDecode();
    const currentTimeEpoch = Moment().unix();
    if (currentTimeEpoch > exp) {
      // Token expired
      return true;
    }
    return false;
  }
  //  NO token found
  return true;
};

export { jwtDecode, tokenIsExpired };
