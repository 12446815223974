import React, { Component } from "react";
import { Modal, message } from "antd";
import moment from "moment";
import Fetch from "../../utils/fetch";
class BusInformation extends Component {
  state = {
    tripUtilization: {},
    loading: true
  };

  async componentDidMount() {
    try {
      const tripLastData = await this.fetchTripData();

      const tripUtilization = await this.fetchUtilization(tripLastData);
      if (!tripUtilization) {
        this.setState({ loading: false });
      } else {
        this.setState({ tripUtilization, loading: false });
      }
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  }

  fetchUtilization = async odoReadings => {
    try {
      const { trip } = this.props;

      const url = `/bus-tracker/tripUtilization/?plate=${trip.bus.rc}&start_time=${trip.startTime}&end_time=${trip.endTime}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        let formattedData = {
          serviceName: trip.service.name,
          fuelConsumed: response.fuelConsumed,
          distanceTraveled: response.distanceTraveled,
          tripDate: moment(trip.startTime).format("LLL"),
          startOdo: odoReadings.startOdo ? odoReadings.startOdo : null,
          endOdo: odoReadings.endOdo ? odoReadings.endOdo : null
        };
        return formattedData;
      }
    } catch (err) {
      return null;
    }
  };

  fetchTripData = async () => {
    try {
      const { trip } = this.props;

      const url = `/bus-tracker/trip/?id=${trip._id}`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        let tripOdo = {
          startOdo: response.startOdo,
          endOdo: response.endOdo
        };
        return tripOdo;
      }
    } catch (err) {
      console.log("fetchTripData-error", err);
      return null;
    }
  };

  render() {
    const { tripUtilization, loading } = this.state;

    return (
      <Modal
        width={"50%"}
        title="Utilizations"
        visible={true}
        onCancel={this.props.onCancel}
        footer={null}
      >
        {loading ? (
          <h1>loading ...</h1>
        ) : tripUtilization ? (
          <div className="row">
            <div className="col-6">
              <p>
                <h2>Service Name:</h2>
                {tripUtilization.serviceName}{" "}
              </p>
              <p>
                <h2>Start Odometer Reading:</h2>
                {tripUtilization && tripUtilization.startOdo
                  ? tripUtilization.startOdo.toLocaleString("en-IN")
                  : null}
              </p>
              <p>
                <h2>Fuel Consumed:</h2>
                {tripUtilization.fuelConsumed
                  ? tripUtilization.fuelConsumed.toFixed(2)
                  : null}
                {" L"}
              </p>
            </div>
            <div className="col-6">
              <p>
                <h2>Date:</h2>
                {tripUtilization.tripDate}
              </p>
              <p>
                <h2>End Odometer Reading:</h2>{" "}
                {tripUtilization && tripUtilization.endOdo
                  ? tripUtilization.endOdo.toLocaleString("en-IN")
                  : null}
              </p>
              <p>
                <h2>Distance Covered:</h2>
                {tripUtilization.distanceTraveled / 1000}
                {" KM"}
              </p>
            </div>
          </div>
        ) : (
          "No Details found"
        )}
      </Modal>
    );
  }
}

export default BusInformation;
