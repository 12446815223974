import React from "react";
import moment from "moment";
import cx from "classnames";

import { getWayPoints } from "../../../../utils/common";
import RouteMap from "../../../routeMap";
import DirectionIcon from "../../../../images/GetDirection.svg";
import CallIcon from "../../../../images/telephone.png";
import { useHistory } from "react-router-dom";
import { Image, Button } from "antd";
import styles from "./tripDetails.module.scss";
import { analytics } from "../../../../utils/analytics";
import { googleLayerAnalytics } from "../../../../utils/googleDataLayer";
const dateFormat = "MMM DD, HH:mm A";

const _gaq = window._gaq || [];

const getSeatBooked = tripChart => {
  let seatBooked = "";
  tripChart = tripChart.filter(obj => obj.status == "CONFIRMED");
  if (tripChart.length > 0) {
    for (let i = 0; i < tripChart.length; ++i) {
      const { seatLabel, status } = tripChart[i];

      if (i !== 0) {
        seatBooked += ",";
      }
      if (status === "CONFIRMED") {
        seatBooked += seatLabel;
      }
    }
  }
  return seatBooked;
};

const TripDetails = props => {
  const {
    booking,
    customerLatLng,
    busRoute,
    toggleOverlayScreen,
    changeBooking,
    connectingFlag,
    cities,
    bus,
    eta,
    boardingStationMarked,
    isConnectingBooking,
    connectingFirstLegCompleted
  } = props;

  let etaTime;
  // eta check
  etaTime = eta.early
    ? Math.abs(eta.earlyTimeSec)
    : eta.delay
    ? Math.abs(eta.delayTimeSec)
    : Math.abs(booking.boardingPoint.time - Date.now());

  const history = useHistory();
  const handleOnClick = () => {
    toggleOverlayScreen(true, showRoute, "Bus Route");
    history.push("Itinerary");
    googleLayerAnalytics("view_itinerary", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      booking_source: booking.demandSource
    });
  };

  function mapsSelector() {
    const { lat, lng } = booking.boardingPoint;
    _gaq.push([
      "_trackEvent",
      "Get Directions",
      "Button Clicked",
      "click to see",
      1,
      true
    ]);
    analytics("Navigate", {
      mobile: booking.mobileNo,
      latitude: customerLatLng[0],
      longitude: customerLatLng[1]
    });
    googleLayerAnalytics("navigate_pickup", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    const ua = navigator.userAgent.toLowerCase();

    if (ua.indexOf("android") > -1) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      );
    } else if (ua.match(/iPhone|iPad|iPod/i)) {
      window.location = `http://maps.apple.com/?q=sll=${lat},${lng}&z=10&t=s`;
    } else {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      );
    }
  }

  const route = getWayPoints(
    busRoute,
    booking.boardingPoint.stationId,
    booking.dropPoint.stationId
  );

  const showRoute = () => <RouteMap route={route} cities={cities} />;
  let enablePnrView = {};
  let enableTripDetails = {};

  if (props.tourValue === 1) {
    enablePnrView = { zIndex: 101, background: "white", paddingBottom: "10px" };
  } else if (props.tourValue === 2) {
    enableTripDetails = {
      zIndex: 11,
      background: "white",
      height: "50px",
      width: "1px"
    };
  }
  return (
    <div className={cx(styles.tripDetails)}>
      <ul>
        <div className="row pt-3" style={enablePnrView}>
          {/* <div style={{ paddingLeft: "15px", fontSize: "16px", width: "100%" }}>
            PNR: {booking.bookingCode}
            <hr />
          </div> */}
          <>
            {booking.packageCount ? (
              <>
                <div className="col-6 text-left">
                  <br />
                  <div className="h1"> PNR </div>
                  <p className="h1">
                    {!connectingFlag
                      ? booking.bookingCode
                      : booking.connectingBookingPnr}
                  </p>
                </div>
                <div className="col-6 text-right">
                  <br />
                  <div> OTP </div>
                  <p className="light-black" style={{ fontSize: "14px" }}>
                    {`${booking.otp}`}
                  </p>
                </div>
              </>
            ) : (
              <div className="col-6">
                <div className="h1">
                  PNR:{" "}
                  {!connectingFlag
                    ? booking.bookingCode
                    : booking.connectingBookingPnr}
                </div>
              </div>
            )}

            {isConnectingBooking && !connectingFirstLegCompleted ? (
              <div className="col-6 text-right">
                <Button
                  type="primary"
                  onClick={changeBooking}
                  style={{
                    paddingBottom: "15px",
                    background: "#7b2bff",
                    fontSize: "15px",
                    border: "2px solid #7b2bff"
                  }}
                >
                  {connectingFlag
                    ? "Show previous Station"
                    : "Show next Station"}
                </Button>
              </div>
            ) : null}

            <div className="col-12">
              <br />
              <hr />
            </div>
          </>
          <div className="col-6">
            <br />
            <div className="h1" style={{ fontSize: "16px" }}>
              {Object.keys(bus).length ? <>{bus.rc}</> : "Bus Number"}
            </div>
            <div className="dark-gray">{booking.busType.name}</div>
          </div>
          {booking.tripChart ? (
            <div className="col-6 text-right">
              <br />
              <div> Seats </div>
              <p className="light-black" style={{ fontSize: "14px" }}>
                {`${getSeatBooked(booking.tripChart)}`}
              </p>
            </div>
          ) : (
            <div className="col-6 text-right">
              <br />
              <div> Packages Count </div>
              <p className="light-black" style={{ fontSize: "14px" }}>
                {`${booking.packageCount}`}
              </p>
            </div>
          )}
        </div>

        {booking.boardingPoint.stationImagemain ? (
          <li className={styles.pickup}>
            <div className="row">
              <div className="col-7" style={{ display: "flex" }}>
                <Image
                  onClick={() => {
                    googleLayerAnalytics("boarding_point_station_image_click", {
                      mobile_no: booking.mobileNo,
                      pnr: booking.bookingCode,
                      from_city: booking.boardingPoint.city,
                      to_city: booking.dropPoint.city,
                      from_station: booking.boardingPoint.address,
                      to_station: booking.dropPoint.address,
                      booking_source: booking.demandSource,
                      trip_date: moment(booking.boardingPoint.time).format(
                        "YYYY-MM-DD"
                      )
                    });
                  }}
                  width={60}
                  src={`https://station-asset.s3.amazonaws.com/${booking.boardingPoint.stationId}/${booking.boardingPoint.stationImagemain}`}
                />
                <div style={{ paddingLeft: "10px" }}>
                  <h2 className="h1">{booking.boardingPoint.name}</h2>
                  <p className="light-black">
                    {(eta.early || eta.delay) &&
                    !booking.isOnboarded &&
                    !boardingStationMarked ? (
                      <>
                        <strike>
                          {moment(booking.boardingPoint.time).format(
                            dateFormat
                          )}
                        </strike>
                        <br />
                        {moment(etaTime).format(dateFormat)}
                      </>
                    ) : (
                      moment(booking.boardingPoint.time).format(dateFormat)
                    )}
                  </p>
                </div>
              </div>
              <div className="col-5 text-right" style={enableTripDetails}>
                {!booking.isOnboarded ? (
                  <>
                    <div
                      onClick={mapsSelector}
                      style={{ marginLeft: "5%", marginTop: "10px" }}
                    >
                      <h2 className="blue d-inline pr-2">Navigate</h2>
                      <img width="27px" src={DirectionIcon} />
                    </div>
                  </>
                ) : null}
                <br />
              </div>
            </div>
            <div className="row">
              {/* <p className="dark-gray mb-1">Landmark</p> */}
            </div>
          </li>
        ) : (
          <li className={styles.noImagePickup}>
            <div className="row">
              <div className="col-6">
                <h2 className="h1">{booking.boardingPoint.name}</h2>
              </div>
              <div className="col-6 text-right" style={enableTripDetails}>
                {!booking.isOnboarded ? (
                  <>
                    <div
                      onClick={mapsSelector}
                      style={{ marginLeft: "5%", marginTop: "10px" }}
                    >
                      <h2 className="blue d-inline pr-2">Navigate</h2>
                      <img width="27px" src={DirectionIcon} />
                    </div>
                  </>
                ) : null}
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {/* <p className="dark-gray mb-1">Landmark</p> */}
                <p className="light-black">
                  <p className="light-black">
                    {(eta.early || eta.delay) && !booking.isOnboarded ? (
                      <>
                        <strike>
                          {moment(booking.boardingPoint.time).format(
                            dateFormat
                          )}
                        </strike>
                        <br />
                        {moment(etaTime).format(dateFormat)}
                      </>
                    ) : (
                      moment(booking.boardingPoint.time).format(dateFormat)
                    )}
                  </p>
                </p>
              </div>
            </div>
          </li>
        )}

        {booking.dropPoint.stationImagemain ? (
          <li className={styles.drop}>
            <div className="row">
              <div className="col-7" style={{ display: "flex" }}>
                <Image
                  onClick={() => {
                    googleLayerAnalytics("drop_point_station_image_click", {
                      mobile_no: booking.mobileNo,
                      pnr: booking.bookingCode,
                      from_city: booking.boardingPoint.city,
                      to_city: booking.dropPoint.city,
                      from_station: booking.boardingPoint.address,
                      to_station: booking.dropPoint.address,
                      booking_source: booking.demandSource,
                      trip_date: moment(booking.boardingPoint.time).format(
                        "YYYY-MM-DD"
                      )
                    });
                  }}
                  width={60}
                  src={`https://station-asset.s3.amazonaws.com/${booking.dropPoint.stationId}/${booking.dropPoint.stationImagemain}`}
                />
                <div style={{ paddingLeft: "10px" }}>
                  <h2 className="h1">{booking.dropPoint.name}</h2>
                  <p className="light-black">
                    {moment(booking.dropPoint.time).format(dateFormat)}
                  </p>
                </div>
              </div>

              <div className="col-5 text-right">
                <div onClick={handleOnClick}>
                  <h2 className="blue d-inline pr-2">Itinerary</h2>
                </div>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {/* <p className="dark-gray mb-1">Landmark</p> */}
              </div>
            </div>
          </li>
        ) : (
          <li className={styles.noImageDrop}>
            <div className="row">
              <div className="col-6">
                <h2 className="h1">{booking.dropPoint.name}</h2>
              </div>
              <div className="col-6 text-right">
                <div
                  // onClick={() =>
                  //   toggleOverlayScreen(true, showRoute, "Bus Route")
                  // }
                  onClick={handleOnClick}
                >
                  <h2 className="blue d-inline pr-2">Itinerary</h2>
                </div>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {/* <p className="dark-gray mb-1">Landmark</p> */}
                <p className="light-black">
                  {moment(booking.dropPoint.time).format(dateFormat)}
                </p>
              </div>
            </div>
          </li>
        )}
        <li>
          <div className="row">
            <div className="col-6">
              <h2 className="h1">Marshal&apos;s Number</h2>
            </div>
            <div className="col-6 text-right">
              <div>
                <a href="tel:+91-8287009889" className="blue d-inline pr-2">
                  8287009889
                </a>
                <img width="27px" src={CallIcon} />
              </div>
              <div>
                <h2 className="red d-inline pr-2">
                  Number will be active one hour prior to pickup
                </h2>
              </div>
              <br />
            </div>
          </div>
        </li>
      </ul>
      <hr />
    </div>
  );
};

export default TripDetails;
