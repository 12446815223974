import React, { Component } from "react";
import io from "socket.io-client";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";

import {
  Tag,
  Button,
  Table,
  message,
  Checkbox,
  Tabs,
  Modal,
  Select,
  Input
} from "antd";
import { Tooltip } from "antd";
import { PlusSquareOutlined, EditOutlined } from "@ant-design/icons";
import tripSide from "../../images/tripSide.svg";
import tripSideCol from "../../images/tripSideCol.svg";
import liveView from "../../images/liveView.svg";
import liveViewCol from "../../images/liveViewCol.png";
import outBoundCol from "../../images/OutBoundCol.svg";
import crewTick from "../../images/crewTick.svg";
import crewTickCol from "../../images/CrewTickCol.svg";
import camFeedCol from "../../images/camFeedCol.svg";
import camFeed from "../../images/camFeed.svg";
import outBoundImg from "../../images/OutBound.svg";
import gdsLoungeCol from "../../images/loungeIconBlue.svg";
import gdsLounge from "../../images/loungeIcon.svg";
import connectingTrip from "../../images/connectingTrip.svg";
import task from "../../images/task.svg";
import geofence from "../../images/geofence.svg";

import connectingTripCol from "../../images/connectingTripCol.svg";
import taskCol from "../../images/taskCol.svg";
import geofenceCol from "../../images/geofenceCol.svg";
import gdsImg from "../../images/gds.png";
import gdsCol from "../../images/gdsCol.png";

import { Link } from "react-router-dom";
import moment from "moment";
import { socketURL } from "../../config";
import Fetch from "../../utils/fetch";
import BookingList from "../../components/bookingList";
import Loader from "../../components/loader";
import StationList from "../../components/stationList";
import BookingConnectingList from "../../components/bookingConnectingList";
import CrewDetails from "../../components/crewDetails";
import BusInformation from "../../components/intanglesBusInfo";
import AssignCrewDetails from "../../components/assignCrew";
import IssuesList from "../../components/issuesList";
import RaiseIssue from "../../components/raiseIssue";
import StatusComment from "../../components/statusComment";
import SearchForm from "../../components/searchForm";
import ConnectingSearchForm from "../../components/connectingSearchForm";

import TaskSearchForm from "../../components/taskSearchForm";
import EditTask from "../../components/editTask";
import ViewAttachments from "../../components/viewAttachments";
import ViewTimelines from "../../components/viewTimelines";
import WebHeader from "../../components/webHeader";
import TaskList from "../../components/taskList";
import InventoryDetails from "../../components/inventoryDetails";
import {
  getHoursAndMinFromMillis,
  getBrowserVisibilityProp,
  getBrowserVisibilityEvent
} from "../../utils/common";
import { markTripCompleted, markTripCompletedManually } from "../../network";
import jwt from "jsonwebtoken";
import { prodServer } from "../../config";
import GeoFenceView from "../../components/geoFence";
import Gds from "../../components/gdsTrips";
import CallLogsView from "../../components/callLogsView";
import GdsCallLogsView from "../../components/gdsCallLogsView";
import GdsLounegBookingView from "../../components/gdsLoungeBooking";

import ComplainView from "../../components/complainView";
import CameraFeeds from "../../components/cameraFeedView";
import menu from "../../images/menu.svg";
import LiveView from "../liveView";
import TripSOS from "../../components/tripOutBoundSOS";
const { TabPane } = Tabs;

const { Option } = Select;
const { TextArea } = Input;

const sortPointMap = {
  upcomming: 2000,
  deviceNotWorking: 700,
  tripNotStarted: 600,
  onTime: 21,
  tripNotAssigned: 20,
  past: 1000,
  delay: 22,
  notBoarded: 30,
  issue: 3,
  willStartIn: 2,
  completed: 1
};

const calculateSortPoint = trip => {
  const { SOSCount, status, tripStartTime } = trip;
  let sortPoint = 0;

  // sortPoint +=
  //   booking.bookingCountObj.notBoardedBookingCount * sortPointMap.notBoarded;

  // if (booking && booking.bookingCountObj.notBoardedBookingCount > 0) {
  //   sortPoint += sortPointMap.notBoarded;
  // }
  if (SOSCount > 0) {
    sortPoint += sortPointMap.issue;
  }

  switch (status && status.name) {
    case "On Time": {
      sortPoint += sortPointMap.onTime;
      break;
    }
    case "Tracking Not Working": {
      sortPoint += sortPointMap.deviceNotWorking;
      break;
    }
    case "Trip Not Assigned Yet": {
      sortPoint += sortPointMap.tripNotAssigned;
      break;
    }
    case "Bus Not Started yet": {
      sortPoint += sortPointMap.tripNotStarted;
      break;
    }
    case "Marshal Not Logged In": {
      sortPoint += sortPointMap.tripNotStarted;
      break;
    }
    case "COMPLETED": {
      sortPoint += sortPointMap.completed;
      break;
    }
    default: {
      break;
    }
  }

  let upcommingtime = tripStartTime - Date.now();
  let todayDate = new Date();
  todayDate = todayDate.setHours(0, 0, 0, 0);

  if (tripStartTime < todayDate) {
    sortPoint += sortPointMap.past;
  }

  if (upcommingtime >= 0) {
    if (upcommingtime <= 7200000) {
      sortPoint += sortPointMap.upcomming;
      switch (status.name) {
        case "Trip Not Assigned Yet": {
          sortPoint += sortPointMap.tripNotAssigned + 200;
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  const endDate = todayDate + 24 * 60 * 60 * 1000;

  if (tripStartTime > endDate) {
    sortPoint -= sortPointMap.tripNotAssigned;
  }

  let multiplyFactor = 1;

  if (status && status.value > 1) {
    // convert minutes to less than 0
    multiplyFactor = 1 + status.value / (1000 * 60 * 10);
  }

  if (status && status.name.indexOf("Delay") === 0) {
    sortPoint += sortPointMap.delay;
  }

  if (status && status.name.indexOf("Will Start in") === 0) {
    sortPoint += sortPointMap.willStartIn;
  }

  return sortPoint;
};

const columns = [
  {
    title: "Service Name",
    dataIndex: "serviceName",
    key: "serviceName",
    width: "10%",
    defaultSortOrder: "descend",
    sorter: (a, b) => calculateSortPoint(a) - calculateSortPoint(b),
    render: ({
      name,
      tripId,
      status,
      serviceStatus,
      stationtime,
      SOSCount,
      onClick
    }) => {
      if (status === "COMPLETED" || status === "ONTRIP") {
        return (
          <>
            <Link to={`/report/?tripId=${tripId}`} target="_blank">
              {name}
            </Link>
            <br></br>
            {stationtime ? moment(stationtime).format("MMM Do") : null}
            <br />
            {SOSCount > 0 ? (
              <Button
                type="danger"
                onClick={() => onClick({ showSOS: true, tripId })}
              >
                SOS
              </Button>
            ) : null}

            <br />
            {!prodServer ? (
              <Link to={`/track/test/${tripId}`} target="_blank">
                Testing Framework
              </Link>
            ) : null}
          </>
        );
      } else if (serviceStatus === "INACTIVE") {
        return (
          <div style={{ color: "#ff7875" }}>
            {name},<br></br>
            {stationtime ? moment(stationtime).format("MMM Do") : null}
            <br />
            {SOSCount > 0 ? (
              <Button
                type="danger"
                onClick={() => onClick({ showSOS: true, tripId })}
              >
                SOS
              </Button>
            ) : null}
            <br />
            {!prodServer ? (
              <Link to={`/track/test/${tripId}`} target="_blank">
                Testing Framework
              </Link>
            ) : null}
          </div>
        );
      } else {
        return (
          <div>
            {name},<br></br>
            {stationtime ? moment(stationtime).format("MMM Do") : null}
            <br />
            {SOSCount > 0 ? (
              <Button
                type="danger"
                onClick={() => onClick({ showSOS: true, tripId })}
              >
                SOS
              </Button>
            ) : null}
            <br />
            {!prodServer ? (
              <Link to={`/track/test/${tripId}`} target="_blank">
                Testing Framework
              </Link>
            ) : null}
          </div>
        );
      }
    }
  },
  {
    title: "Upcoming Station",
    dataIndex: "upcommingStation",
    key: "upcommingStation",
    width: "10%",
    render: ({ name = "", eta = "", onClick, tripId, status }) =>
      status === "ONTRIP" ? (
        <Button
          onClick={() => onClick({ showStationList: true, tripId })}
          style={{ height: "8rem" }}
        >
          {name ? (
            <>
              <div className="tableContent" style={{ color: "blue" }}>
                {name}
              </div>
              <div
                className="tableContent"
                style={{ color: "green", marginTop: "5px" }}
              >
                {eta ? `ETA: ${eta}` : ""}
              </div>
            </>
          ) : (
            <div style={{ color: "coral" }}>
              <p>Tracking not</p>
              <p>working</p>
            </div>
          )}
        </Button>
      ) : (
        <Button onClick={() => onClick({ showStationList: true, tripId })}>
          Station List
        </Button>
      )
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "15%",
    key: "status",
    sorter: (a, b) => a.status.value - b.status.value,
    filters: [
      {
        text: "On Time",
        value: "On Time"
      },
      {
        text: "Will Start",
        value: "Will Start"
      },
      {
        text: "Delay",
        value: "Delay"
      },
      {
        text: "Delay less than 60mins",
        value: "late by "
      },
      {
        text: "Completed",
        value: "Completed"
      },
      {
        text: "Marshal Not Logged In",
        value: "Marshal Not Logged In"
      },
      {
        text: "Tracking Not Working",
        value: "Tracking Not Working"
      },
      {
        text: "Trip Not Assigned Yet",
        value: "Trip Not Assigned Yet"
      }
    ],
    onFilter: (value, record) =>
      record.status.isPseudoOnTrip === "true"
        ? value === "Marshal Not Logged In"
          ? record.status.names.includes(value)
          : record.status.name.indexOf(value) === 0
        : record.status.name.indexOf(value) === 0,
    render: busStatus => (
      <>
        <Tag color={busStatus.color}>{busStatus.name}</Tag>
        <br />
        <Tag color={busStatus.ignitionColor}>{busStatus.speed} km/hr</Tag>
        <br />
        {busStatus.isPseudoOnTrip === "true" ? (
          <>
            <Tag color="red"> Pseudo ONTRIP </Tag>
            <Tag color="red"> Marshal not Logged In </Tag>
            <br />
          </>
        ) : null}
        {busStatus.isValidTrackingData === "false" ? (
          <>
            <Tag color="red"> InValid Tracking Data </Tag>
            <br />
          </>
        ) : null}
      </>
    )
  },
  {
    title: "Trip Summary",
    dataIndex: "summary",
    width: "10%",
    key: "summary",
    render: ({
      addSummary,
      viewSummary,
      isCompleted,
      isSummary,
      tripId,
      tripSummary
    }) => (
      <>
        {isCompleted ? (
          <PlusSquareOutlined
            onClick={() =>
              addSummary({
                tripId,
                addTripSummary: true
              })
            }
          />
        ) : null}
        {isSummary ? (
          <div
            style={{
              cursor: "pointer",
              wordBreak: "normal",
              marginTop: "2px",
              border: "1px solid grey",
              borderRadius: "5px"
            }}
            onClick={() =>
              viewSummary({
                tripSummary,
                tripId,
                viewTripSummary: true,
                isCompleted
              })
            }
          >
            show Remarks
          </div>
        ) : (
          <div
            style={{
              color: "red",
              wordBreak: "normal"
            }}
          >
            No Remarks
          </div>
        )}
      </>
    )
  },
  {
    title: "Booking List",
    dataIndex: "booking",
    width: "10%",
    key: "booking",
    render: ({ tripId, onClick, bookingCountObj }) => (
      <>
        <Button
          type={"default"}
          onClick={() =>
            onClick({
              showBookingList: true,
              tripId
            })
          }
        >
          <span>{`Bookings`}</span>
        </Button>
        <p
          style={{
            margin: "10px 0 0 0",
            fontSize: "12px"
          }}
        >
          <span>{`Not Boarded: ${bookingCountObj}`}</span>
        </p>
      </>
    )
  },

  {
    title: "Crew Details",
    dataIndex: "crewDetails",
    width: "10%",
    key: "crewDetails",
    render: ({ onClick, tripId, name, privilege }) => {
      if (privilege && privilege.includes("experience")) {
        return (
          <>
            <Button
              // type={name === "Unhealthy" ? "danger" : "default"}
              onClick={() => onClick({ showCrewDetails: true, tripId })}
            >
              {name}
            </Button>
            <Button
              onClick={() => onClick({ showAssignCrewDetails: true, tripId })}
            >
              Assigne Crew
            </Button>
          </>
        );
      } else {
        return (
          <Tooltip color="blue" title="You're not authorized">
            <Button disabled>{name}</Button>
          </Tooltip>
        );
      }
    }
  },
  {
    title: "Last Updated at",
    dataIndex: "lastUpdatedAt",
    key: "lastUpdatedAt",
    width: "10%",
    sorter: (a, b) => a.lastUpdatedAt.lastUpdated - b.lastUpdatedAt.lastUpdated,
    render: ({
      onClick,
      lastUpdated,
      endTime = 0,
      status,
      tripId,
      updateTripStatusManually,
      updateTrackingStatus,
      isTrackingWorking
    }) => {
      if (lastUpdated) {
        const showCompletedButton =
          status === "ONTRIP" && Date.now() - endTime >= 1 * 60 * 60 * 1000;
        return (
          <>
            <br />
            <Checkbox
              onChange={e =>
                updateTrackingStatus({
                  tripId,
                  isTrackingWorking: e.target.checked
                })
              }
              checked={isTrackingWorking}
            >
              Tracking Not Working
            </Checkbox>
            <br />
            <span>{moment(lastUpdated).calendar()}</span>
            {showCompletedButton ? (
              <>
                <br />
                <Button onClick={() => updateTripStatusManually(tripId)}>
                  Complete Trip
                </Button>
              </>
            ) : null}
          </>
        );
      } else if (status === "COMPLETED") {
        return (
          <div>
            <Tag color="green">Trip is Completed</Tag>
            <br />
            <Button onClick={() => onClick({ showUtilization: true, tripId })}>
              Utilization
            </Button>
          </div>
        );
      } else if (status === "ACTIVE") {
        return (
          <>
            <Checkbox
              onChange={e =>
                updateTrackingStatus({
                  tripId,
                  isTrackingWorking: e.target.checked
                })
              }
              checked={isTrackingWorking}
            >
              Tracking Not Working
            </Checkbox>
            <br />
            <Tag color="magenta">Trip Not Started Yet</Tag>
          </>
        );
      } else {
        return (
          <>
            <Checkbox
              onChange={e =>
                updateTrackingStatus({
                  tripId,
                  isTrackingWorking: e.target.checked
                })
              }
              checked={isTrackingWorking}
            >
              Tracking Not Working
            </Checkbox>
            <br />
            <Tag color="red">No Data Found</Tag>
          </>
        );
      }
    }
  }
];

const connectingTripsColumns = [
  {
    title: "Service Name",
    dataIndex: "serviceName",
    key: "serviceName",
    width: "10%",
    defaultSortOrder: "descend",
    render: ({ name, tripId, stationtime }) => {
      return (
        <div
          style={{
            wordBreak: "break-word"
          }}
        >
          {name}
          <br></br>
          {stationtime ? moment(stationtime).format("MMM Do") : null}
          <br />
          {!prodServer ? (
            <Link to={`/track/test/${tripId}`} target="_blank">
              Testing Framework
            </Link>
          ) : null}
        </div>
      );
    }
  },
  {
    title: "Leading Trip",
    dataIndex: "LeadingServiceName",
    key: "LeadingServiceName",
    width: "10%",
    render: ({ name }) => {
      return (
        <div
          style={{
            wordBreak: "break-word"
          }}
        >
          {name}
        </div>
      );
    }
  },
  {
    title: "Upcoming Station",
    dataIndex: "LeadingUpcommingStation",
    key: "LeadingUpcommingStation",
    width: "10%",
    render: ({ name = "", eta = "", onClick, tripId, status }) =>
      status === "ONTRIP" ? (
        <Button
          onClick={() =>
            onClick({ showConnectingStationList: true, tripId, status })
          }
          style={{ height: "8rem" }}
        >
          {name ? (
            <>
              <div
                className="tableContent"
                style={{ color: "blue", wordBreak: "break-word" }}
              >
                {name}
              </div>
              <div
                className="tableContent"
                style={{ color: "green", marginTop: "5px" }}
              >
                {eta ? `ETA: ${eta}` : ""}
              </div>
            </>
          ) : (
            "Tracking not working"
          )}
        </Button>
      ) : (
        <Button
          onClick={() =>
            onClick({ showConnectingStationList: true, tripId, status })
          }
        >
          Station List
        </Button>
      )
  },
  {
    title: "InterChange Station",
    dataIndex: "interChangeStation",
    key: "interChangeStation",
    width: "10%",
    render: ({ name, eta = "" }) => {
      return (
        <>
          <div
            style={{
              color: "green",
              wordBreak: "break-word"
            }}
          >
            {name}
          </div>
          <div>{eta ? `ETA: ${eta}` : ""}</div>
        </>
      );
    }
  },
  {
    title: "Trailing Trip",
    dataIndex: "trailingServiceName",
    key: "trailingServiceName",
    width: "10%",
    render: ({ name }) => {
      return (
        <div
          style={{
            wordBreak: "break-word"
          }}
        >
          {name}
        </div>
      );
    }
  },
  {
    title: "Upcoming Station",
    dataIndex: "trailingUpcommingStation",
    key: "trailingUpcommingStation",
    width: "10%",
    render: ({ name = "", eta = "", onClick, tripId, status }) =>
      status === "ONTRIP" ? (
        <Button
          onClick={() =>
            onClick({ showTrailingConnectingStationList: true, tripId, status })
          }
          style={{ height: "8rem" }}
        >
          {name ? (
            <>
              <div
                className="tableContent"
                style={{ color: "blue", wordBreak: "break-word" }}
              >
                {name}
              </div>
              <div
                className="tableContent"
                style={{ color: "green", marginTop: "5px" }}
              >
                {eta ? `ETA: ${eta}` : ""}
              </div>
            </>
          ) : (
            "Tracking not working"
          )}
        </Button>
      ) : (
        <Button
          onClick={() =>
            onClick({ showTrailingConnectingStationList: true, tripId, status })
          }
        >
          Station List
        </Button>
      )
  },
  {
    title: "InterChange Station",
    dataIndex: "trailingInterChangeStation",
    key: "trailingInterChangeStation",
    width: "10%",
    render: ({ name, eta = "" }) => {
      return (
        <>
          <div
            style={{
              color: "green",
              wordBreak: "break-word"
            }}
          >
            {name}
          </div>
          <div>{eta ? `ETA: ${eta}` : ""}</div>
        </>
      );
    }
  },
  {
    title: "Status",
    dataIndex: "connectingStatus",
    width: "10%",
    key: "connectingStatus",
    render: leadingBusStatus => (
      <>
        <Tag color={leadingBusStatus.color}>{leadingBusStatus.name}</Tag>
        <br />
      </>
    )
  },
  {
    title: "Booking List",
    dataIndex: "booking",
    width: "10%",
    key: "booking",
    render: ({ tripId, onClick }) => {
      return (
        <div>
          <Button
            onClick={() =>
              onClick({
                showConnectingBookingList: true,
                tripId
              })
            }
          >
            Show Bookings
          </Button>
        </div>
      );
    }
  }
];

const taskColumns = [
  {
    title: "Task",
    dataIndex: "task",
    key: "task",
    width: "10%",
    render: ({ taskName, auditName }) => {
      return (
        <>
          <div>{auditName}</div>
          <div>{taskName}</div>
        </>
      );
    }
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: "10%",
    render: ({ typeName, taskType }) => {
      return (
        <>
          <div>{typeName}</div>
          <div>{taskType}</div>
        </>
      );
    }
  },
  {
    title: "Service Name",
    width: "10%",
    dataIndex: "serviceName",
    key: "serviceName",
    render: ({ serviceName, serviceDate }) => {
      return (
        <div>
          <div>{serviceName}</div>
          <div>{serviceDate}</div>
        </div>
      );
    }
  },
  {
    title: "Station",
    width: "10%",
    dataIndex: "station",
    key: "station",
    render: ({ station }) => {
      return <div>{station}</div>;
    }
  },
  {
    title: "Bus Number",
    dataIndex: "busNumber",
    key: "busNumber",
    width: "10%",
    render: ({ busNumber }) => {
      if (busNumber) {
        return <div>{busNumber}</div>;
      }
    }
  },
  {
    title: "Bus Host",
    dataIndex: "busHost",
    key: "busHost",
    width: "10%",
    render: ({ busHost }) => {
      if (busHost) {
        return <div>{busHost}</div>;
      }
    }
  },
  {
    title: "Bus Driver",
    dataIndex: "busDriver",
    key: "busDriver",
    width: "10%",
    render: ({ busDriver }) => {
      if (busDriver) {
        return <div>{busDriver}</div>;
      }
    }
  },

  {
    title: "Assigned To",
    dataIndex: "assignedTo",
    key: "assignedTo",
    width: "10%",
    render: ({ visibleto, hubs }) => {
      return (
        <div>
          {visibleto} - {hubs}
        </div>
      );
    }
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: "10%",
    render: ({ amount }) => {
      return <div>{amount}</div>;
    }
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "10%",
    render: ({ value }) => {
      return <div>{value}</div>;
    }
  },

  {
    title: "Agent",
    dataIndex: "agent",
    key: "agent",
    width: "10%",
    render: ({ agent }) => {
      return <div>{agent}</div>;
    }
  },
  {
    title: "Created By",
    width: "10%",
    dataIndex: "createdBy",
    key: "createdBy",
    render: ({ name }) => {
      return <div>{name}</div>;
    }
  },
  {
    width: "10%",
    title: "Generation Date",
    dataIndex: "generationDate",
    key: "generationDate",
    sorter: (a, b) => a.generationDate.timefilter - b.generationDate.timefilter,
    render: ({ time }) => {
      return <div>{time}</div>;
    }
  },
  {
    width: "10%",
    title: "TAT",
    dataIndex: "tat",
    key: "tat",
    render: ({ time, isTaskMaster, onChange, privilege }) => {
      if (
        time &&
        privilege &&
        isTaskMaster &&
        privilege.includes("experience")
      ) {
        return (
          <div>
            {time}
            <EditOutlined onClick={onChange} />{" "}
          </div>
        );
      } else {
        return <div>{time}</div>;
      }
    }
  },
  {
    width: "10%",
    title: "Closed By",
    dataIndex: "closedBy",
    key: "closedBy",
    render: ({ closeuser }) => {
      return <div>{closeuser}</div>;
    }
  },
  {
    width: "10%",
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: ({ taskStatus, onChange, privilege, iseditable, assignedUser }) => {
      const token = localStorage.getItem("idToken");
      const decodedToken = jwt.decode(token);
      if (iseditable) {
        return <div>{taskStatus}</div>;
      } else if (
        privilege &&
        privilege.includes("experience") &&
        assignedUser._id === decodedToken.id
      ) {
        return (
          <div>
            {taskStatus}
            <EditOutlined onClick={onChange} />{" "}
          </div>
        );
      } else {
        return <div>{taskStatus}</div>;
      }
    }
  },
  {
    width: "10%",
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
    render: ({ commentName, isTaskMaster, onChange, privilege }) => {
      if (privilege && isTaskMaster && privilege.includes("experience")) {
        return (
          <div>
            {commentName}
            <EditOutlined onClick={onChange} />{" "}
          </div>
        );
      } else {
        return <div>{commentName}</div>;
      }
    }
  },
  {
    width: "10%",
    title: "Closing Comments",
    dataIndex: "closeComment",
    key: "closeComment"
  },
  {
    width: "10%",
    title: "View Attachment",
    dataIndex: "viewAttachment",
    key: "viewAttachment",
    render: ({ isAttachmentRequired, attachments, onChange }) => {
      if (isAttachmentRequired && attachments) {
        return (
          <div>
            <Button onClick={onChange} type="dashed">
              {"view"}
            </Button>
          </div>
        );
      }
    }
  },
  {
    width: "10%",
    title: "Timeline",
    dataIndex: "viewTimeline",
    key: "viewTimeline",
    render: ({ onChange }) => {
      return (
        <div>
          <Button onClick={onChange} type="dashed">
            {"view"}
          </Button>
        </div>
      );
    }
  },
  {
    width: "10%",
    title: "Remind Team",
    dataIndex: "remindTeam",
    key: "remindTeam",
    render: ({ onClick, timeDifference, status }) => {
      if (timeDifference <= 24 * 60 * 60 * 1000 && status != "CLOSED") {
        return (
          <div>
            <Button onClick={onClick} type="dashed">
              {"Remind Team"}
            </Button>
          </div>
        );
      }
    }
  }
];

class Home extends Component {
  state = {
    loading: true,
    showBookingList: false,
    showConnectingBookingList: false,
    showStationList: false,
    showSOS: false,
    showConnectingStationList: false,
    showUtilization: false,
    showTrailingConnectingStationList: false,
    trips: [],
    tasks: [],
    connectingTrips: [],
    alltasks: [],
    showCrewDetails: false,
    showAssignCrewDetails: false,
    current: 1,
    defaultpage: 1,
    allTrips: [],
    editTask: false,
    viewAttachments: false,
    viewTimelines: false,
    taskToEdit: {},
    newTasks: null,
    showIssueList: false,
    addTripSummary: false,
    viewTripSummary: false,
    showSummaryRemarkUpdate: false,
    RaiseIssueList: false,
    showTaskList: false,
    visible: true,
    users: [],
    privileges: [],
    userResult: {},
    usertype: [],
    cities: [],
    taskStatusFilter: "OPEN",
    typeFilter: "Task",
    activeKey: 1,
    drawerWidth: "4%"
  };

  async componentDidMount() {
    try {
      const socket = io(socketURL, {
        withCredentials: true
      });
      const cities = await this.fetchCities();
      socket.on("LastOnTripData", this.handleLastOnTripData);
      socket.on("newIssue", this.newIssueAdded);
      socket.on("passengerIsBoarded", this.handlePassengerOnBoarded);
      socket.on("faultAndAlert", this.newOBDAdded);

      // for getting user
      let privilege;
      const token = localStorage.getItem("idToken");
      const decodedToken = jwt.decode(token);

      privilege = decodedToken.privileges;

      // let user = await this.searchUser();

      const response = await this.getAllQLTrips();
      let trips;
      if (response !== "No data found") {
        trips = this.formatTrips(response, privilege);
        this.updateTitle(trips);
      }

      const visibilityChange = getBrowserVisibilityEvent();

      window.addEventListener(visibilityChange, this.handleVisibilityChange);

      const pageHiddenKey = getBrowserVisibilityProp();

      this.setState({
        cities,
        loading: false,
        trips,
        filtertrips: trips,
        // users: user,
        privileges: privilege,
        allTrips: response,
        visible: document[pageHiddenKey]
      });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    const visibilityChange = getBrowserVisibilityEvent();

    window.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }

  handleVisibilityChange = async () => {
    const pageHiddenKey = getBrowserVisibilityProp();

    if (!this.state.visible && !document[pageHiddenKey]) {
      console.log("refreshing page on active");
      await this.refreshTrips();
    }
  };

  fetchCities = async () => {
    try {
      const url = `/bus-tracker/fetchCities`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      return response;
    } catch (err) {
      message.error(err);
    }
  };

  updateTitle = trips => {
    const notificationCount = trips.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.SOSCount;
    }, 0);

    if (notificationCount) {
      document.title = `(${notificationCount}) zingbus | Bus Tracker`;
    }
  };

  handleLastOnTripData = async data => {
    try {
      const socketDataMap = {};
      for (let i = 0; i < data.length; ++i) {
        const tripRecentData = data[i];

        socketDataMap[tripRecentData.tripId] = tripRecentData;
      }

      const { trips } = this.state;

      const updatedTrips = trips.map(trip => {
        const { busRoute, tripId, tripStartTime, lastUpdatedAt } = trip;
        const lastTripData = socketDataMap[tripId];

        if (lastTripData) {
          const { upcomingStationId, eta } = lastTripData;
          const busStatus = {
            ...trip.status,
            isValidTrackingData: `${trip.isValidTrackingData}`,
            isPseudoOnTrip: `${trip.isPseudoOnTrip}`,
            ignition: lastTripData.ignitionStatus || "OFF",
            speed: lastTripData.speed || 0,
            ignitionColor: "green"
          };

          if (!parseInt(busStatus.speed)) {
            busStatus.ignitionColor = "yellow";
          }

          if (busStatus.ignition !== "ON") {
            busStatus.ignitionColor = "red";
          }

          if (eta && Array.isArray(eta.rows)) {
            const upCommingStationDetailFromRoute = busRoute.filter(
              station => station.stationId === upcomingStationId
            )[0];

            // calculating current station and last station and auto complete trip
            let currentStation =
              upCommingStationDetailFromRoute &&
              upCommingStationDetailFromRoute.station
                ? upCommingStationDetailFromRoute.station._id
                : null;
            const lastStation = busRoute[busRoute.length - 1].station._id;

            let endTime = lastUpdatedAt.endDate + 3 * 60 * 60 * 1000;
            if (
              trip.tripStatus == "ONTRIP" &&
              endTime < Date.now() &&
              currentStation === lastStation
            ) {
              console.log("---------Trip Auto updated------------");
              this.updateTripStatus(tripId);
            }

            const upcommingStationEta = eta.rows[0][0];

            if (upcommingStationEta && currentStation) {
              const expectedReachingTime =
                Date.now() + upcommingStationEta.duration.value * 1000;

              let etaForUpcomingStation = "";

              const scheduleReachingTime =
                tripStartTime + upCommingStationDetailFromRoute.millisFromStart;

              if (expectedReachingTime - scheduleReachingTime < 0) {
                etaForUpcomingStation = getHoursAndMinFromMillis(
                  // scheduleReachingTime - Date.now()
                  expectedReachingTime - Date.now()
                );
              } else {
                etaForUpcomingStation = upcommingStationEta.duration.text;
              }

              if (expectedReachingTime - scheduleReachingTime > 0) {
                const delayedTime = expectedReachingTime - scheduleReachingTime;
                let time = Math.round(delayedTime / (60 * 1000));
                busStatus.name = `Delayed by ${time} mins`;
                busStatus.color = "#f50";
                busStatus.value = delayedTime;
                if (time < 61) {
                  busStatus.name = `late by ${time} mins`;
                  busStatus.color = "#f50";
                  busStatus.value = delayedTime;
                }
              } else {
                busStatus.name = "On Time";
                busStatus.value = 1;
                busStatus.color = "geekblue";
              }

              const returnObj = {
                ...trip,
                status: busStatus,
                lastUpdatedAt: {
                  ...trip.lastUpdatedAt,
                  lastUpdated: parseInt(lastTripData.latlngDate)
                }
              };

              if (upCommingStationDetailFromRoute) {
                returnObj.upcomingStation = {
                  ...trip.upcomingStation,
                  ...upCommingStationDetailFromRoute.station,
                  eta: etaForUpcomingStation,
                  status: "ONTRIP"
                };
              }

              return returnObj;
            }
          }

          return trip;
        }

        return trip;
      });

      this.setState({ trips: updatedTrips });
    } catch (err) {
      console.log(err);
    }
  };

  newIssueAdded = data => {
    const { trips } = this.state;

    const updatedTrips = trips.map(trip => {
      if (trip.tripId === data.tripId) {
        return {
          ...trip,
          issues: { ...trip.issues, count: trip.issues.count + 1 }
        };
      }

      return trip;
    });

    this.updateTitle(updatedTrips);

    this.setState({ trips: updatedTrips });
  };

  newOBDAdded = data => {
    const { trips } = this.state;

    const updatedTrips = trips.map(trip => {
      if (trip.busRc && trip.busRc.imei === data.busIMEI) {
        return {
          ...trip,
          // alerts: { ...trip.alerts, alert: trip.issues.count + 1 }
          issues: { ...trip.issues, count: trip.issues.count + 1 }
        };
      }
      return trip;
    });

    this.updateTitle(updatedTrips);

    this.setState({ trips: updatedTrips });
  };

  handlePassengerOnBoarded = data => {
    const { trips } = this.state;

    const updatedTrips = trips.map(trip => {
      if (trip.tripId === data.tripId) {
        return {
          ...trip
          // booking: { ...trip.booking, bookingCountObj: data.bookingCount }
        };
      }

      return trip;
    });

    this.setState({ trips: updatedTrips });
  };

  handleChange = obj => {
    this.setState(obj);
  };

  getServiceName = async () => {
    try {
      const url = `/bus-tracker/serviceNameEs`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  handleSOSView = async obj => {
    const { serviceNames } = this.state;
    let stateObj = obj;
    this.setState(obj);
    if (serviceNames) {
      stateObj.serviceNames = serviceNames;
    } else {
      stateObj.serviceNames = await this.getServiceName();
    }
    this.setState(stateObj);
  };

  handleTextChange = (key, value) => {
    this.setState({ [key]: value });
  };

  updateTripSummary = async value => {
    const {
      tripSummary,
      viewForTrip,
      trips,
      summaryRemark,
      summaryRemarkUpdateIndex
    } = this.state;
    let tripSummaryArr = [];
    for (let i = 0; i < tripSummary.length; i++) {
      const data = tripSummary[i];
      let newTripObj = {};
      if (i == value.index || i == summaryRemarkUpdateIndex) {
        newTripObj = {
          issueName: data.issueName,
          subIssueName: data.subIssueName,
          issueId: data.issueId,
          categoryName: data.categoryName,
          subSubIssueName: data.subSubIssueName,
          categoryId: data.categoryId,
          subIssueId: data.subIssueId,
          subSubIssueId: data.subSubIssueId,
          remark:
            summaryRemark && summaryRemark.length > 0
              ? summaryRemark
              : data.remark.value,
          status: value.status ? value.status : data.status.status
        };
      } else {
        newTripObj = {
          issueName: data.issueName,
          subIssueName: data.subIssueName,
          subSubIssueName: data.subSubIssueName,
          issueId: data.issueId,
          categoryName: data.categoryName,
          categoryId: data.categoryId,
          subIssueId: data.subIssueId,
          subSubIssueId: data.subSubIssueId,
          remark: data.remark.value,
          status: data.status.status
        };
      }
      tripSummaryArr.push(newTripObj);
    }

    const url = `/bus-tracker/update/trip/${viewForTrip}`;
    const options = {
      method: "post",
      data: {
        tripSummary: tripSummaryArr
      }
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let newTripsArr = trips.map(trip => {
        if (trip.tripId === viewForTrip) {
          return {
            ...trip,
            summary: { ...trip.summary, tripSummary: tripSummaryArr }
          };
        }

        return trip;
      });

      message.success("Summary Updated");
      this.setState({
        summaryRemark: "",
        viewTripSummary: false,
        showSummaryRemarkUpdate: false,
        trips: newTripsArr
      });
    } else {
      message.error("Summary Not Updated");
    }
  };

  handleViewSummary = async obj => {
    const tripSummarycolumns = [
      {
        title: "Index",
        dataIndex: "index",
        key: "index",
        width: "2%"
      },
      {
        title: "Issue Name",
        dataIndex: "issueName",
        key: "issueName",
        width: "10%"
      },
      {
        title: "Sub Issue Name",
        dataIndex: "subIssueName",
        key: "subIssueName",
        width: "10%"
      },
      {
        title: "Sub Sub Issue Name",
        dataIndex: "subSubIssueName",
        key: "subSubIssueName",
        width: "10%"
      },
      {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        width: "10%",
        render: ({ value, index }) => (
          <>
            {obj.isCompleted ? (
              <EditOutlined
                onClick={() =>
                  this.setState({
                    showSummaryRemarkUpdate: true,
                    summaryRemarkUpdateIndex: index - 1
                  })
                }
              />
            ) : null}

            <span>{value}</span>
          </>
        )
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "10%",
        render: ({ status, index }) => (
          <>
            {obj.isCompleted ? (
              <Select
                value={status}
                onChange={e =>
                  this.updateTripSummary({
                    status: e,
                    index: index - 1
                  })
                }
              >
                <Option value="INACTIVE">INACTIVE</Option>
                <Option value="ACTIVE">ACTIVE</Option>
              </Select>
            ) : (
              <span>{status}</span>
            )}
          </>
        )
      }
    ];
    let count = 1;
    const tripSummary = obj.tripSummary.map(obj => {
      let returnObj = {
        index: count++,
        issueName: obj.issueName,
        subIssueName: obj.subIssueName,
        subSubIssueName: obj.subSubIssueName,
        categoryName: obj.categoryName,
        remark: {
          value: obj.remark,
          index: count - 1
        },
        issueId: obj.issueId,
        subIssueId: obj.subIssueId,
        subSubIssueId: obj.subSubIssueId,
        status: {
          status: obj.status,
          index: count - 1
        }
      };
      return returnObj;
    });
    const viewForTrip = obj.tripId;

    this.setState({
      tripSummary,
      tripSummarycolumns,
      viewTripSummary: true,
      viewForTrip
    });
  };
  handleTripSummary = async obj => {
    const { tripSummaryIssues } = this.state;
    let issueResponse = tripSummaryIssues;

    if (!issueResponse) {
      issueResponse = await this.fetchIssue();
    }
    if (issueResponse) {
      this.setState({
        addTripSummary: true,
        summaryTripId: obj.tripId,
        tripSummaryIssues: issueResponse
      });
    } else {
      message.error("No subIssue found");
      this.setState({ addTripSummary: false });
    }
  };

  fetchIssue = async () => {
    try {
      const url = `/bus-tracker/tripSummary`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      return response;
    } catch (err) {
      message.error(err);
    }
  };
  fetchCategoryName = async subIssueId => {
    const { tripSummaryUpdation } = this.state;

    let subIssueResponse = await this.tripSummarySubSubIssue(subIssueId);

    this.setState({
      tripSummaryUpdation: {
        ...tripSummaryUpdation,
        selectedSubIssueId: subIssueId,
        subsubIssue: subIssueResponse
      }
    });
  };

  tripSummarySubIssue = async issueId => {
    const subIssueUrl = `/bus-tracker/tripIssueSubIssue/${issueId}`;
    const options = {
      method: "get"
    };
    let subIssueResponse = await Fetch(subIssueUrl, options);
    let tripSummaryUpdation = {};
    if (Array.isArray(subIssueResponse)) {
      tripSummaryUpdation.subIssue = subIssueResponse;
      tripSummaryUpdation.issueIdSelected = issueId;
      this.setState({ tripSummaryUpdation });
    } else {
      message.error("No subIssue found");
    }
  };

  tripSummarySubSubIssue = async subIssueId => {
    const subIssueUrl = `/bus-tracker/tripIssueSubSubIssue/${subIssueId}`;
    const options = {
      method: "get"
    };
    let subIssueResponse = await Fetch(subIssueUrl, options);
    if (Array.isArray(subIssueResponse)) {
      return subIssueResponse;
    } else {
      message.error("No sub subIssue found");
    }
  };

  insertTripSummary = async () => {
    let {
      tripSummaryIssues,
      tripSummaryUpdation,
      summaryTripId,
      trips
    } = this.state;

    const oldTripSummary = trips.filter(obj => obj.tripId == summaryTripId)[0]
      .summary.tripSummary;

    const {
      subIssue,
      issueIdSelected,
      selectedSubIssueId,
      selectedSubSubIssueId,
      remarks,
      selectedCategoryId,
      subsubIssue
    } = tripSummaryUpdation;
    if (!issueIdSelected || !selectedSubIssueId || !selectedSubSubIssueId) {
      message.error("please select issue , subIssue and Sub Sub Issue");
      return;
    }

    const issueName = tripSummaryIssues.filter(
      obj => obj._id == issueIdSelected
    )[0].name;
    const subIssueName = subIssue.filter(
      obj => obj._id == selectedSubIssueId
    )[0].name;

    const subSubIssueName = subsubIssue.filter(
      obj => obj._id == selectedSubSubIssueId
    )[0].name;
    let tripSummary = [];
    if (oldTripSummary && oldTripSummary.length > 0) {
      tripSummary = oldTripSummary;
    }
    const newTripObj = {
      issueName,
      categoryId: selectedCategoryId,
      subIssueName,
      subSubIssueName,
      issueId: issueIdSelected,
      subIssueId: selectedSubIssueId,
      subSubIssueId: selectedSubSubIssueId,
      remark: remarks,
      status: "ACTIVE"
    };
    tripSummary.push(newTripObj);

    const url = `/bus-tracker/update/trip/${summaryTripId}`;
    const options = {
      method: "post",
      data: {
        tripSummary
      }
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      let newTripsArr = trips.map(trip => {
        if (trip.tripId === summaryTripId) {
          return {
            ...trip,
            summary: {
              ...trip.summary,
              isSummary: true,
              tripSummary: tripSummary
            }
          };
        }

        return trip;
      });

      message.success("Summary Added");
      this.setState({
        addTripSummary: false,
        trips: newTripsArr,
        tripSummaryUpdation: {}
      });
    } else {
      message.error("Summary Not Added");
    }
  };

  getprivlages = visibleToUserWithPrivileges => {
    let privlage = "";
    privlage += `${visibleToUserWithPrivileges}`;
    return privlage;
  };

  updateTripStatus = async tripId => {
    try {
      const response = await markTripCompleted(tripId);

      if (response.length) {
        window.location.reload();
      }
    } catch (err) {
      message.error(err);
      console.log(err);
    }
  };

  updateTripStatusManually = async tripId => {
    try {
      const response = await markTripCompletedManually(tripId);

      if (response.length) {
        window.location.reload();
      }
    } catch (err) {
      message.error(err);
      console.log(err);
    }
  };

  formatTask = async (tasks, privilege) => {
    let todayDate = new Date();
    todayDate = todayDate.setHours(0, 0, 0, 0);
    let previousDayDate = todayDate - 24 * 60 * 60 * 1000;
    const taskMap = tasks.map(async task => {
      const {
        taskMaster = {},
        status,
        comment,
        Comment,
        closeComment,
        taskCode,
        comments,
        etaDate,
        generationDate,
        assignedUser = {},
        job = {},
        audit = {},
        bus = {},
        busHost = {},
        busDriver = {},
        agent = {},
        station = {},
        amount,
        service = {},
        type,
        trip,
        booking,
        attachments,
        attachment,
        createdByUser,
        responseByUser
      } = task;

      let timeDiff = etaDate - previousDayDate;
      const taskFormated = {
        task: {
          taskName: taskMaster.name ? taskMaster.name : job.name,
          auditName: taskMaster.name ? taskCode : audit.name
        },
        busHost: { busHost: busHost.name },
        busDriver: { busDriver: busDriver.name },
        agent: { agent: agent.name },
        station: { station: station.name },
        amount: { amount: amount },

        type: {
          typeName: taskMaster.name ? "Task" : "Audit",
          taskType: taskMaster.name ? type : null
        },
        serviceName: {
          serviceName: service ? service.name : null,
          serviceDate: trip ? moment(trip.tripDate).format("LL") : null
        },
        pnr: {
          value: booking ? booking.bookingCode : null
        },
        busNumber: {
          busNumber: bus.rc,
          onChange: () =>
            this.setState({
              editField: "busNumber",
              editTask: true,
              taskToEdit: task
            })
        },
        assignedTo: {
          visibleto: taskMaster.name
            ? assignedUser.name
            : await this.getprivlages(job.visibleToUserWithPrivileges),
          hubs: service.serviceHub
        },
        generationDate: {
          time: moment(generationDate).format("LL"),
          timefilter: generationDate
        },
        createdBy: createdByUser
          ? { name: createdByUser.name }
          : { name: "System" }, //createdByUserId
        tat: etaDate
          ? {
              privilege: privilege,
              time: moment(etaDate).format("LL"),
              isTaskMaster: taskMaster.name ? false : true,
              onChange: () =>
                this.setState({
                  editField: "tat",
                  editTask: true,
                  taskToEdit: task
                })
            }
          : { time: null },
        closedBy:
          status === "CLOSED" && responseByUser
            ? { closeuser: responseByUser.name }
            : { closeuser: null },
        status: {
          privilege: privilege,
          assignedUser: assignedUser,
          iseditable: taskMaster.name
            ? status !== "INPROGRESS"
            : etaDate + 24 * 60 * 60 * 1000 - 1 < new Date().getTime() &&
              status !== "CLOSED",
          taskStatus:
            etaDate + 24 * 60 * 60 * 1000 - 1 < new Date().getTime() &&
            status !== "CLOSED" &&
            status !== "REJECTED"
              ? "LAPSED"
              : status,
          onChange: () =>
            this.setState({
              editField: taskMaster.name ? "Task Status" : "status",
              editTask: true,
              taskToEdit: task
            })
        },
        closeComment: closeComment,
        comments: {
          commentName: taskMaster.name ? Comment || comment : comments,
          privilege: privilege,
          isTaskMaster: taskMaster.name ? false : true,
          onChange: () =>
            this.setState({
              editField: "comments",
              editTask: true,
              taskToEdit: task
            })
        },
        viewTimeline: {
          onChange: () =>
            this.setState({
              editField: "View Timelines",
              viewTimelines: true,
              taskToEdit: task
            })
        },

        viewAttachment: {
          isAttachmentRequired: taskMaster.name
            ? taskMaster.isAttachmentRequired
            : true,
          attachments: taskMaster.name ? attachment : attachments,
          onChange: () =>
            this.setState({
              editField: "View Attachments",
              viewAttachments: true,
              taskToEdit: task
            })
        },

        remindTeam: {
          timeDifference: timeDiff,
          status: status,
          onClick: () =>
            this.remindTeam(
              service.name,
              service.serviceHub,
              job.visibleToUserWithPrivileges,
              job.name
            )
        }
      };

      return taskFormated;
    });

    return Promise.all(taskMap);
  };

  // searchUser = async () => {
  //   try {
  //     const url = `/bus-tracker/user`;
  //     const options = {
  //       method: "get"
  //     };

  //     const response = await Fetch(url, options);
  //     if (response) {
  //       return response;
  //     } else {
  //       throw new Error("No response");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  remindTeam = async (
    servicename,
    serviceHub,
    visibleToUserWithPrivileges,
    name
  ) => {
    try {
      const url = `/bus-tracker/remindTeam?place=${serviceHub}&privileges=${visibleToUserWithPrivileges}&name=${name}&servicename=${servicename}`;
      const options = {
        method: "post",
        body: {
          hub: serviceHub,
          privileges: visibleToUserWithPrivileges,
          name: name,
          servicename: servicename
        }
      };
      const response = await Fetch(url, options);

      if (response) {
        return message.success("Notification Sent");
      } else {
        throw new Error("No response");
      }
    } catch (err) {
      console.log(err);
    }
  };

  formatTrips = trips => {
    let todayTrip = [];
    let pastTrip = [];
    let tomorrowTrip = [];

    let todayTripTime = new Date();
    todayTripTime = todayTripTime.setHours(0, 0, 0, 0);

    for (let i = 0; i < trips.length; ++i) {
      const trip = trips[i];
      const { tripDate } = trip;

      if (parseInt(tripDate) === todayTripTime) {
        todayTrip.push(trip);
      } else if (parseInt(tripDate) > todayTripTime) {
        tomorrowTrip.push(trip);
      } else {
        pastTrip.push(trip);
      }
    }

    pastTrip = pastTrip.sort(
      (a, b) =>
        a.tripDate + a.service.startTime - (b.tripDate + b.service.startTime)
    );

    todayTrip = todayTrip.sort(
      (a, b) =>
        a.tripDate + a.service.startTime - (b.tripDate + b.service.startTime)
    );

    tomorrowTrip = tomorrowTrip.sort(
      (a, b) =>
        a.tripDate + a.service.startTime - (b.tripDate + b.service.startTime)
    );

    trips = [...pastTrip, ...todayTrip, ...tomorrowTrip];

    return trips.map(trip => {
      const {
        service,
        tripDate,
        status,
        _id,
        upcomingStation = {},
        busRoute = [],
        lastData = {},
        eta,
        issues,
        issuesCount,
        faults = [],
        alerts = [],
        crewDetails,
        bookingCountObj,
        lastUpdated = 0,
        startTime,
        endTime,
        timeInput,
        commentInput,
        taskKey,
        inventoryDetails,
        newStatusComment = false,
        isTrackingWorking = false,
        userprivileges,
        trailingTripConnecting,
        leadingTripConnecting,
        tripSummary,
        boardedCount,
        notBoardedCount,
        bookingCount,
        alertCount,
        complaintCount
      } = trip;
      const tripStartTime = service.startTime;

      const busStatus = {
        names: trip.isPseudoOnTrip ? ["Marshal Not Logged In"] : [],
        name: "",
        color: "red",
        isValidTrackingData: `${trip.isValidTrackingData}`,
        isPseudoOnTrip: `${trip.isPseudoOnTrip}`,
        ignition: lastData.ignitionStatus || "OFF",
        speed: lastData.speed || 0,
        ignitionColor: "green",
        onClick: this.handleChange,
        statusComment: this.newStatusComment,
        timeInput: timeInput,
        currentDate: new Date().getTime(),
        commentInput: commentInput,
        newStatusComment,
        tripId: trip._id
      };

      if (leadingTripConnecting && trailingTripConnecting) {
        let headTrip = leadingTripConnecting[0];
        let tailTrip = trailingTripConnecting[0];
        let leadingEtaForUpcomingStation = "";
        let trailingEtaForUpcomingStation = "";
        let leadingEtaForCnnecting = "";
        let trailingEtaForCnnecting = "";

        const changeStationName = headTrip.headChangeStationName;

        let leadingBusStatus = {
          name: "",
          color: "red",
          onClick: this.handleChange,
          tripId: trip._id
        };
        const leadingUpCommingStationDetailFromRoute = headTrip.busRoute.filter(
          station => station.stationId === headTrip.upcomingStation._id
        );
        const leadingReachingTimeForUpcommingStation = leadingUpCommingStationDetailFromRoute.length
          ? headTrip.tripDate +
            headTrip.service.startTime +
            leadingUpCommingStationDetailFromRoute[0].millisFromStart
          : null;

        if (headTrip.eta && Array.isArray(headTrip.eta.rows)) {
          const upcommingStationEta = headTrip.eta.rows[0];

          if (upcommingStationEta) {
            const expectedReachingTime =
              Date.now() + upcommingStationEta[0].duration.value * 1000;

            if (
              expectedReachingTime - leadingReachingTimeForUpcommingStation <
              0
            ) {
              leadingEtaForUpcomingStation = getHoursAndMinFromMillis(
                leadingReachingTimeForUpcommingStation - Date.now()
              );
            } else {
              leadingEtaForUpcomingStation =
                upcommingStationEta[0].duration.text;
            }
            if (
              expectedReachingTime - leadingReachingTimeForUpcommingStation >
              0
            ) {
              const delayedTime =
                expectedReachingTime - leadingReachingTimeForUpcommingStation;
              leadingBusStatus.name = `Delayed by ${Math.round(
                delayedTime / (60 * 1000)
              )} mins`;
              leadingBusStatus.color = "#f50";
              leadingBusStatus.value = delayedTime;
            } else {
              leadingBusStatus.name = "On Time";
              leadingBusStatus.color = "geekblue";
              leadingBusStatus.value = 1;
            }
          } else {
            leadingBusStatus.name = "Upcoming station eta not found";
            leadingBusStatus.color = "red";
            leadingBusStatus.value = 1;
          }
        } else {
          leadingBusStatus.name = "Tracking Not Working";
          leadingBusStatus.color = "red";
          leadingBusStatus.value = 1;
        }

        const leadingConnectingStationDetailFromRoute = headTrip.connectingStation.filter(
          station => station.stationId === changeStationName.station._id
        );
        leadingEtaForCnnecting =
          leadingConnectingStationDetailFromRoute.length &&
          headTrip.status == "ONTRIP"
            ? leadingConnectingStationDetailFromRoute[0].stationMarked
              ? "Station Marked"
              : getHoursAndMinFromMillis(
                  leadingConnectingStationDetailFromRoute[0]
                    .expectedReachingTime - Date.now()
                )
            : null;

        const trailingUpCommingStationDetailFromRoute = tailTrip.busRoute.filter(
          station => station.stationId === tailTrip.upcomingStation._id
        );
        const trailingReachingTimeForUpcommingStation = trailingUpCommingStationDetailFromRoute.length
          ? tailTrip.tripDate +
            tailTrip.service.startTime +
            trailingUpCommingStationDetailFromRoute[0].millisFromStart
          : null;

        if (tailTrip.eta && Array.isArray(tailTrip.eta.rows)) {
          const upcommingStationEta = tailTrip.eta.rows[0];

          if (upcommingStationEta) {
            const expectedReachingTime =
              Date.now() + upcommingStationEta[0].duration.value * 1000;

            if (
              expectedReachingTime - trailingReachingTimeForUpcommingStation <
              0
            ) {
              trailingEtaForUpcomingStation = getHoursAndMinFromMillis(
                trailingReachingTimeForUpcommingStation - Date.now()
              );
            } else {
              trailingEtaForUpcomingStation =
                upcommingStationEta[0].duration.text;
            }
            if (
              expectedReachingTime - leadingReachingTimeForUpcommingStation >
              0
            ) {
              const delayedTime =
                expectedReachingTime - leadingReachingTimeForUpcommingStation;
              busStatus.name = `Delayed by ${Math.round(
                delayedTime / (60 * 1000)
              )} mins`;
              busStatus.color = "#f50";
              busStatus.value = delayedTime;
            } else {
              busStatus.name = "On Time";
              busStatus.color = "geekblue";
              busStatus.value = 1;
            }
          } else {
            busStatus.name = "Upcoming station eta not found";
            busStatus.color = "red";
            busStatus.value = 1;
          }
        } else {
          busStatus.name = "Tracking Not Working";
          busStatus.color = "red";
          busStatus.value = 1;
        }

        // const trailingUpCommingStationDetailFromRoute = tailTrip.connectingStation.filter(
        //   station =>
        //     station.stationId === tailTrip.upcomingStation._id
        // );
        // trailingEtaForUpcomingStation = trailingUpCommingStationDetailFromRoute.length
        //   ? getHoursAndMinFromMillis(
        //       trailingUpCommingStationDetailFromRoute[0].expectedReachingTime -
        //         Date.now()
        //     )
        //   : null;
        const trailingChangeStationName = tailTrip.tailChangeStationName;
        if (trailingChangeStationName) {
          const trailingConnectingStationDetailFromRoute = tailTrip.connectingStation.filter(
            station =>
              station.stationId === trailingChangeStationName.station._id
          )[0];

          trailingEtaForCnnecting =
            trailingConnectingStationDetailFromRoute &&
            tailTrip.status == "ONTRIP"
              ? trailingConnectingStationDetailFromRoute.stationMarked
                ? "Station Marked"
                : getHoursAndMinFromMillis(
                    trailingConnectingStationDetailFromRoute.expectedReachingTime -
                      Date.now()
                  )
              : null;
        }

        const tripFormated = {
          serviceName: {
            name: service.name,
            tripId: _id,
            status,
            stationtime: headTrip.startTime,
            serviceStatus: service.status
          },
          tripStartTime: trip.tripDate + service.startTime,
          fromStationTime: startTime,
          LeadingServiceName: {
            name: headTrip.service.name
          },
          connectingStatus:
            busStatus.color == "#f50" || leadingBusStatus.color == "#f50"
              ? (leadingBusStatus = { name: "Late", color: "#f50", value: 1 })
              : (leadingBusStatus = {
                  name: "On Time",
                  color: "geekblue",
                  value: 1
                }),
          LeadingUpcommingStation: leadingUpCommingStationDetailFromRoute[0]
            ? {
                ...leadingUpCommingStationDetailFromRoute[0].station,
                eta: leadingEtaForUpcomingStation,
                onClick: this.handleChange,
                tripId: headTrip._id,
                status: headTrip.status,
                tripStatus: headTrip.status
              }
            : {
                onClick: this.handleChange,
                status: headTrip.status,
                tripStatus: headTrip.status,
                tripId: headTrip._id
              },

          interChangeStation: {
            name: changeStationName.station.name,
            eta: leadingEtaForCnnecting
          },

          trailingServiceName: {
            name: tailTrip.service.name
          },
          trailingUpcommingStation: trailingUpCommingStationDetailFromRoute[0]
            ? {
                ...trailingUpCommingStationDetailFromRoute[0].station,
                eta: trailingEtaForUpcomingStation,
                onClick: this.handleChange,
                tripId: tailTrip._id,
                status: tailTrip.status,
                tripStatus: tailTrip.status
              }
            : {
                onClick: this.handleChange,
                status: tailTrip.status,
                tripId: tailTrip._id,
                tripStatus: tailTrip.status
              },
          trailingInterChangeStation: {
            name: trailingChangeStationName
              ? trailingChangeStationName.station.name
              : null,
            eta: trailingEtaForCnnecting
          },

          booking: {
            status,
            tripId: _id,
            onClick: this.handleChange
          },
          lastData,
          key: _id,
          tripId: _id,
          busRoute,
          tripStatus: status,
          headTrip,
          tailTrip
        };
        return tripFormated;
      }
      // const issueCount = issues.filter(
      //   d => d.status === "OPEN" || d.status === "TIMEDOUT"
      // ).length;
      const SOSCount = alertCount.count + complaintCount.count;

      const upCommingStationDetailFromRoute = busRoute.filter(
        station => station.stationId === upcomingStation._id
      );

      let etaForUpcomingStation = "";

      if (!parseInt(busStatus.speed)) {
        busStatus.ignitionColor = "yellow";
      }

      if (busStatus.ignition !== "ON") {
        busStatus.ignitionColor = "red";
      }

      const reachingTimeForUpcommingStation = upCommingStationDetailFromRoute.length
        ? tripDate +
          tripStartTime +
          upCommingStationDetailFromRoute[0].millisFromStart
        : null;

      if (eta && Array.isArray(eta.rows)) {
        const upcommingStationEta = eta.rows[0];

        if (upcommingStationEta) {
          const expectedReachingTime =
            Date.now() + upcommingStationEta[0].duration.value * 1000;

          if (expectedReachingTime - reachingTimeForUpcommingStation < 0) {
            etaForUpcomingStation = getHoursAndMinFromMillis(
              reachingTimeForUpcommingStation - Date.now()
            );
          } else {
            etaForUpcomingStation = upcommingStationEta[0].duration.text;
          }

          if (expectedReachingTime - reachingTimeForUpcommingStation > 0) {
            const delayedTime =
              expectedReachingTime - reachingTimeForUpcommingStation;
            const time = Math.round(delayedTime / (60 * 1000));
            busStatus.name = `Delayed by ${time} mins`;
            busStatus.color = "#f50";
            busStatus.value = delayedTime;
            if (time < 61) {
              busStatus.name = `late by ${time} mins`;
              busStatus.color = "#f50";
              busStatus.value = delayedTime;
            }
            if (busStatus.isPseudoOnTrip === "true") {
              busStatus.names.push(
                `Delayed by ${Math.round(delayedTime / (60 * 1000))} mins`
              );
            }
          } else {
            busStatus.name = "On Time";
            busStatus.color = "geekblue";
            busStatus.value = 1;
            if (busStatus.isPseudoOnTrip === "true") {
              busStatus.names.push("On Time");
            }
          }
        } else {
          busStatus.name = "Upcoming station eta not found";
          busStatus.color = "red";
          busStatus.value = 1;
        }
      } else {
        busStatus.name = "Tracking Not Working";
        busStatus.color = "red";
        busStatus.value = 1;
        if (busStatus.isPseudoOnTrip === "true") {
          busStatus.names.push("Tracking Not Working");
        }
      }

      const { busHost, bus, busDriver } = crewDetails;

      if (!busHost && !bus && !busDriver) {
        busStatus.name = `Trip Not Assigned Yet`;
        busStatus.color = "purple";
      } else if (status === "ACTIVE") {
        const timeDiff = tripDate + tripStartTime - Date.now();

        if (timeDiff > 1 * 60 * 60 * 1000) {
          busStatus.name = `Will Start in ${getHoursAndMinFromMillis(
            timeDiff
          )}`;
          busStatus.color = "cyan";
          busStatus.value = timeDiff;
        } else if (timeDiff < 1 * 60 * 60 * 1000 && timeDiff > 0) {
          busStatus.name = `Marshal Not Logged In`;
          busStatus.color = "red";
          busStatus.value = 1;
        } else if (timeDiff < 0) {
          busStatus.name = "Bus Not Started yet";
          busStatus.color = "#d35400";
          busStatus.value = 1;
        }
      }

      if (status === "COMPLETED") {
        busStatus.name = "Completed";
        busStatus.color = "blue";
        busStatus.value = 1;
      }
      const tripSummaryCompleteTime =
        tripDate +
        tripStartTime +
        busRoute[busRoute.length - 1].millisFromStart +
        4 * 60 * 60 * 1000;
      const tripFormated = {
        serviceName: {
          SOSCount,
          name: service.name,
          tripId: _id,
          status,
          cabServiceType: service.cabServiceType,
          serviceStatus: service.status,
          stationtime: startTime,
          cstatus: crewDetails.status,
          onClick: this.handleSOSView
        },
        SOSCount,
        tripStartTime: trip.tripDate + service.startTime,
        fromStationTime: startTime,
        status: busStatus,
        upcommingStation: upCommingStationDetailFromRoute[0]
          ? {
              ...upCommingStationDetailFromRoute[0].station,
              eta: etaForUpcomingStation,
              onClick: this.handleChange,
              tripId: _id,
              status
            }
          : {
              onClick: this.handleChange,
              status,
              tripId: _id
            },
        booking: {
          status,
          tripId: _id,
          onClick: this.handleChange,
          bookingCountObj: notBoardedCount.count
        },
        taskList: { onClick: this.handleChange, tripId: _id, name: taskKey },
        lastData,
        busRc: bus,
        key: _id,
        tripId: _id,
        summary: {
          tripSummary,
          addSummary: this.handleTripSummary,
          viewSummary: this.handleViewSummary,
          isSummary: !!tripSummary,
          tripId: _id,
          isCompleted: tripSummaryCompleteTime > Date.now()
        },
        crewDetails: {
          onClick: this.handleChange,
          privilege: userprivileges,
          tripId: _id,
          name: crewDetails.status
        },
        inventory: {
          onClick: this.handleChange,
          tripId: _id,
          name: inventoryDetails.status
        },

        busRoute,
        lastUpdatedAt: {
          onClick: this.handleChange,
          lastUpdated,
          endTime,
          status,
          tripId: _id,
          updateTripStatusManually: this.updateTripStatusManually,
          updateTrackingStatus: this.isTrackingWorking,
          isTrackingWorking
        },
        tripStatus: status
      };
      return tripFormated;
    });
  };

  onTripSearch = (trips, tripFilter) => {
    try {
      const { privileges } = this.state;
      let formatedTrips = "";
      if (tripFilter) {
        formatedTrips = trips;
        this.setState({ trips: formatedTrips });
      } else {
        formatedTrips = this.formatTrips(trips, privileges);
        this.setState({ trips: formatedTrips, allTrips: trips });
      }
    } catch (err) {
      message.error(err);
    }
  };

  onConnectingTripSearch = trips => {
    try {
      const { privileges } = this.state;
      let formatedTrips = "";
      formatedTrips = this.formatTrips(trips, privileges);
      this.setState({ connectingTrips: formatedTrips });
    } catch (err) {
      message.error(err);
    }
  };

  onTaskSearch = (tasks, tasksCount, searchTaskFlag, responseCount) => {
    try {
      let { privileges, users, defaultpage } = this.state;
      defaultpage += responseCount;
      this.formatTask(tasks, privileges, users).then(input =>
        this.setState({
          tasks: input,
          searchedTask: input,
          paginationTotal: tasksCount,
          privilege: privileges,
          searchTaskFlag: searchTaskFlag,
          defaultpage: defaultpage
        })
      );
      if (defaultpage === 2) {
        this.onChange(1, searchTaskFlag);
      }
    } catch (err) {
      message.error(err);
    }
  };

  onTaskUpdate = (tasks, editedTask) => {
    try {
      const { privileges, searchTaskFlag, searchedTask } = this.state;
      if (searchTaskFlag) {
        this.formatTask(searchedTask, privileges).then(() =>
          this.setState({
            tasks: searchedTask,
            editedTask: editedTask
          })
        );
      } else {
        this.formatTask(tasks, privileges).then(input =>
          this.setState({
            tasks: input,
            privilege: privileges,
            searchTaskFlag: searchTaskFlag,
            editedTask: editedTask
          })
        );
      }
    } catch (err) {
      message.error(err);
    }
  };
  getTrips = async () => {
    try {
      const url = `/bus-tracker/allTrips`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getAllQLTrips = async () => {
    try {
      const url = `/bus-tracker/allQLTrips`;

      const options = {
        method: "post"
      };

      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getTasks = async () => {
    try {
      // const url = `/bus-tracker/tasks?page=${page}`;
      const url = `/bus-tracker/tasks`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  refreshTrips = async () => {
    // try {
    //   const response = await this.getTrips();
    //   const trips = this.formatTrips(response);
    //   this.updateTitle(trips);
    //   this.setState({ trips, allTrips: response });
    // } catch (err) {
    //   console.log(err);
    // }
  };

  resetTrips = async () => {
    try {
      this.setState({ loading: true });

      const response = this.getTrips();

      const trips = this.formatTrips(response);

      this.updateTitle(trips);

      this.setState({ loading: false, trips, allTrips: response });
    } catch (err) {
      console.log(err);
    }
  };

  isTrackingWorking = async data => {
    try {
      const path = "/bus-tracker/trackingWorking";
      const options = {
        method: "post",
        data
      };
      const response = await Fetch(path, options);

      if (response) {
        console.log("Updated");
        window.location.reload(false);
      }
    } catch (err) {
      console.log(err);
      message.error("Wrong");
    }
  };

  // onChange = async (page, taskSearched) => {
  //   let { tasks, users, privileges } = this.state;

  //   if (taskSearched !== true) {
  //     let taskOnPage = await this.getTasks(page);
  //     tasks = await this.formatTask(taskOnPage.tasksResult, privileges, users);
  //     this.setState({
  //       tasks,
  //       current: page
  //     });
  //   } else if (taskSearched) {
  //     this.setState({
  //       current: page
  //     });
  //   }
  // };

  fetchConnectingTrips = async () => {
    try {
      const url = `/bus-tracker/allQLConnectingTrips`;

      const options = {
        method: "post"
      };

      const response = await Fetch(url, options);
      return response;
    } catch (err) {
      message.error(err);
    }
  };
  handleTaskStatusFilter = Filter => {
    this.setState({ taskStatusFilter: Filter });
  };

  onChange = async key => {
    this.setState({
      taskloading: true,
      activeKey: key,
      connectingLoader: true
      // drawerWidth: "12%"
    });
    let { tasks, connectingTrips } = this.state;
    if (tasks.length === 0 && key == 2) {
      let tasksResponse = await this.getTasks();

      if (tasksResponse !== "no task found") {
        tasks = await this.formatTask(
          tasksResponse.data,
          tasksResponse.userprivileges
        );
        this.setState({
          taskloading: false,
          tasks,
          newTasks: tasksResponse.EtaDateCount
        });
      } else {
        this.setState({
          taskloading: false
        });
      }
    }
    if (connectingTrips.length == 0 && key == 3) {
      let tripResponse = await this.fetchConnectingTrips();
      if (tripResponse && tripResponse !== "No data found") {
        tripResponse = await this.formatTrips(tripResponse);
        this.setState({
          connectingLoader: false,
          connectingTrips: tripResponse
        });
      } else {
        this.setState({
          connectingLoader: false
        });
      }
    }
    this.setState({
      taskloading: false,
      connectingLoader: false
    });
  };
  handleTypeFilterChange = Filter => {
    this.setState({ typeFilter: Filter });
  };
  changeLoadingOnRefresh = state => {
    this.setState({ taskloading: state });
  };
  handleDrawer = () => {
    const { drawerWidth } = this.state;
    if (drawerWidth == "12%") {
      this.setState({ drawerWidth: "4%" });
    } else {
      this.setState({ drawerWidth: "12%" });
    }
  };

  render() {
    const {
      loading,
      trips,
      tasks,
      alltasks,
      showBookingList,
      showConnectingBookingList,
      tripId,
      showStationList,
      showSOS,
      serviceNames,
      showConnectingStationList,
      showUtilization,
      showTrailingConnectingStationList,
      showCrewDetails,
      showAssignCrewDetails,
      allTrips,
      showIssueList,
      addTripSummary,
      tripSummaryIssues = [],
      viewTripSummary,
      showSummaryRemarkUpdate,
      summaryRemark,
      tripSummarycolumns = [],
      tripSummary = [],
      RaiseIssueList,
      showTaskList,
      editField,
      showInventoryDetails,
      taskToEdit,
      paginationTotal,
      current,
      newTasks,
      searchTaskFlag,
      editedTask,
      editTask,
      viewAttachments,
      viewTimelines,
      filtertrips,
      taskloading,
      connectingTrips,
      connectingLoader,
      status,
      cities,
      drawerWidth,
      activeKey,
      tripSummaryUpdation = {},
      filterMoreTableData
    } = this.state;
    return (
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          height: "100vh"
        }}
      >
        <div className="row" style={{ alignItems: "center", width: "100%" }}>
          <div
            className="col-1  text-left"
            style={{ paddingLeft: "30px", paddingBottom: "2px" }}
          >
            <button className="menuBtn" onClick={this.handleDrawer}>
              <img style={{ width: "20px" }} src={menu} alt="menu" />
            </button>
          </div>
          <div className="col-11">
            <WebHeader onLogoClick={this.resetTrips} />
          </div>
        </div>
        <Tabs
          tabPosition={"left"}
          tabBarStyle={{ width: drawerWidth }}
          defaultActiveKey="1"
          onChange={this.onChange}
        >
          <TabPane
            tab={
              <Tooltip placement="rightTop" title="Trips">
                <span>
                  {" "}
                  <img
                    style={{ width: "17px" }}
                    src={activeKey == 1 ? tripSideCol : tripSide}
                    alt="menu"
                  />{" "}
                  {drawerWidth == "12%" ? "Trips" : null}
                </span>
              </Tooltip>
            }
            key="1"
          >
            <div>
              <SearchForm
                cities={cities}
                onChange={this.handleChange}
                onTripSearch={this.onTripSearch}
                trips={filtertrips}
              />
            </div>
            <Table
              columns={columns}
              dataSource={trips}
              pagination={false}
              loading={loading}
              scroll={{ y: "70vh" }}
            />
            {showBookingList && (
              <>
                <BookingList
                  tripId={tripId}
                  upcomingStation={
                    trips.filter(trip => trip.tripId === tripId)[0]
                      .upcommingStation
                  }
                  busRoute={
                    trips.filter(trip => trip.tripId === tripId)[0].busRoute
                  }
                  onCancel={() => this.handleChange({ showBookingList: false })}
                />
              </>
            )}
            {showStationList ? (
              <StationList
                tripId={tripId}
                tripStatus={
                  trips.filter(trip => trip.tripId === tripId)[0].tripStatus
                }
                onCancel={() => this.handleChange({ showStationList: false })}
              />
            ) : null}
            {showSOS ? (
              <Modal
                centered
                title="Trip SOS"
                visible={showSOS}
                footer={null}
                width={"100%"}
                onCancel={() =>
                  this.setState({
                    showSOS: false
                  })
                }
              >
                <TripSOS
                  onCancel={() => this.setState({ showSOS: false })}
                  tripId={tripId}
                  serviceNames={serviceNames}
                />
              </Modal>
            ) : null}

            {viewTripSummary ? (
              <Modal
                centered
                title="Add Trip Summary"
                visible={true}
                footer={null}
                width={"100%"}
                onCancel={() =>
                  this.setState({
                    viewTripSummary: false
                  })
                }
              >
                <Table
                  columns={tripSummarycolumns}
                  dataSource={tripSummary}
                  pagination={false}
                />
              </Modal>
            ) : null}
            {addTripSummary ? (
              <Modal
                centered
                title="Add Trip Summary"
                visible={true}
                width={"800px"}
                onCancel={() =>
                  this.setState({
                    addTripSummary: false,
                    tripSummaryUpdation: {}
                  })
                }
                onOk={this.insertTripSummary}
                okText="Save"
              >
                <div className="row">
                  <div className="col-3">Select Issue Name</div>
                  <div className="col-6 text-left">
                    <Select
                      className="mb-1"
                      style={{ width: "400px" }}
                      defaultValue=" "
                      onChange={key => this.tripSummarySubIssue(key)}
                    >
                      {tripSummaryIssues.map(issues => (
                        <Option value={issues._id} key={issues._id}>
                          {issues.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                {Array.isArray(tripSummaryUpdation.subIssue) &&
                tripSummaryUpdation.subIssue.length > 0 ? (
                  <div className="row">
                    <div className="col-3">Select Sub Issue Name</div>
                    <div className="col-6 text-left">
                      <Select
                        className="mb-1"
                        style={{ width: "400px" }}
                        defaultValue=" "
                        onChange={key => this.fetchCategoryName(key)}
                      >
                        {tripSummaryUpdation.subIssue.map(subis => (
                          <Option value={subis._id} key={subis._id}>
                            {subis.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : null}
                {Array.isArray(tripSummaryUpdation.subsubIssue) &&
                tripSummaryUpdation.subsubIssue.length > 0 ? (
                  <div className="row">
                    <div className="col-3">Select Sub Sub Issue Name</div>
                    <div className="col-6 text-left">
                      <Select
                        className="mb-1"
                        style={{ width: "400px" }}
                        defaultValue=" "
                        onChange={value =>
                          this.setState({
                            tripSummaryUpdation: {
                              ...tripSummaryUpdation,
                              selectedSubSubIssueId: value
                            }
                          })
                        }
                      >
                        {tripSummaryUpdation.subsubIssue.map(subsub => (
                          <Option value={subsub._id} key={subsub._id}>
                            {subsub.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : null}

                {tripSummaryUpdation.selectedSubSubIssueId ? (
                  <>
                    <div className="row">
                      <div className="col-3">Remarks</div>
                      <div className="col-6 text-left">
                        <TextArea
                          className="mb-1"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          style={{ width: "400px" }}
                          onChange={e =>
                            this.setState({
                              tripSummaryUpdation: {
                                ...tripSummaryUpdation,
                                remarks: e.target.value
                              }
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </Modal>
            ) : null}
            {showSummaryRemarkUpdate ? (
              <Modal
                title="OutBound Remarks"
                visible={showSummaryRemarkUpdate}
                okText="Update"
                onOk={this.updateTripSummary}
                onCancel={() =>
                  this.setState({
                    summaryRemark: "",
                    showSummaryRemarkUpdate: false
                  })
                }
              >
                <Input
                  value={summaryRemark}
                  placeholder="Enter Remark"
                  onChange={e =>
                    this.handleTextChange("summaryRemark", e.target.value)
                  }
                />
              </Modal>
            ) : null}
            {RaiseIssueList ? (
              <RaiseIssue
                tripId={tripId}
                onChange={obj => this.setState(obj)}
                onCancel={() => this.handleChange({ RaiseIssueList: false })}
              />
            ) : null}
            {showTaskList ? (
              <TaskList
                tripId={tripId}
                onCancel={() => this.handleChange({ showTaskList: false })}
              />
            ) : null}
            {showUtilization ? (
              <BusInformation
                trip={allTrips.filter(trip => trip._id === tripId)[0]}
                onCancel={() => this.handleChange({ showUtilization: false })}
              />
            ) : null}
            {showCrewDetails ? (
              <CrewDetails
                details={
                  allTrips.filter(trip => trip._id === tripId)[0].crewDetails
                }
                onCancel={() => this.handleChange({ showCrewDetails: false })}
              />
            ) : null}
            {showAssignCrewDetails ? (
              <Modal
                title="Crew Updation"
                visible={showAssignCrewDetails}
                footer={null}
                onCancel={() =>
                  this.setState({
                    showAssignCrewDetails: false
                  })
                }
              >
                <AssignCrewDetails
                  tripId={tripId}
                  details={
                    allTrips.filter(trip => trip._id === tripId)[0].crewDetails
                  }
                  close={() =>
                    this.setState({
                      showAssignCrewDetails: false
                    })
                  }
                />
              </Modal>
            ) : null}
            {showIssueList ? (
              <IssuesList
                crewDetails={
                  allTrips.filter(trip => trip._id === tripId)[0].crewDetails
                }
                tripId={tripId}
                onCancel={() => this.handleChange({ showIssueList: false })}
              />
            ) : null}
            {showInventoryDetails ? (
              <InventoryDetails
                details={
                  allTrips.filter(trip => trip._id === tripId)[0]
                    .inventoryDetails
                }
                onCancel={() =>
                  this.handleChange({ showInventoryDetails: false })
                }
              />
            ) : null}
          </TabPane>
          <TabPane
            tab={
              <Tooltip placement="rightTop" title="Connecting Trips">
                <span>
                  {" "}
                  <img
                    style={{ width: "20px" }}
                    src={activeKey == 3 ? connectingTripCol : connectingTrip}
                    alt="menu"
                  />{" "}
                  {drawerWidth == "12%" ? "Connecting Trips" : null}
                </span>
              </Tooltip>
            }
            // tab={
            //   connectingTrips.length == 0
            //     ? "Connecting Trips"
            //     : "Connecting Trips (" + connectingTrips.length + ")"
            // }
            key="3"
          >
            <div>
              <ConnectingSearchForm
                cities={cities}
                onChange={this.handleChange}
                onTripSearch={this.onConnectingTripSearch}
                trips={filtertrips}
              />
            </div>
            <Table
              loading={connectingLoader}
              columns={connectingTripsColumns}
              dataSource={connectingTrips}
              pagination={false}
              scroll={{ x: "100%", y: "70vh" }}
            />
            {showConnectingStationList ? (
              <StationList
                tripId={tripId}
                tripStatus={status}
                onCancel={() =>
                  this.handleChange({ showConnectingStationList: false })
                }
              />
            ) : null}
            {showTrailingConnectingStationList ? (
              <StationList
                tripId={tripId}
                tripStatus={status}
                onCancel={() =>
                  this.handleChange({
                    showTrailingConnectingStationList: false
                  })
                }
              />
            ) : null}
            {showConnectingBookingList && (
              <>
                <BookingConnectingList
                  tripId={tripId}
                  onCancel={() =>
                    this.handleChange({ showConnectingBookingList: false })
                  }
                />
              </>
            )}

            {/* {searchTaskFlag ? (
              <Pagination
                total={paginationTotal}
                onChange={this.onSearchChange}
                hideOnSinglePage={true}
              />
            ) : null}
            {!searchTaskFlag ? (
              <Pagination
                defaultCurrent={1}
                total={paginationTotal}
                onChange={this.onChange}
              />
            ) : null} */}
          </TabPane>
          <TabPane
            tab={
              <Tooltip placement="rightTop" title="Tasks">
                <span>
                  {" "}
                  <img
                    style={{ width: "20px" }}
                    src={activeKey == 2 ? taskCol : task}
                    alt="menu"
                  />{" "}
                  {drawerWidth == "12%" ? "Tasks" : null}
                </span>
              </Tooltip>
            }
            key="2"
          >
            <TaskSearchForm
              tasks={tasks}
              pageNumber={current}
              changeLoadingOnRefresh={this.changeLoadingOnRefresh}
              onTypeFilterChange={this.handleTypeFilterChange}
              searchTaskFlag={searchTaskFlag}
              onTaskFilterChange={this.handleTaskStatusFilter}
              onPageChange={this.onChange}
              onTaskSearch={this.onTaskSearch}
              alltaskDownload={alltasks}
              isrefresh={editedTask}
            />
            <Table
              loading={taskloading}
              columns={taskColumns}
              dataSource={tasks}
              pagination={false}
              scroll={{ x: "140%", y: "70vh" }}
            />
            {/* {searchTaskFlag ? (
              <Pagination
                total={paginationTotal}
                onChange={this.onSearchChange}
                hideOnSinglePage={true}
              />
            ) : null}
            {!searchTaskFlag ? (
              <Pagination
                defaultCurrent={1}
                total={paginationTotal}
                onChange={this.onChange}
              />
            ) : null} */}
            {editTask ? (
              <EditTask
                onChange={obj => this.setState(obj)}
                editField={editField}
                taskStatusFilter={this.state.taskStatusFilter}
                task={taskToEdit}
                onTaskUpdate={this.onTaskUpdate}
                total={paginationTotal}
                pageNumber={current}
                closeParentModal={() => this.handleChange({ editTask: false })}
                onCancel={() => this.handleChange({ editTask: false })}
              />
            ) : null}
            {viewAttachments ? (
              <ViewAttachments
                onChange={obj => this.setState(obj)}
                editField={editField}
                task={taskToEdit}
                closeParentModal={() =>
                  this.handleChange({ viewAttachments: false })
                }
                onCancel={() => this.handleChange({ viewAttachments: false })}
              />
            ) : null}
            {viewTimelines ? (
              <ViewTimelines
                onChange={obj => this.setState(obj)}
                editField={editField}
                task={taskToEdit}
                typeFilter={this.state.typeFilter}
                closeParentModal={() =>
                  this.handleChange({ viewTimelines: false })
                }
                onCancel={() => this.handleChange({ viewTimelines: false })}
              />
            ) : null}
          </TabPane>
          <TabPane
            tab={
              <>
                <Tooltip placement="rightTop" title="GDS">
                  <span>
                    {" "}
                    <img
                      style={{ width: "20px" }}
                      src={activeKey == 9 ? gdsCol : gdsImg}
                      alt="menu"
                    />{" "}
                    {drawerWidth == "12%" ? "GDS" : null}
                  </span>
                </Tooltip>
              </>
            }
            key="9"
          >
            <Gds />
          </TabPane>
          <TabPane
            tab={
              <Tooltip placement="rightTop" title="Gds Lounge Booking">
                <span>
                  {" "}
                  <img
                    style={{ width: "17px" }}
                    src={activeKey == 12 ? gdsLoungeCol : gdsLounge}
                    alt="menu"
                  />{" "}
                  {drawerWidth == "12%" ? "Gds Lounge Booking" : null}
                </span>
              </Tooltip>
            }
            key="12"
          >
            <GdsLounegBookingView />
          </TabPane>
          <TabPane
            tab={
              <>
                <Tooltip placement="rightTop" title="Geofence">
                  <span>
                    {" "}
                    <img
                      style={{ width: "20px" }}
                      src={activeKey == 4 ? geofenceCol : geofence}
                      alt="menu"
                    />{" "}
                    {drawerWidth == "12%" ? "GeoFence" : null}
                  </span>
                </Tooltip>
              </>
            }
            key="4"
          >
            <GeoFenceView />
          </TabPane>
          <TabPane
            tab={
              <Tooltip placement="rightTop" title="OutBound Calls">
                <span>
                  {" "}
                  <img
                    style={{ width: "17px" }}
                    src={activeKey == 5 ? outBoundCol : outBoundImg}
                    alt="menu"
                  />{" "}
                  {drawerWidth == "12%" ? "OutBound Calls" : null}
                </span>
              </Tooltip>
            }
            key="5"
          >
            <CallLogsView />
          </TabPane>
          <TabPane
            tab={
              <Tooltip placement="rightTop" title="Gds OutBound Calls">
                <span>
                  {" "}
                  <img
                    style={{ width: "17px" }}
                    src={activeKey == 10 ? outBoundCol : outBoundImg}
                    alt="menu"
                  />{" "}
                  {drawerWidth == "12%" ? "Gds OutBound Calls" : null}
                </span>
              </Tooltip>
            }
            key="10"
          >
            <GdsCallLogsView />
          </TabPane>
          <TabPane
            tab={
              <Tooltip placement="rightTop" title="Crew Tickets">
                <span>
                  {" "}
                  <img
                    style={{ width: "17px" }}
                    src={activeKey == 8 ? crewTickCol : crewTick}
                    alt="menu"
                  />{" "}
                  {drawerWidth == "12%" ? "Crew Tickets" : null}
                </span>
              </Tooltip>
            }
            key="8"
          >
            <ComplainView />
          </TabPane>
          <TabPane
            tab={
              <Tooltip placement="rightTop" title="Camera Feeds">
                <span>
                  {" "}
                  <img
                    style={{ width: "17px" }}
                    src={activeKey == 7 ? camFeedCol : camFeed}
                    alt="menu"
                  />{" "}
                  {drawerWidth == "12%" ? "Camera Feeds" : null}
                </span>
              </Tooltip>
            }
            key="7"
          >
            <CameraFeeds />
          </TabPane>
          <TabPane
            tab={
              <Tooltip placement="rightTop" title="Live View">
                <span>
                  {" "}
                  <img
                    style={{ width: "17px" }}
                    src={activeKey == 6 ? liveViewCol : liveView}
                    alt="menu"
                  />{" "}
                  {drawerWidth == "12%" ? "Live View" : null}
                </span>
              </Tooltip>
            }
            key="6"
          >
            <LiveView />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
export default Home;
