/* eslint-disable no-undef */
import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Table,
  Tag,
  Card,
  Button,
  AutoComplete,
  Modal,
  Select,
  Input,
  DatePicker,
  message
} from "antd";

import Fetch from "../../utils/fetch";
import mapStyle from "../../config/mapStyle";
import { getHoursAndMinFromMillis } from "../../utils/common";
import { CSVLink } from "react-csv";
import notvisitedPoints from "../../images/location-pin.png";
import visitedPoints from "../../images/pink_pin.png";
import stationPoints from "../../images/station_icon-01.png";
import markedStationIcon from "../../images/marked_station_icon-01.png";
import markedReststop from "../../images/marked_reststop-01.png";
import reststopIcon from "../../images/reststop-01.png";
import VideoStream from "../../components/videoStream";

const { Option } = Select;
const { TextArea } = Input;

const columns = [
  {
    title: "Station Name",
    dataIndex: "stationName",
    key: "name"
  },
  {
    title: "City Name",
    dataIndex: "cityName",
    key: "cname"
  },

  {
    title: "Schedule Reaching Time",
    dataIndex: "scheduleReachingTime",
    key: "scheduleReachingTime",
    render: date => moment(date).format("LLL")
  },
  {
    title: "Station Reached Time",
    dataIndex: "stationReachedTime",
    key: "stationReachedTime",
    render: date =>
      date ? (
        moment(date).format("LLL")
      ) : (
        <Tag color="red">No Data found for station</Tag>
      )
  },
  {
    title: "Time Difference",
    dataIndex: "timeDifference",
    key: "timeDifference",
    render: time => {
      if (time) {
        const isNegative = time <= 0;
        return (
          <>
            {" "}
            <Tag color={isNegative ? "green" : "red"}>
              {`${getHoursAndMinFromMillis(Math.abs(time))} ${
                isNegative ? "Early" : "Delay"
              }`}
            </Tag>
          </>
        );
      }
    }
  },
  {
    title: "Wait Time",
    dataIndex: "waitTime",
    key: "waitTime",
    render: time => {
      if (time) {
        return <> {`${getHoursAndMinFromMillis(Math.abs(time))}`}</>;
      }
    }
  },
  {
    title: "Seats",
    dataIndex: "seats",
    key: "seats"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: ({ stationMarked, station, onClick, tripStatus }) => {
      if(tripStatus !== "COMPLETED"){
        return (
          <Button
            onClick={() => onClick(station, !stationMarked ? "MARK" : "UNMARK")}
            type="danger"
          >
            {!stationMarked ? "Mark Station" : "Unmark Station"}
          </Button>
        )
      }
      
    },
    width: "10%"
  }
];

const gridStyle = {
  width: "100%",
  height: "70vh"
};

class Report extends Component {
  state = {
    loading: true,
    busRoute: [],
    busTraveledRouteCoords: [],
    bookings: [],
    tripUtilization: {},
    csvDataSource: [],
    issue: [],
    subIssue: [],
    selectedSubIssueId: null,
    showVideo: false,
    showIssueModal: false
  };

  maxBreakDown = 4500;
  breakDown = 0;

  async componentDidMount() {
    try {
      const query = new URLSearchParams(this.props.location.search);
      const tripId = query.get("tripId");
      // const { tripId } = this.state;
      if (tripId) {
        const serviceName = await this.getServiceName();
        const {
          route = [],
          busTraveledRouteCoords = [],
          bus,
          tripDate,
          service,
          routePointsBetweenStationMap,
          tripStatus,
          stationsGeoFencingData
        } = await this.fetchRoute(tripId, serviceName);
        const bookings = await this.fetchAllBooking(tripId);
        const debug = query.get("debug") === "true";
        let utilization, tripOdo;

        if (tripStatus == "COMPLETED") {
          tripOdo = await this.fetchTrip(tripId);
          if (tripOdo) {
            let firstStationTime =
              route[0].reachedTime > 0
                ? route[0].reachedTime
                : route[0].reachingTime;

            let lastStationTime =
              route.slice(-1)[0].reachedTime > 0
                ? route.slice(-1)[0].reachedTime
                : route.slice(-1)[0].reachingTime;

            utilization = await this.fetchUtilization(
              bus.rc,
              firstStationTime,
              lastStationTime,
              tripOdo
            );
          }
        }
        const selectServiceName = `${service.name}|ACTIVE`;

        let id = serviceName
          ? serviceName.filter(pre => pre.name === selectServiceName)
          : null;

        this.setState(
          {
            loading: false,
            tripId,
            tripStatus,
            busRoute: route,
            busTraveledRouteCoords,
            bookings,
            bus,
            tripDate,
            service,
            routePointsBetweenStationMap,
            stationsGeoFencingData,
            tripUtilization: utilization,
            debug,
            selectServiceName: id[0].name,
            serviceName,
            serviceId: "",
            date: moment()
          },
          this.initMap
        );
      } else {
        const serviceName = await this.getServiceName();
        this.setState({
          loading: false,
          serviceName,
          serviceId: "",
          date: moment()
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  getServiceName = async () => {
    try {
      this.setState({ loader: true });
      const url = `/bus-tracker/serviceNameEs`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        this.setState({
          loader: false,
          serviceNames: response,
          viewModal: true
        });

        return response;
      } else {
        this.setState({ loader: false });

        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  handleSendClick = async () => {
    const query = new URLSearchParams(this.props.location.search);
    this.setState({ loading: true });
    let { selectServiceName, serviceName, date } = this.state;
    let serviceId;
    let id = serviceName
      ? serviceName.filter(service => service.name === selectServiceName)
      : null;
    if (id.length) {
      serviceId = id[0]._id;
    } else {
      message.error("Please select a valid service");
      this.setState({
        loading: false
      });
      return;
    }
    const url = `/bus-tracker/fetchTripViaService?date=${moment(date).format(
      "YYYY-MM-DD"
    )}&serviceId=${serviceId}`;

    const options = {
      method: "get"
    };
    const tripId = await Fetch(url, options);
    if (tripId) {
      const {
        route = [],
        busTraveledRouteCoords = [],
        bus,
        tripDate,
        service,
        routePointsBetweenStationMap,
        tripStatus,
        stationsGeoFencingData
      } = await this.fetchRoute(tripId, serviceName);
      const bookings = await this.fetchAllBooking(tripId);

      const debug = query.get("debug") === "true";
      let utilization, tripOdo;

      if (tripStatus == "COMPLETED") {
        tripOdo = await this.fetchTrip(tripId);
        if (tripOdo) {
          let firstStationTime =
            route[0].reachedTime > 0
              ? route[0].reachedTime
              : route[0].reachingTime;

          let lastStationTime =
            route.slice(-1)[0].reachedTime > 0
              ? route.slice(-1)[0].reachedTime
              : route.slice(-1)[0].reachingTime;

          utilization = await this.fetchUtilization(
            bus.rc,
            firstStationTime,
            lastStationTime,
            tripOdo
          );
        }
      }

      this.setState(
        {
          loading: false,
          tripId,
          tripStatus,
          busRoute: route,
          busTraveledRouteCoords,
          bookings,
          bus,
          tripDate,
          service,
          routePointsBetweenStationMap,
          stationsGeoFencingData,
          tripUtilization: utilization,
          debug
        },
        this.initMap
      );
    } else {
      // message.error("Please select a valid service");
      this.setState({
        loading: false
      });
    }
  };

  handleIssueInsertClick = async () => {
    let { issueIdSelected, selectedSubIssueId, remarks, tripId } = this.state;
    if (!issueIdSelected || !selectedSubIssueId) {
      message.error("please select issue and subIssue");
      return;
    }
    const url = `/bus-tracker/update/trip/${tripId}`;
    const options = {
      method: "post",
      data: {
        issueId: issueIdSelected,
        subIssueId: selectedSubIssueId,
        remark: remarks
      }
    };
    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      message.success("Remark Added");
      this.setState({
        showIssueModal: false,
        issueIdSelected: null,
        selectedSubIssueId: null,
        remarks: null,
        subIssue: []
      });
    } else {
      message.error("Remark Not Added");
    }
  };

  openIssueModal = async () => {
    const issueResponse = await this.fetchIssue();
    if (issueResponse) {
      this.setState({ showIssueModal: true, issue: issueResponse });
    } else {
      message.error("No subIssue found");
      this.setState({ showIssueModal: false });
    }
  };

  showVideo = () => {
    this.setState({ showVideo: true });
  };

  fetchIssue = async () => {
    try {
      const url = `/bus-tracker/tripIssue`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      return response;
    } catch (err) {
      message.error(err);
    }
  };

  subIssue = async issueId => {
    const subIssueUrl = `/bus-tracker/tripIssueSubIssue/${issueId}`;
    const options = {
      method: "get"
    };
    let subIssueResponse = await Fetch(subIssueUrl, options);
    if (Array.isArray(subIssueResponse)) {
      this.setState({ subIssue: subIssueResponse, issueIdSelected: issueId });
    } else {
      message.error("No subIssue found");
    }
  };
  fetchTrip = async tripId => {
    try {
      const url = `/bus-tracker/trip/?id=${tripId}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return null;
      }
    } catch (err) {
      this.setState({ loading: false });
      return Promise.reject(err);
    }
  };

  fetchUtilization = async (rc, startTime, endTime, trip) => {
    try {
      const url = `/bus-tracker/tripUtilization/?plate=${rc}&start_time=${startTime}&end_time=${endTime}`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        let formattedData = {
          fuelConsumed: response.fuelConsumed,
          distanceTraveled: response.distanceTraveled,
          startOdo: trip.startOdo ? trip.startOdo : null,
          endOdo: trip.endOdo ? trip.endOdo : null
        };
        return formattedData;
      }
    } catch (err) {
      this.setState({ loading: false });
      return Promise.reject(err);
    }
  };

  fetchRoute = async (tripId, serviceName) => {
    try {
      const cityIds = [];
      const url = `/bus-tracker/route/${tripId}?tripPoints=${true}`;
      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      if (response) {
        let {
          busRoute = [],
          completedTripPoints,
          tripDate,
          status,
          startTime,
          endTime,
          bus = {},
          service = {},
          routePointsBetweenStationMap,
          stationsGeoFencingData
        } = response;

        busRoute.map(stopStation => {
          cityIds.push(stopStation.station.cityId);
        });

        const url = `/bus-tracker/fetchCityName`;
        const options = {
          method: "post",
          data: {
            cityIds
          }
        };

        if (service.pairServiceId) {
          let getPairId = service.pairServiceId;
          let pairServiceName = serviceName.filter(
            service => service._id == getPairId
          )[0].name;
          service = { ...service, pairServiceName };
        }

        const cityNameResponse = await Fetch(url, options);

        const route = busRoute.map(stopStation => {
          return stopStation.locationType === "DEFAULT"
            ? {
                name: stopStation.station.name,
                coordinates: {
                  lat: stopStation.station.lat,
                  lng: stopStation.station.lng
                },
                cityName: cityNameResponse[stopStation.station.cityId]
                  ? cityNameResponse[stopStation.station.cityId][0].name
                  : null,
                stationType: stopStation.station.type,
                stationMarked: stopStation.stationMarked,
                reachingTime: stopStation.reachingTime,
                waitTime: stopStation.waitTime ? stopStation.waitTime : "",
                reachedTime: stopStation.stationMarked
                  ? stopStation.reachedDate
                  : 0,
                _id: stopStation.station._id
              }
            : {
                name: stopStation.station.name,
                coordinates: {
                  lat: stopStation.station.oppLat,
                  lng: stopStation.station.oppLng
                },
                cityName: cityNameResponse[stopStation.station.cityId]
                  ? cityNameResponse[stopStation.station.cityId][0].name
                  : null,
                stationType: stopStation.station.type,
                stationMarked: stopStation.stationMarked,
                reachingTime: stopStation.reachingTime,
                waitTime: stopStation.waitTime ? stopStation.waitTime : "",
                reachedTime: stopStation.stationMarked
                  ? stopStation.reachedDate
                  : 0,
                _id: stopStation.station._id
              };
        });

        const busTraveledRouteCoords = completedTripPoints;

        return {
          route,
          busTraveledRouteCoords,
          bus,
          tripStatus: status,
          tripStartTime: startTime,
          tripEndTime: endTime,
          service,
          tripDate,
          routePointsBetweenStationMap,
          stationsGeoFencingData
        };
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

  fetchAllBooking = async tripId => {
    try {
      const url = `/bus-tracker/tripbookings`;
      const options = {
        method: "post",
        data: {
          tripId
        }
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      }

      return [];
    } catch (err) {
      console.log(err);
    }
  };

  initMap = async () => {
    if (typeof google !== "undefined") {
      const {
        busRoute,
        busTraveledRouteCoords,
        stationsGeoFencingData,
        debug
      } = this.state;

      const csvDataSource = busRoute.map(station => ({
        stationName: station.name,
        cityName: station.cityName,
        scheduleReachingTime: moment(station.reachingTime).format("LLL"),
        stationReachedTime: station.reachedTime
          ? moment(station.reachedTime).format("LLL")
          : null,
        timeDifference: station.reachedTime
          ? getHoursAndMinFromMillis(
              Math.abs(station.reachedTime - station.reachingTime)
            )
          : null,
        waitTime: getHoursAndMinFromMillis(Math.abs(station.waitTime)),
        seats: this.getSeatsByStation(station._id)
      }));

      this.setState({ csvDataSource });

      // const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        preserveViewport: true,
        suppressMarkers: true
      });

      const startPoint = {
        lat: busRoute[0].coordinates.lat,
        lng: busRoute[0].coordinates.lng
      };

      const map = new google.maps.Map(document.getElementById("map2"), {
        zoom: 8,
        center: startPoint
      });

      // Style map
      const styledMapType = new google.maps.StyledMapType(mapStyle);
      map.mapTypes.set("styled_map", styledMapType);
      map.setMapTypeId("styled_map");

      this.setState({
        map,
        directionsRenderer
      });

      this.map = map;

      // Set bounds
      const bounds = new google.maps.LatLngBounds();

      for (let i = 0; i < busRoute.length; ++i) {
        const station = busRoute[i];

        const {
          stationType,
          stationMarked,
          reachedTime,
          reachingTime
        } = station;

        let icon = stationPoints;

        if (stationMarked && stationType !== "VANPOINT") {
          icon = markedStationIcon;
        }

        if (stationType === "RESTSTOP") {
          icon = reststopIcon;
        }

        if (stationMarked && stationType === "RESTSTOP") {
          icon = markedReststop;
        }

        const stationMarker = new google.maps.Marker({
          position: station.coordinates,
          title: station.name,
          map,
          icon
        });

        bounds.extend(station.coordinates);

        const stationTime = stationMarked
          ? `<p>Reached at: ${moment(reachedTime).format("LLL")}</p>`
          : "";

        const infoWindow = new google.maps.InfoWindow({
          content: `<p><b>${station.name},${station.cityName}</b></p>
          <p>Lat: ${station.coordinates.lat}, Lng: ${
            station.coordinates.lng
          }</p>
        <p>Schedule Time: ${moment(reachingTime).format("LLL")}</p>
        <p>Station Type: ${stationType}</p>
        ${stationTime}`
        });

        // eslint-disable-next-line no-loop-func
        stationMarker.addListener("click", () => {
          infoWindow.open(this.map, stationMarker);
        });
      }

      map.fitBounds(bounds);

      for (let i = 0; i < busTraveledRouteCoords.length; ++i) {
        const point = busTraveledRouteCoords[i];
        let traveledPointMarker;
        if (!point.distanceToUpcomingStation) {
          traveledPointMarker = new google.maps.Marker({
            position: { lat: point.lat, lng: point.lng },
            map: map,
            icon: notvisitedPoints
          });
        } else {
          traveledPointMarker = new google.maps.Marker({
            position: { lat: point.lat, lng: point.lng },
            map: map,
            icon: visitedPoints
          });
        }
        const infoWindow = new google.maps.InfoWindow({
          content: `<p>Lat: ${point.lat}, Lng: ${point.lng}</p>
        <p>Time: ${moment(point.latlngDate).format("LLL")}</p>
        <p>UpcomingStation: ${point.upComingStationName}</p>
        <p>UpcomingStationId: ${point.upcomingStationId}</p>
        <p>distanceToUpcomingStation: ${point.distanceToUpcomingStation}m</p>
        <p>airDistanceToUpcomingStation: ${
          point.upcomingStationAirDistance
        }m</p>`
        });

        traveledPointMarker.addListener("click", () => {
          infoWindow.open(this.map, traveledPointMarker);
        });
      }

      if (debug) {
        for (let i = 0; i < stationsGeoFencingData.length; ++i) {
          const geoFence = stationsGeoFencingData[i].geofence;

          switch (geoFence.type) {
            case "circle": {
              this.createCircle(geoFence);
              break;
            }

            case "rectangle": {
              this.createReactangle(geoFence.boundingBox);
              break;
            }

            case "polygon": {
              this.createPolygon(geoFence.coordinates);
              break;
            }

            default: {
              break;
            }
          }
        }
      }

      this.showRoute(busRoute);
    }
  };

  createCircle = circle => {
    const cityCircle = new google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: this.map,
      center: circle.center,
      radius: circle.radius
    });
  };

  createReactangle = boundingBox => {
    const { topRight, bottomLeft } = boundingBox;

    const north = topRight.lat;
    const south = bottomLeft.lat;
    const east = topRight.lng;
    const west = bottomLeft.lng;

    const rectangle = new google.maps.Rectangle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: this.map,
      bounds: {
        north,
        south,
        east,
        west
      }
    });

    return rectangle;
  };

  createPolygon = coords => {
    const polygon = new google.maps.Polygon({
      paths: coords,
      strokeColor: "#00FF00",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00FF00",
      fillOpacity: 0.35
    });

    polygon.setMap(this.map);
  };

  showRoute = (route, color) => {
    const max = 24;
    const pathRequestArr = [];

    for (let i = 0; i < route.length; i = i + 24) {
      pathRequestArr.push(route.slice(i, i + max + 1));
    }

    for (let i = 0; i < pathRequestArr.length; ++i) {
      const path = pathRequestArr[i];
      const start = 0;
      const end = path.length;

      let waypoints = path.slice(start, end);

      this.plotRoute(waypoints, color);
    }
  };

  plotRoute = async (waypoints, color = "blue") => {
    const url = `/bus-tracker/routeOSRM`;
    const options = {
      method: "post",
      data: {
        path: waypoints
      }
    };
    const response = await Fetch(url, options);
    const newPath = new google.maps.Polyline({
      path: response,
      preserveViewport: true,
      suppressMarkers: true,
      strokeColor: color
    });

    newPath.setMap(this.map);
  };

  getSeatsByStation = stationId => {
    const { bookings } = this.state;
    if (!(Array.isArray(bookings) && bookings.length < 1)) {
      return null;
    }
    let seatsLabel = [];
    const bookingFromStation = bookings.filter(
      booking =>
        booking.fromStationId === stationId && booking.status === "CONFIRMED"
    );
    let bookingTripChart = bookingFromStation.map(obj => obj.tripChart);

    if (bookingTripChart) {
      bookingTripChart = bookingTripChart.flat(1);
      if (seatsLabel.length === 0) {
        seatsLabel += bookingTripChart.map(seat => seat.seat.seatLabel);
      } else {
        seatsLabel += `,${bookingTripChart.map(seat => seat.seat.seatLabel)}`;
      }
    }

    return seatsLabel;
  };

  markStation = async (station, status) => {
    try {
      const { tripId } = this.state;
      const { busRoute } = this.state;
      const url = `/bus-tracker/markstation/`;
      const options = {
        method: "post",
        data: {
          stationId: station._id,
          lng: station.coordinates.lng,
          lat: station.coordinates.lat,
          stationName: station.name,
          tripId,
          status
        }
      };

      const response = await Fetch(url, options);

      if (response) {
        message.success(`Station ${status} success`);
        const updatedStationList = busRoute.map(s => {
          if (s._id === response[0].stationId) {
            if (status === "MARK") {
              return {
                ...s,
                status: { value: "REACHED", color: "green" },
                stationMarked: true,
                action: { ...s.action, stationMarked: true }
              };
            } else if (status === "UNMARK") {
              return {
                ...s,
                status: { value: "NA", color: "orange" },
                stationMarked: false,
                action: { ...s, stationMarked: false }
              };
            }
          }
          return s;
        });

        this.setState({
          busRoute: updatedStationList
        });

        return Promise.resolve(response);
      } else {
        // console.log(response);
        throw new Error("No response");
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      loading,
      busRoute,
      bus,
      service,
      tripDate,
      tripStatus,
      debug,
      tripUtilization,
      tripId,
      serviceNames,
      date,
      csvDataSource,
      issue,
      showIssueModal,
      subIssue,
      selectedSubIssueId,
      selectServiceName,
      showVideo
    } = this.state;
    return (
      <>
        <div style={{ margin: "2%" }}>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-3 text-left">
              <AutoComplete
                style={{
                  width: "100%"
                }}
                placeholder="select a service"
                dataSource={
                  serviceNames
                    ? serviceNames.map(service => service.name)
                    : null
                }
                value={selectServiceName ? selectServiceName : null}
                // onChange={value => {
                //   let id = serviceNames
                //     ? serviceNames.filter(service => service.name === value)
                //     : null;
                //   if (id.length) {
                //     this.setState({
                //       serviceId: id[0]._id
                //     });
                //   } else {
                //     this.setState({
                //       serviceId: null
                //     });
                //   }
                // }}
                onChange={value => this.setState({ selectServiceName: value })}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </div>
            <div className="col-3 text-left">
              <DatePicker
                style={{ width: "100%" }}
                value={date}
                onChange={date => this.setState({ date })}
              />
            </div>
            <div className="col-3 text-left">
              <Button onClick={this.handleSendClick} style={{ margin: "1% 0" }}>
                search
              </Button>
            </div>

            <div className="col-3 text-right">
              {tripId ? (
                <div>
                  <Button onClick={this.showVideo}>Show Video</Button>
                </div>
              ) : null}
            </div>

            <div>
              {showIssueModal ? (
                <Modal
                  centered
                  title="Add Trip Remark"
                  visible={true}
                  width={"800px"}
                  onCancel={() =>
                    this.setState({
                      showIssueModal: false,
                      issueIdSelected: null,
                      selectedSubIssueId: null,
                      remarks: null,
                      subIssue: []
                    })
                  }
                  onOk={this.handleIssueInsertClick}
                  okText="Save"
                >
                  <div className="row">
                    <div className="col-3">Select Issue Name</div>
                    <div className="col-6 text-left">
                      <Select
                        className="mb-1"
                        style={{ width: "400px" }}
                        defaultValue=" "
                        onChange={key => this.subIssue(key)}
                      >
                        {issue.map(issues => (
                          <Option value={issues._id} key={issues._id}>
                            {issues.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  {Array.isArray(subIssue) && subIssue.length > 0 ? (
                    <div className="row">
                      <div className="col-3">Select Sub Issue Name</div>
                      <div className="col-6 text-left">
                        <Select
                          className="mb-1"
                          style={{ width: "400px" }}
                          defaultValue=" "
                          onChange={key =>
                            this.setState({ selectedSubIssueId: key })
                          }
                        >
                          {subIssue.map(subis => (
                            <Option value={subis._id} key={subis._id}>
                              {subis.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  ) : null}
                  {selectedSubIssueId ? (
                    <>
                      <div className="row">
                        <div className="col-3">Remarks</div>
                        <div className="col-6 text-left">
                          <TextArea
                            className="mb-1"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            style={{ width: "400px" }}
                            onChange={e =>
                              this.setState({ remarks: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </Modal>
              ) : null}
            </div>
            {showVideo ? (
              <VideoStream
                tripId={tripId}
                cameraCode={bus.cameraCodes}
                serviceName={service.name}
                busRc={bus.rc}
                onCancel={() => this.setState({ showVideo: false })}
              />
            ) : null}
          </div>
        </div>
        {loading ? (
          <h1>Loading ....</h1>
        ) : tripId ? (
          <div>
            <div>
              <Card
                style={{
                  padding: 15
                }}
              >
                {tripStatus == "COMPLETED" ? (
                  <div className="row">
                    <div className="col-6 text-left">
                      <h2>Service Name: {service.name}</h2>
                      <h2>
                        Pair Service Name:{" "}
                        {service.pairServiceName
                          ? service.pairServiceName
                          : "NA"}
                      </h2>
                      <h2>Bus Number: {bus.rc}</h2>
                      <h2>
                        Start Odometer Reading:{" "}
                        {tripUtilization && tripUtilization.startOdo
                          ? tripUtilization.startOdo.toLocaleString("en-IN")
                          : null}
                      </h2>
                      <h2>
                        Fuel Consumed:
                        {tripUtilization && tripUtilization.fuelConsumed
                          ? tripUtilization.fuelConsumed.toFixed(2) + "L"
                          : null}{" "}
                      </h2>
                    </div>
                    <div className="col-6 text-left">
                      <h2>Trip Date: {moment(tripDate).format("MMM Do")}</h2>
                      <h2>
                        End Odometer Reading:
                        {tripUtilization && tripUtilization.endOdo
                          ? tripUtilization.endOdo.toLocaleString("en-IN")
                          : null}
                      </h2>
                      <h2>
                        Distance Covered:
                        {tripUtilization && tripUtilization.distanceTraveled
                          ? tripUtilization.distanceTraveled / 1000 + "KM"
                          : null}
                      </h2>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <h2>Service Name: {service.name}</h2>
                      <h2>
                        Pair Service Name:{" "}
                        {service.pairServiceName
                          ? service.pairServiceName
                          : "NA"}
                      </h2>
                      <h2>Bus Number: {bus.rc}</h2>
                      <h2>Trip Date: {moment(tripDate).format("MMM Do")}</h2>
                    </div>
                  </div>
                )}
              </Card>

              <Table
                columns={columns}
                dataSource={busRoute.map(station => ({
                  key: station._id,
                  stationName: station.name,
                  cityName: station.cityName,
                  waitTime: station.waitTime,
                  scheduleReachingTime: station.reachingTime,
                  action: {
                    stationMarked: station.stationMarked,
                    station: station,
                    onClick: this.markStation,
                    tripStatus: tripStatus
                  },
                  // seats: noOfSeats,
                  stationReachedTime: station.reachedTime
                    ? station.reachedTime
                    : null,
                  timeDifference: station.reachedTime
                    ? station.reachedTime - station.reachingTime
                    : null,
                  seats: this.getSeatsByStation(station._id)
                }))}
                pagination={false}
                scroll={{ y: "39vh" }}
                size="small"
              />
              <div
                style={{
                  margin: "1% 0",
                  textAlign: "center",
                  fontSize: "15px"
                }}
              >
                <CSVLink data={csvDataSource}>Download Report</CSVLink>

                {/* <CSVDownload data={csvData} target="_blank" /> */}
              </div>
            </div>
            <Card.Grid style={gridStyle}>
              <div className="row">
                <div className="col-6 text-left">
                  <h2>Route Taken</h2>
                </div>
                <div className="col-6 text-right">
                  <Link
                    to={
                      debug
                        ? `?tripId=${tripId}&debug=false`
                        : `?tripId=${tripId}&debug=true`
                    }
                    target="_parent"
                  >
                    <Button className="mb-1">
                      {debug ? "Hide Geofence" : "Show GeoFence"}
                    </Button>
                  </Link>
                </div>
              </div>
              <div style={{ width: "100%", height: "100%" }} id="map2" />
            </Card.Grid>
          </div>
        ) : null}
      </>
    );
  }
}

export default Report;
