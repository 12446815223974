import React from "react";
import styles from "./otg.module.scss";

const OTG = props => {
  return (
    <div className={styles.bannerwrapper}>
      <div className={styles.Header}>
        <div>
          <span>On Time Guarantee</span>
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/OTG/on-time-1.svg"
            alt="timer"
          />
        </div>
      </div>
      <div className={styles.isApplicable}>
        <img
          src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/OTG/accept1.svg"
          alt="confirm"
        />
        <span>Applicable on this trip</span>
      </div>
      <div className={styles.info}>
        <div>
          <span>
            Claim your 50% refund coupon from my bookings page in the app.
          </span>
        </div>
      </div>
      <img
        className={styles.man}
        src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/OTG/relax.svg"
      />
    </div>
  );
};

export default OTG;
