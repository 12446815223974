import React from "react";
import loaderImage from "../../images/zingbus-loading.gif";
import "./loader.scss";

const Loader = () => (
  <div className="preloader">
    <img src={loaderImage} alt="Loading" />
  </div>
);

export default Loader;
