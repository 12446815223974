import React, { Component } from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import {
  Button,
  DatePicker,
  Select,
  message,
  AutoComplete,
  Switch
} from "antd";
import Fetch from "../../utils/fetch";
import InitiateTask from "../InitiateTask";
import InitiateTaskGroup from "../InitiateTaskGroup";

const { Option } = Select;

class TaskSearchForm extends Component {
  state = {
    showResult: false,
    Downloadtasks: [],
    DownloadAlltasks: [],
    result: [],
    cities: [],
    fromdate: moment().subtract(10, "days"),
    timeFilter: "generationDate",
    todate: moment(),
    typeFilter: "Task",
    statusFilter: "OPEN",
    typeTaskFilter: "INTERNAL",
    updatedField: "",
    selectKey: "----Select type----",
    taskSearchValue: "",
    serviceNames: [],
    busNumberSearch: [],
    taskLoader: false,
    downloadTasksBtn: false,
    bookingLoader: false
  };

  inputRef = React.createRef();
  csvLink = React.createRef();

  async componentDidMount() {
    try {
      const { tasks, pageNumber, searchTaskFlag, isrefresh } = this.props;
      const serviceNameResponse = await this.serviceName();
      const BusNumberResp = await this.busNumber();
      const createTaskGroupResp = await this.createTaskGroup();

      this.setState({
        Downloadtasks: tasks,
        pageNumber: pageNumber,
        searchTaskFlag: searchTaskFlag,
        isrefresh: isrefresh,
        taskGroup: createTaskGroupResp,
        serviceNames: serviceNameResponse,
        busNumberSearch: BusNumberResp
      });
    } catch (err) {
      console.error(err);
    }
  }

  createTaskGroup = async () => {
    try {
      const url = `/bus-tracker/createTaskGroup`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response.data;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  busNumber = async () => {
    try {
      const url = `/bus-tracker/busNumber`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  serviceName = async () => {
    try {
      const url = `/bus-tracker/serviceNameEs`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  fetchTaskModule = async showUserTask => {
    try {
      let { statusFilter, typeTaskFilter } = this.state;
      this.setState({ taskLoader: false, downloadTasksBtn: false });
      let url = `/bus-tracker/taskSearch?`;

      if (statusFilter) {
        url += `statusFilter=${statusFilter}`;
      }
      if (typeTaskFilter) {
        url += `&typeTaskFilter=${typeTaskFilter}`;
      }
      if (showUserTask) {
        url += `&showUserTaskOnly=${showUserTask}`;
      }

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (Array.isArray(response)) {
        this.setState({ taskLoader: false, downloadTasksBtn: false }, () =>
          this.props.onTaskSearch(response)
        );
      } else {
        message.error("No task Found");
        this.setState({ taskLoader: false });
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  fetchAudit = async downloadtaskBoolean => {
    try {
      this.setState({ taskLoader: true, downloadTasksBtn: false });
      let {
        searchTaskFlag,
        fromdate = null,
        todate = null,
        timeFilter = null,
        statusFilter = null,
        typeFilter,
        taskSearchValue = null,
        busNumber = null
      } = this.state;
      let url = `/bus-tracker/searchAudit?`;
      let todaysDate = new Date().getTime();

      if (downloadtaskBoolean === true) {
        url = `/bus-tracker/searchAuditDownload?`;
      } else {
        url = `/bus-tracker/searchAudit?`;
      }

      if (
        taskSearchValue &&
        fromdate &&
        todate &&
        timeFilter &&
        statusFilter &&
        typeFilter
      ) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&taskSearchValue=${taskSearchValue}&statusFilter=${statusFilter}&typeFilter=${typeFilter}`;
      } else if (
        busNumber &&
        fromdate &&
        todate &&
        timeFilter &&
        statusFilter &&
        typeFilter
      ) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&busNumber=${busNumber}&statusFilter=${statusFilter}&typeFilter=${typeFilter}`;
      } else if (
        taskSearchValue &&
        fromdate &&
        todate &&
        timeFilter &&
        typeFilter
      ) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&taskSearchValue=${taskSearchValue}&typeFilter=${typeFilter}`;
      } else if (
        taskSearchValue &&
        fromdate &&
        todate &&
        timeFilter &&
        statusFilter
      ) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&taskSearchValue=${taskSearchValue}&statusFilter=${statusFilter}`;
      } else if (
        fromdate &&
        todate &&
        timeFilter &&
        statusFilter &&
        typeFilter
      ) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&statusFilter=${statusFilter}&typeFilter=${typeFilter}`;
      } else if (busNumber && statusFilter && typeFilter) {
        url += `busNumber=${busNumber}&statusFilter=${statusFilter}&typeFilter=${typeFilter}`;
      } else if (
        busNumber &&
        fromdate &&
        todate &&
        timeFilter &&
        statusFilter
      ) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&busNumber=${busNumber}&statusFilter=${statusFilter}`;
      } else if (busNumber && fromdate && todate && timeFilter && typeFilter) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&busNumber=${busNumber}&typeFilter=${typeFilter}`;
      } else if (fromdate && todate && timeFilter && busNumber) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&busNumber=${busNumber}`;
      } else if (taskSearchValue && statusFilter && typeFilter) {
        url += `taskSearchValue=${taskSearchValue}&statusFilter=${statusFilter}&typeFilter=${typeFilter}`;
      } else if (fromdate && todate && timeFilter && taskSearchValue) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&taskSearchValue=${taskSearchValue}`;
      } else if (fromdate && todate && timeFilter && typeFilter) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&typeFilter=${typeFilter}`;
      } else if (taskSearchValue && fromdate && todate && timeFilter) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&taskSearchValue=${taskSearchValue}`;
      } else if (taskSearchValue && typeFilter) {
        url += `taskSearchValue=${taskSearchValue}&typeFilter=${typeFilter}`;
      } else if (taskSearchValue && statusFilter) {
        url += `taskSearchValue=${taskSearchValue}&statusFilter=${statusFilter}`;
      } else if (busNumber && fromdate && todate && timeFilter) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&busNumber=${busNumber}`;
      } else if (busNumber && typeFilter) {
        url += `busNumber=${busNumber}&typeFilter=${typeFilter}`;
      } else if (busNumber && statusFilter) {
        url += `busNumber=${busNumber}&statusFilter=${statusFilter}`;
      } else if (fromdate && todate && timeFilter && statusFilter) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}&statusFilter=${statusFilter}`;
      } else if (typeFilter && statusFilter) {
        url += `typeFilter=${typeFilter}&statusFilter=${statusFilter}`;
      } else if (fromdate && todate && timeFilter) {
        url += `fromdate=${moment(fromdate).format(
          "YYYY-MM-DD"
        )}&todate=${moment(todate).format(
          "YYYY-MM-DD"
        )}&timeFilter=${timeFilter}`;
      } else if (taskSearchValue) {
        url += `taskSearchValue=${taskSearchValue}`;
      } else if (statusFilter) {
        url += `statusFilter=${statusFilter}`;
      } else if (typeFilter) {
        url += `typeFilter=${typeFilter}`;
      } else if (busNumber) {
        url += `busNumber=${busNumber}`;
      }

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      let { data, tasksCount } = response;
      if (statusFilter === "LAPSED") {
        data = data.filter(
          obj =>
            obj.etaDate + 24 * 60 * 60 * 1000 - 1 < todaysDate &&
            obj.status !== "CLOSED"
        );
        tasksCount = data.length;
      }

      if (statusFilter === "CLOSED") {
        tasksCount = data.length;
      }

      if (statusFilter === "OPEN") {
        data = data.filter(
          obj => obj.etaDate + 24 * 60 * 60 * 1000 - 1 > todaysDate
        );
        tasksCount = data.length;
      }
      searchTaskFlag = true;
      if (downloadtaskBoolean === true) {
        this.setState({ taskLoader: false, downloadTasksBtn: true });
        return response;
      }
      let responseCount = 1;
      if (response) {
        this.setState({ taskLoader: false, downloadTasksBtn: true }, () =>
          this.props.onTaskSearch(
            data,
            tasksCount,
            searchTaskFlag,
            responseCount
          )
        );
      } else {
        this.setState({ taskLoader: false });
      }
    } catch (err) {
      this.setState({ taskLoader: false });
    }
  };

  handleStatusFilterChange = Filter => {
    this.setState({ statusFilter: Filter });
    this.props.onTaskFilterChange(Filter);
  };

  downloadTask = async () => {
    let downloadtaskBoolean = true;
    let data = await this.fetchAudit(downloadtaskBoolean);
    this.setState({ DownloadAlltasks: data });
    if (this.state.DownloadAlltasks.length) {
      this.csvLink.current.link.click();
    }
  };

  searchedTask = async () => {
    const { typeFilter } = this.state;
    const { changeLoadingOnRefresh } = this.props;
    changeLoadingOnRefresh(true);
    if (typeFilter == "Task") {
      await this.fetchTaskModule();
    } else {
      let downloadtaskBoolean = false;
      await this.fetchAudit(downloadtaskBoolean);
    }
    changeLoadingOnRefresh(false);
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleStatusTypeChange = Filter => {
    this.setState({ typeFilter: Filter });
    this.props.onTypeFilterChange(Filter);
  };

  sendTask = async Bodyobj => {
    try {
      const url = `/bus-tracker/sendTask`;

      const options = {
        method: "post",
        data: { obj: Bodyobj }
      };

      const response = await Fetch(url, options);
      if (response) {
        message.success("Task Initiated");
      }
    } catch (err) {
      message.error(err);
    }
  };

  sendGroupTask = async (Bodyobj, taskMasterId) => {
    try {
      const url = `/bus-tracker/sendGroupTask`;
      const options = {
        method: "post",
        data: { obj: Bodyobj, id: taskMasterId }
      };

      const response = await Fetch(url, options);
      if (response) {
        message.success("Task Will be created in some Time");
      }
    } catch (err) {
      message.error(err);
    }
  };

  ShowUserOnlyTask = async checked => {
    const showUserTask = checked;
    const { changeLoadingOnRefresh } = this.props;
    changeLoadingOnRefresh(true);
    await this.fetchTaskModule(showUserTask);
    changeLoadingOnRefresh(false);
  };

  render() {
    let {
      selectKey,
      taskLoader,
      downloadTasksBtn,
      statusFilter,
      timeFilter,
      DownloadAlltasks,
      typeFilter,
      typeTaskFilter,
      taskGroup,
      fromdate,
      todate,
      serviceNames,
      busNumberSearch
    } = this.state;
    return (
      <>
        <div className="taskSearchForm">
          {/* input tag is for copying customer link */}
          <input
            ref={this.inputRef}
            readOnly={true}
            style={{
              position: "absolute",
              left: "-9999px"
            }}
          />

          <div className="taskSearch">
            <Select
              defaultValue="Audit"
              style={{ width: "fit-content", minWidth: "100px" }}
              value={typeFilter}
              onChange={value => this.handleStatusTypeChange(value)}
            >
              <Option value="Audit">Audit</Option>
              <Option value="Task">Task</Option>
              {/* <Option value="ALL">All</Option> */}
            </Select>

            <Button
              loading={taskLoader}
              onClick={this.searchedTask}
              style={{ width: 100 }}
              type="primary"
            >
              Search
            </Button>
          </div>
          {typeFilter === "Audit" ? (
            <>
              <div className="dateSearch">
                <DatePicker
                  style={{ width: 150 }}
                  value={fromdate}
                  onChange={fromdate => this.setState({ fromdate })}
                />
                <DatePicker
                  style={{ width: 150, margin: "0 0.2rem" }}
                  value={todate}
                  onChange={todate => this.setState({ todate })}
                />
                {todate ? (
                  <Select
                    style={{ width: "150px" }}
                    value={timeFilter}
                    onChange={value => this.setState({ timeFilter: value })}
                  >
                    <Option value="TAT">TAT</Option>
                    <Option value="generationDate">Generation Date</Option>
                  </Select>
                ) : null}
              </div>

              <div className="userType">
                <Select
                  // defaultValue={0}
                  defaultValue="----Select Type----"
                  style={{
                    width: "fit-content",
                    minWidth: "100px",
                    margin: "0 0.2rem",
                    whiteSpace: "pre-line"
                  }}
                  onChange={value => this.setState({ selectKey: value })}
                >
                  <Option value="1">Service</Option>
                  <Option value="2">Bus</Option>
                  {/* <Option value="3">Marshal</Option> */}
                  {/* <Option value="4">Pilot</Option> */}
                </Select>
                {selectKey === "1" ? (
                  <AutoComplete
                    style={{
                      width: 200
                    }}
                    dataSource={serviceNames.map(service => service.name)}
                    onChange={value => {
                      let id = serviceNames.filter(
                        service => service.name === value
                      );
                      if (id.length) {
                        this.setState({
                          taskSearchValue: id[0]._id,
                          busNumber: null
                        });
                      } else {
                        this.setState({
                          taskSearchValue: null,
                          busNumber: null
                        });
                      }
                    }}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                ) : null}
                {selectKey === "2" ? (
                  <AutoComplete
                    style={{
                      width: 200
                    }}
                    onChange={value => {
                      let id = busNumberSearch.filter(bus => bus.rc === value);
                      if (id.length) {
                        this.setState({
                          taskSearchValue: null,
                          busNumber: id[0]._id
                        });
                      } else {
                        this.setState({
                          taskSearchValue: null,
                          busNumber: null
                        });
                      }
                    }}
                    dataSource={busNumberSearch.map(bus => bus.rc)}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                ) : null}
              </div>

              <div className="taskSearch">
                <Select
                  defaultValue="ALL"
                  style={{ width: "fit-content", minWidth: "100px" }}
                  value={statusFilter}
                  onChange={value => this.handleStatusFilterChange(value)}
                >
                  <Option value="OPEN">Open</Option>
                  <Option value="CLOSED">Closed</Option>
                  <Option value="LAPSED">Lapsed</Option>
                  <Option value="ALL">All</Option>
                </Select>

                <Button
                  loading={taskLoader}
                  onClick={this.searchedTask}
                  style={{ width: 100 }}
                  type="primary"
                >
                  Search
                </Button>
                {downloadTasksBtn ? (
                  <>
                    <Button
                      loading={taskLoader}
                      onClick={this.downloadTask}
                      style={{ width: 100 }}
                      type="primary"
                    >
                      Download
                    </Button>
                    <CSVLink
                      data={DownloadAlltasks.map(obj => ({
                        Task: obj.job.name,
                        Type: "Task",
                        Service_Name: obj.service ? obj.service.name : null,
                        Bus_Number: obj.bus ? obj.bus.rc : null,
                        Assigned_To:
                          obj.job.visibleToUserWithPrivileges +
                          "-" +
                          (obj.service ? obj.service.serviceHub : obj.bus.bus),
                        Created_By: obj.createdByUser.name,
                        generationDate: moment(obj.generationDate).format(
                          "LLL"
                        ),
                        TAT: moment(obj.etaDate).format("LLL"),
                        Closed_By: obj.responseByUser
                          ? obj.responseByUser.name
                          : null,
                        Status: obj.status,
                        Comments: obj.comments
                      }))}
                      ref={this.csvLink}
                      filename="data.csv"
                      className="hidden"
                      target="_blank"
                    />
                  </>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className="taskSearch">
                <Select
                  defaultValue="ALL"
                  style={{ width: "fit-content", minWidth: "200px" }}
                  value={statusFilter}
                  onChange={value => this.handleStatusFilterChange(value)}
                >
                  <Option value="OPEN">Open</Option>
                  <Option value="CLOSED">Closed</Option>
                  <Option value="REJECTED">Rejected</Option>
                  <Option value="INPROGRESS">InProgress</Option>
                  <Option value="ALL">All</Option>
                </Select>

                <Button
                  loading={taskLoader}
                  onClick={this.searchedTask}
                  style={{ width: 100 }}
                  type="primary"
                >
                  Search
                </Button>
                <Button
                  type="primary"
                  style={{ width: 100 }}
                  onClick={this.searchedTask}
                >
                  Refresh
                </Button>
              </div>
              <div className="taskSearch">
                <Select
                  defaultValue="INTERNAL"
                  style={{ width: "fit-content", minWidth: "200px" }}
                  value={typeTaskFilter}
                  onChange={value => this.setState({ typeTaskFilter: value })}
                >
                  <Option value="INTERNAL">Internal</Option>
                  <Option value="CASHCOLLECTION">Cash Collection</Option>
                  <Option value="SAFETYKIT">Safety Kit</Option>
                  <Option value="BOOKINGRATING">Booking Rating</Option>
                </Select>
                <Button
                  loading={taskLoader}
                  onClick={this.searchedTask}
                  style={{ width: 100 }}
                  type="primary"
                >
                  Search
                </Button>
                <Switch
                  onChange={this.ShowUserOnlyTask}
                  checkedChildren="Assigned"
                  unCheckedChildren="Assigned"
                  defaultChecked={false}
                />
              </div>
            </>
          )}

          <div className="createNewTask">
            <InitiateTask isloading={taskLoader} onClick={this.sendTask} />
          </div>
          <div className="createNewTask">
            <InitiateTaskGroup
              isloading={taskLoader}
              taskGroup={taskGroup}
              onClick={this.sendGroupTask}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TaskSearchForm;
