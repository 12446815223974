import axios from "axios";
import { message } from "antd";
import { baseURL } from "../config";

const Fetch = async (path, options = {}, authorization = false) => {
  try {
    const token = localStorage.getItem("idToken");
    if (authorization && !token) {
      throw new Error("No JWT token found");
    }
    const headers = {
      ...options.headers
    };

    if (token) {
      headers.authorization = `Bearer ${token}`;
    }

    const response = await axios({
      method: "get",
      url: `${baseURL}${path}`,
      ...options,
      headers
    });

    const { data, statusCode } = response.data;
    if (statusCode === "ok") {
      return data;
    } else {
      if (data == "Sorry! You're not allowed" && statusCode == "Not Allowed") {
        message.error(JSON.stringify(data));
        return data;
      } else if (data !== null && statusCode !== "Booking Not Found") {
        message.error(JSON.stringify(data));
      }
      return null;
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      const { data, statusCode } = err.response.data;
      switch (statusCode) {
        case "error":
          message.error(JSON.stringify(data));
          return null;

        case "Not Allowed":
          message.error(data || "Not Allowed");
          return null;

        case "Not Found":
          message.error("Not Found");
          return null;

        case "OSRM Error":
          return null;
        default:
          message.error("Some thing went wrong");
          return null;
      }
    } else {
      message.error("Some thing went wrong");
      return null;
    }
  }
};

export default Fetch;
