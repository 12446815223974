import React, { Component } from "react";
import {
  Card,
  Select,
  DatePicker,
  Checkbox,
  Switch,
  Button,
  message
} from "antd";
import moment from "moment";
import Fetch from "../../utils/fetch";
import { prodServer } from "../../config";

const { Option } = Select;
const { RangePicker } = DatePicker;

class TrackingTest extends Component {
  state = {
    loading: true,
    services: [],
    tripDetails: {},
    currentConfig: {},
    selectedServiceId: null,
    useMockTrackingData: true,
    selectedDate: null,
    selectedStartTime: null,
    selectedEndTime: null,
    clearOldData: false,
    upcomingStationNewLogic: false
  };

  async componentDidMount() {
    try {
      const { tripId } = this.props.match.params;

      const stateObj = {};

      const [allServices, tripDetails] = await Promise.all([
        this.getAllServiceFromProd(),
        this.fetchBusRoute(tripId)
      ]);

      stateObj.services = allServices;
      stateObj.tripDetails = tripDetails;
      stateObj.loading = false;
      stateObj.currentConfig = this.getConfigFromTrip(tripDetails, allServices);

      this.setState(stateObj);
    } catch (err) {
      console.log(err);
    }
  }

  fetchBusRoute = async tripId => {
    try {
      const url = `/bus-tracker/route/${tripId}`;
      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      }

      return {};
    } catch (err) {
      console.log(err);

      return Promise.reject(err);
    }
  };

  getAllServiceFromProd = async () => {
    const url = `/test/framework/service`;
    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      return response;
    } else {
      return [];
    }
  };

  getConfigFromTrip = (trip, allServices) => {
    const dateFormat = "MMMM Do YYYY, h:mm:ss a";
    const serviceMap = {};

    for (let i = 0; i < allServices.length; ++i) {
      const service = allServices[i];

      serviceMap[service._id] = service;
    }

    const {
      trackingDataStartTime,
      trackingDataEndTime,
      useMockTrackingData,
      tripToMock,
      mockTime,
      serviceToMock,
      tripDateToMock
    } = trip;

    if (!useMockTrackingData) {
      return {};
    }

    const currentConfig = {
      mockTime: moment(mockTime).format(dateFormat),
      tripDateToMock: moment(tripDateToMock).format(dateFormat),
      tripToMock
    };

    if (serviceToMock) {
      currentConfig.serviceName = serviceMap[serviceToMock].name;
    }

    if (trackingDataStartTime) {
      currentConfig["trackingDataStartTime"] = moment(
        trackingDataStartTime
      ).format(dateFormat);
    }

    if (trackingDataEndTime) {
      currentConfig["trackingDataEndTime"] = moment(trackingDataEndTime).format(
        dateFormat
      );
    }

    return currentConfig;
  };

  onSwitchChange = value => {
    this.setState({ useMockTrackingData: value });
  };

  onUpdateUpcomingStationLogic = value => {
    this.setState({ upcomingStationNewLogic: value });
  };

  onServiceSelect = value => {
    this.setState({ selectedServiceId: value });
  };

  onDateSelect = date => {
    this.setState({ selectedDate: date.startOf("day").valueOf() });
  };

  onCheckboxClicked = e => {
    this.setState({ clearOldData: e.target.checked });
  };

  onTimeRangeSelect = value => {
    const [startTime, endTime] = value;
    const startTimeStamp = startTime.valueOf();
    const endTimeStamp = endTime.valueOf();

    this.setState({
      selectedStartTime: startTimeStamp,
      selectedEndTime: endTimeStamp
    });
  };

  onSearch = val => {
    console.log("search:", val);
  };

  disabledDate = current => {
    return current && current > moment().endOf("day");
  };

  updateTrip = (tripId, requestData) => {
    const url = `/bus-tracker/update/trip/${tripId}`;
    const options = {
      method: "post",
      data: requestData
    };

    return Fetch(url, options);
  };

  updateTripForTestingFramework = requestData => {
    const url = `/test/framework/trip/update`;
    const options = {
      method: "post",
      data: requestData
    };

    return Fetch(url, options);
  };

  onSave = async () => {
    const { tripId } = this.props.match.params;

    const {
      selectedServiceId,
      useMockTrackingData,
      selectedDate,
      selectedStartTime,
      selectedEndTime,
      clearOldData
    } = this.state;

    const requestData = {
      tripId,
      serviceIdProd: selectedServiceId,
      useMockTrackingData,
      date: selectedDate,
      trackingDataStartTime: selectedStartTime,
      trackingDataEndTime: selectedEndTime,
      clearOldData
    };

    await this.updateTripForTestingFramework(requestData);

    message.success("Testing config saved successfully");
  };

  onUpcomingStationLogicUpdate = async () => {
    const { tripId } = this.props.match.params;

    const { upcomingStationNewLogic } = this.state;

    const requestBody = {
      useUpcomingStationNewLogic: upcomingStationNewLogic
    };

    await this.updateTrip(tripId, requestBody);

    message.success("Trip Updated successfully");
  };

  render() {
    const { services, loading, tripDetails, currentConfig } = this.state;

    const currentConfigKeys = Object.keys(currentConfig);

    if (loading) {
      return <h1>Loading ....</h1>;
    }

    return (
      <div style={{ padding: "10px 20px" }}>
        <Card title="Trip Details" style={{ marginBottom: "10px" }}>
          <p>Service Name: {tripDetails.service.name}</p>
          <p>
            Trip Date: {moment(tripDetails.tripDate).format("MMMM Do YYYY")}
          </p>
        </Card>
        <Card title="UpcomingStation Logic" style={{ marginBottom: "10px" }}>
          <div>
            Use new upcomingStation logic &nbsp;
            <Switch onChange={this.onUpdateUpcomingStationLogic} />
          </div>
          <div>
            <Button onClick={this.onUpcomingStationLogicUpdate}>Save</Button>
          </div>
        </Card>

        {!prodServer ? (
          <>
            <Card
              title="Trip Current testing configration"
              style={{ marginBottom: "10px" }}
            >
              {currentConfigKeys.length
                ? currentConfigKeys.map(keyName => (
                    <p
                      key={keyName}
                    >{`${keyName}: ${currentConfig[keyName]}`}</p>
                  ))
                : "No current config found"}
            </Card>
            <Card
              title="Select test tracking configration"
              style={{ marginBottom: "10px" }}
            >
              <div>
                <div>
                  <div>
                    Mock tracking data for this trip &nbsp;
                    <Switch defaultChecked onChange={this.onSwitchChange} />
                  </div>
                  <div>
                    <Select
                      showSearch
                      style={{ width: 400 }}
                      placeholder="Select a service"
                      optionFilterProp="children"
                      onChange={this.onServiceSelect}
                      onSearch={this.onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {services.map(s => (
                        <Option value={s._id} key={s._id}>
                          {s.name}
                        </Option>
                      ))}
                    </Select>
                    <DatePicker
                      disabledDate={this.disabledDate}
                      onChange={this.onDateSelect}
                    />
                    <RangePicker
                      showTime={{ format: "HH:mm" }}
                      format="YYYY-MM-DD HH:mm"
                      onChange={this.onTimeRangeSelect}
                    />
                  </div>
                </div>
                <div>
                  <Checkbox onChange={this.onCheckboxClicked}>
                    Clear old tracking data from DB for this trip
                  </Checkbox>
                </div>
                <div>
                  <Button onClick={this.onSave}>Save</Button>
                </div>
              </div>
            </Card>
          </>
        ) : null}
      </div>
    );
  }
}

export default TrackingTest;
