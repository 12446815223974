import React from "react";
import cx from "classnames";
import "./spinner.css";

const Spinner = props => {
  const { type } = props;

  let spinnerClass = "";

  if (type === "secondary") {
    spinnerClass = "spinnerSecondary";
  }

  return <div className={cx("spinner", spinnerClass)}></div>;
};

export default Spinner;
