import React, { Component } from "react";
import Fetch from "../../utils/fetch";
class cancellationPolicy extends Component {
  state = {
    loading: true,
    _id: this.props.match.params.bookingId
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const bookingData = await this.fetchDate();
      let htmlData = this.createCancellationPolicyLanguage(
        bookingData.cancellationPolicy,
        ""
      );
      this.setState({
        htmlData,
        booking: bookingData,
        loading: false
      });
    } catch (err) {
      console.log(err);
    }
  }

  createCancellationPolicyLanguage = (
    cancellationPolicy,
    zingPrimeCancellationPolicy = []
  ) => {
    let themeColor = "#D6C1F8";
    // let str = `<style>.table{border:1px solid #b2b2b2;border-radius:20px;overflow:hidden;margin-bottom:20px;}.table>div{display:flex;border-bottom:1px solid #b2b2b2;font-weight:500;font-size:15px;line-height:21px;text-align:center;width:100%;align-items:center}.table>div:first-child{background:${themeColor};font-weight:600;font-size:17px}.table>div:last-child{border-bottom:0}.table>div>p{margin:0;padding:15px}.table>div>p:first-child{width:75%;border-right:1px solid #b2b2b2}.table>div>p:nth-child(2){width:25%;}.table>div>b{text-align:center;width:100%;padding:15px}</style>`;
    let str = `<style>.table{border:1px solid #b2b2b2;border-radius:20px;overflow:hidden;margin-bottom:20px;}.table>div{display:flex;border-bottom:1px solid #b2b2b2;font-weight:500;font-size:15px;line-height:21px;text-align:left;width:100%;align-items:center}.table>div:first-child{background:${themeColor};font-weight:600;font-size:17px}.table>div:last-child{border-bottom:0}.table>div>p{margin:0;padding:15px}.table>div>p:first-child{width:75%;border-right:1px solid #b2b2b2}.table>div>p:nth-child(2){width:25%;word-wrap:break-word;}.table>div>b{text-align:left;width:100%;padding:15px}</style>`;

    str += `<div class="table"><div><p><b>Cancellation Time</b> (Valid from trip start time)</p><p><b>Refund Percentage</b></p></div>`;

    for (let i = 0; i < cancellationPolicy.length; ++i) {
      const { conditions, rule } = cancellationPolicy[i];

      if (
        conditions.maxCancellationAllowed &&
        conditions.maxCancellationAllowed > 0
      ) {
        const noOfDays = conditions.thresholdTime / (24 * 60 * 60 * 1000);

        str += `<div><b>${conditions.maxCancellationAllowed} last-minute free cancellation within the last ${noOfDays} rolling days, if eligible:-</b></div>`;
        for (let i = 0; i < rule.length; ++i) {
          const {
            fromCancellationTime,
            toCancellationTime,
            cancellationPercentage
          } = rule[i];

          let isMinuteRulePresent = false;

          let fromCancellationTimeInHours =
            fromCancellationTime / (60 * 60 * 1000);

          if (fromCancellationTimeInHours > 1) {
            fromCancellationTimeInHours = `${fromCancellationTimeInHours} Hours`;
          } else {
            if (fromCancellationTimeInHours > 0) {
              isMinuteRulePresent = true;
            }

            fromCancellationTimeInHours = fromCancellationTime / (60 * 1000);
            fromCancellationTimeInHours = `${fromCancellationTimeInHours} Minutes`;
          }

          let toCancellationTimeInHours = toCancellationTime / (60 * 60 * 1000);

          if (
            (toCancellationTimeInHours > 1 ||
              toCancellationTimeInHours === 0) &&
            !isMinuteRulePresent
          ) {
            toCancellationTimeInHours = `${toCancellationTimeInHours} Hours`;
          } else {
            toCancellationTimeInHours = toCancellationTime / (60 * 1000);
            toCancellationTimeInHours = `${toCancellationTimeInHours} Minutes`;
          }

          if (fromCancellationTime === -1) {
            str += `<div><p>More than ${toCancellationTimeInHours}</p><p>${100 -
              cancellationPercentage}%</p></div>`;
          } else {
            str += `<div><p>${fromCancellationTimeInHours} to ${toCancellationTimeInHours}</p><p>${100 -
              cancellationPercentage}%</p></div>`;
          }
        }

        str += `<div><b>After ${conditions.maxCancellationAllowed} last-minute free cancellations the following policy will be applicable:-</b></div>`;
        continue;
      }

      if (
        conditions.maxCancellationAllowed &&
        conditions.maxCancellationAllowed === -1 &&
        conditions.thresholdTime === -1
      ) {
        for (let i = 0; i < rule.length; ++i) {
          const {
            fromCancellationTime,
            toCancellationTime,
            cancellationPercentage
          } = rule[i];

          let isMinuteRulePresent = false;
          let fromCancellationTimeInHours =
            fromCancellationTime / (60 * 60 * 1000);

          let toCancellationTimeInHours = toCancellationTime / (60 * 60 * 1000);

          if (fromCancellationTimeInHours > 1) {
            fromCancellationTimeInHours = `${fromCancellationTimeInHours} Hours`;
          } else {
            if (fromCancellationTimeInHours > 0) {
              isMinuteRulePresent = true;
            }

            fromCancellationTimeInHours = fromCancellationTime / (60 * 1000);
            fromCancellationTimeInHours = `${fromCancellationTimeInHours} Minutes`;
          }

          if (
            (toCancellationTimeInHours > 1 ||
              toCancellationTimeInHours === 0) &&
            !isMinuteRulePresent
          ) {
            toCancellationTimeInHours = `${toCancellationTimeInHours} Hours`;
          } else {
            toCancellationTimeInHours = toCancellationTime / (60 * 1000);
            toCancellationTimeInHours = `${toCancellationTimeInHours} Minutes`;
          }

          if (fromCancellationTime === -1) {
            str += `<div><p>More than ${toCancellationTimeInHours}</p><p>${100 -
              cancellationPercentage}%</p></div>`;
          } else {
            str += `<div><p>${fromCancellationTimeInHours} to ${toCancellationTimeInHours}</p><p>${100 -
              cancellationPercentage}%</p></div>`;
          }
        }
      }
    }

    let zingPrimeFreeCancellationCount = 0;
    let zingPrimeCancellationDays;

    for (let i = 0; i < zingPrimeCancellationPolicy.length; ++i) {
      const { conditions } = zingPrimeCancellationPolicy[i];

      if (
        conditions.maxCancellationAllowed &&
        conditions.maxCancellationAllowed > 0
      ) {
        zingPrimeFreeCancellationCount = conditions.maxCancellationAllowed;
        zingPrimeCancellationDays =
          conditions.thresholdTime / (24 * 60 * 60 * 1000);
      }
    }

    str += `</div><ul style="
    display: flex;
    flex-direction: column;
  ">`;

    if (zingPrimeFreeCancellationCount > 0) {
      str += `<li>Zingprime users will be provided with ${zingPrimeFreeCancellationCount} last-minute free cancellations every ${zingPrimeCancellationDays} rolling days. The booking amount will be refunded 100% instantly post cancellation</li>`;
    }

    str += `<li>If the cancellation is done after the journey time then no refund will be processed.</li><li>The said policy is applicable on tickets booked by zingbus.com (web or mobile) and the zingbus app only.</li><li>For tickets booked from any other channel or OTA partner, the cancellation policy as mentioned by the channel/OTA partner will be applicable.</li></ul>`;

    return str;
  };

  fetchDate = async () => {
    const url = `/bus-tracker/booking?id=${this.state._id}`;
    const getbooking = await Fetch(url);
    let response = getbooking[0];
    return response;
  };
  render() {
    const { htmlData, loading } = this.state;
    if (loading) {
      return "Loading";
    } else {
      return (
        <div style={{ padding: "2%" }}>
          <h1>Cancellation policy</h1>
          <div dangerouslySetInnerHTML={{ __html: htmlData }}></div>;
          <h1>Luggage Policy</h1>
          <ul>
            <li>
              Maximum baggage allowed per passenger is 15 Kgs, Excess baggage
              will be charged as per the zingbus rules. zingbus also reserves
              the right to disallow any excess baggage carried by passengers.
            </li>
          </ul>
        </div>
      );
    }
  }
}

export default cancellationPolicy;
