import React, { Component } from "react";
import { Button, Input, Select, Modal, Table, message } from "antd";
import Fetch from "../../utils/fetch";
import jwt from "jsonwebtoken";
// let couponProcessed = !!booking.isCreditCouponSent;

const { Option } = Select;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "2%"
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "2%"
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "2%"
  },
  {
    title: "Coupon Type",
    dataIndex: "couponType",
    key: "couponType",
    width: "2%"
  },
  {
    title: "Booking Amount",
    dataIndex: "bookingAmount",
    key: "bookingAmount",
    width: "2%"
  },
  {
    title: "Coupon Max",
    dataIndex: "couponMax",
    key: "couponMax",
    width: "2%"
  }
];

class SendCoupon extends Component {
  state = {
    showCouponOption: false,
    coupons: [],
    couponTable: []
  };

  fetchCoupons = async () => {
    let url = `/booking/coupons/`;

    const options = {
      method: "get"
    };
    const couponArr = await Fetch(url, options);
    return couponArr;
  };

  setCoupon = key => {
    const { coupons } = this.state;
    const selectedCoupon = coupons.filter(coupon => coupon._id == key);
    this.updateTable(selectedCoupon);
  };

  updateTable = selectedCoupon => {
    let { selectedBookings, isSearchForm, isgdsBooking } = this.props;
    const couponTable = [];
    for (let i = 0; i < selectedBookings.length; ++i) {
      const booking =
        isSearchForm || isgdsBooking
          ? selectedBookings[i].booking
          : selectedBookings[i];

      let paidAmount = booking.paidAmount
        ? booking.paidAmount
        : booking.booking && booking.booking.paidAmount
        ? booking.booking.paidAmount
        : null;
      let isCreditCouponSent = !booking.isCreditCouponSent;
      let bookingObj;
      if (isgdsBooking) {
        bookingObj = {
          key: booking._id,
          name: booking.name,
          mobileNo: booking.mobileNo,
          pnr: booking.gdsPNR,
          couponType: selectedCoupon[0].couponType,
          bookingAmount: booking.bookingAmount,
          couponMax: selectedCoupon[0].couponValue
        };
      } else if (isSearchForm) {
        bookingObj = {
          key: booking._id,
          name: booking.name ? booking.name : booking.sender_name,
          mobileNo: booking.mobileNo,
          pnr: booking.bookingCode
            ? booking.bookingCode
            : booking.marketplaceBookingCode,
          couponType: selectedCoupon[0].couponType,
          bookingAmount: paidAmount,
          couponMax: selectedCoupon[0].couponValue
        };
      } else if (booking.status === "CONFIRMED" && isCreditCouponSent) {
        bookingObj = {
          key: booking._id,
          name: booking.name ? booking.name : booking.sender_name,
          mobileNo: booking.sender_name
            ? booking.sender_mobileNo.value
            : booking.mobileNo.value,
          pnr: booking.pnr,
          couponType: selectedCoupon[0].couponType,
          bookingAmount: paidAmount,
          couponMax: selectedCoupon[0].couponValue
        };
      }
      couponTable.push(bookingObj);
    }
    this.setState({
      loading: false,
      couponTable,
      selectedCoupon: selectedCoupon[0]
    });
  };

  toggleCouponOption = async () => {
    let { selectedCount, selectedBookings, isSearchForm } = this.props;

    if (!selectedBookings) {
      return message.error("Please select booking");
    }
    if (!selectedCount) {
      selectedCount = selectedBookings.length;
    }

    if (isSearchForm) {
      const isCreditCouponSent = selectedBookings[0].isCreditCouponSent;
      const creditCouponCode = selectedBookings[0].creditCouponCode;
      if (isCreditCouponSent) {
        return message.error(`Coupon Code ${creditCouponCode} is already sent`);
      }
    }

    const { showCouponOption } = this.state;

    if (!showCouponOption && !selectedCount) {
      return message.error("Please select booking or station");
    }

    const coupons = await this.fetchCoupons();

    this.setState({
      loading: false,
      showCouponOption: true,
      couponTable: [],
      coupons
    });
  };

  handleSendClick = () => {
    const { selectedCoupon } = this.state;
    const { onClick } = this.props;

    onClick(selectedCoupon._id);

    this.setState({
      showCouponOption: false,
      selectedCoupon: {},
      coupons: []
    });
  };

  render() {
    const {
      showCouponOption,
      coupons,
      selectedCoupon,
      couponTable
    } = this.state;

    return (
      <div>
        {showCouponOption ? (
          <Modal
            centered
            width={1500}
            title="Send Coupon"
            footer={null}
            visible={true}
            onCancel={() =>
              this.setState({
                showCouponOption: false,
                selectedCoupon: {},
                selectedCouponBookings: [],
                coupons: []
              })
            }
          >
            <div className="row">
              <div className="col-3">Select Category</div>
              <div className="col-6 text-left">
                <Select
                  className="mb-1"
                  style={{ width: "100%" }}
                  defaultValue=" "
                  onChange={key => this.setCoupon(key)}
                >
                  {coupons.map(coupon => (
                    <Option value={coupon._id} key={coupon._id}>
                      {coupon.description}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <br />
            {selectedCoupon && selectedCoupon.couponPrefix ? (
              <div className="row">
                <div className="col-3">Coupon Type</div>
                <div className="col-6 text-left">
                  <p
                    style={{
                      borderColor: "d9d9d9",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      paddingLeft: "11px"
                    }}
                  >
                    {selectedCoupon.couponPrefix}
                  </p>
                </div>
              </div>
            ) : null}

            {couponTable && couponTable.length > 0 ? (
              <>
                <Table
                  columns={columns}
                  dataSource={couponTable}
                  pagination={false}
                />
                <Button
                  type="primary"
                  onClick={this.handleSendClick}
                  style={{ margin: "1% 0" }}
                >
                  Confirm Coupon
                </Button>
              </>
            ) : null}
          </Modal>
        ) : (
          <Button type="primary" onClick={this.toggleCouponOption}>
            Send Coupon
          </Button>
        )}
      </div>
    );
  }
}

export default SendCoupon;
