import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import CarouselAudio from "../CarouselAudio";

import { Image } from "antd";

const YoutubeSlide = ({ url, isSelected }) => (
  <ReactPlayer
    style={{ padding: "40px" }}
    width="100%"
    url={url}
    playing={isSelected}
    controls
  />
);
// const AudioSlide = ({ url, isSelected }) => (
//   <ReactPlayer
//     style={{ padding: "20px" }}
//     width="100%"
//     url={url}
//     playing={isSelected}
//     controls
//     muted
//   />
// );

class CarouselTab extends Component {
  state = {
    activeKey: this.props.activeKey,
    data: this.props.data
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {
    if (this.state.activeKey) {
      this.setState({ activeKey: "" });
    }
  }
  // componentDidUpdate(prevProps) {
  //    if (prevProps.activeKey !== this.props.activeKey) {
  //    this.carousel(this.props.activeKey, this.props.data);
  // }
  // }

  static getDerivedStateFromProps(props, state) {
    if (props.activeKey !== state.activeKey) {
      return {
        activeKey: props.activeKey
      };
    }
    if (props.activeKey === state.activeKey) {
      return {
        data: props.data
      };
    }
    return null;
  }

  // carousel = (activeKey, data) => {
  //   this.setState({ activeKey: activeKey, data: data });
  // };

  customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

  render() {
    const { data, activeKey } = this.state;
    const { isTask } = this.props;

    if (activeKey === "1") {
      return (
        <Carousel>
          {data.map(v =>
            v.type === "video" || v.type === "audio" || v.type === "image" ? (
              <div
                style={{
                  height: "350px",
                  backgroundColor: "white",
                  padding: "0px",
                  fontSize: "14px",
                  lineHeight: "1.5715"
                }}
              >
                <Image
                  width={150}
                  src={"https://d370edwo3kkakj.cloudfront.net/" + v.path}
                />
              </div>
            ) : null
          )}
        </Carousel>
      );
    } else if (activeKey === "2") {
      return (
        <Carousel renderItem={this.customRenderItem}>
          <CarouselAudio
            activeKey={this.state.activeKey}
            data={data}
            isTask={isTask}
          />
        </Carousel>
      );
    } else if (activeKey === "3") {
      return (
        <Carousel renderItem={this.customRenderItem}>
          {data.map(v =>
            v.type === "video" ? (
              <YoutubeSlide
                url={"https://d370edwo3kkakj.cloudfront.net/" + v.path}
                key="youtube-1"
              />
            ) : (
              <div>
                <span>Nothing to show</span>
              </div>
            )
          )}
          {/* <video
            controls
            autoPlay
            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
          /> */}
        </Carousel>
      );
    }
  }
}

export default CarouselTab;
