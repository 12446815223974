import React, { Component } from "react";
import cx from "classnames";
import { message } from "antd";
import { customerIssue } from "../../../../config";
import { postIssue } from "../../../../network";
import styles from "./emergency.module.scss";
import alertIcon from "../../../../images/alert.svg";
import doctorIcon from "../../../../images/doctor.svg";
import phoneBookIcon from "../../../../images/phone-book.svg";
import { googleLayerAnalytics } from "../../../../utils/googleDataLayer";
import moment from "moment";
class Emergency extends Component {
  state = {
    showSOSMenu: false
  };

  sosMenuRef = React.createRef();
  shareLinkRef = React.createRef();

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  handleOutsideClick = e => {
    const { showSOSMenu } = this.state;
    const clickedInsideSOSMenu = this.sosMenuRef.current.contains(e.target);

    if (!clickedInsideSOSMenu && showSOSMenu) {
      this.setState({ showSOSMenu: false });
    }
  };

  openSOSMenu = e => {
    const { booking } = this.props;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    googleLayerAnalytics("initiate_sos", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      booking_source: booking.demandSource
    });
    this.setState({ showSOSMenu: true });
  };

  raiseIssue = async issueId => {
    try {
      const { booking } = this.props;

      const body = {
        issueId,
        bookingId: booking.id
      };

      await postIssue(body);
      googleLayerAnalytics("submit_sos", {
        mobile_no: booking.mobileNo,
        pnr: booking.bookingCode,
        from_city: booking.boardingPoint.city,
        to_city: booking.dropPoint.city,
        from_station: booking.boardingPoint.address,
        to_station: booking.dropPoint.address,
        booking_source: booking.demandSource,
        trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
        sos_option:
          issueId == "5f13166a8c8d3c0ecd51dd03"
            ? "marshalBehaviour"
            : issueId == "5f13166a8c8d3c0ecd51dd04"
            ? "healthEmergency"
            : "contactPolice"
      });

      message.success(
        "You request is recieved successfully, Our team will look into it shortly"
      );
    } catch (err) {
      message.error("Something went wrong while raising issue");
    }
  };

  copyTrackingLink = () => {
    this.shareLinkRef.current.select();

    document.execCommand("copy");

    message.success("Link Copied");
  };
  handleShare = (title, text, url) => {
    const shareData = {
      title: title,
      text: text,
      url: url
    };
    try {
      navigator.share(shareData);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { showSOSMenu } = this.state;
    const { booking } = this.props;
    const string =
      "whatsapp://send?text= You can Track zingbus from " + booking.trackingUrl;

    return (
      <div className={styles.emergencyContainer}>
        <div className={styles.sos} onClick={this.openSOSMenu}>
          SOS
        </div>

        <div className="blue">
          <a href={string} data-action="share/whatsapp/share">
            <button
              className="transparent_btn"
              onClick={() => {
                googleLayerAnalytics("share_trip", {
                  mobile_no: booking.mobileNo,
                  pnr: booking.bookingCode,
                  from_city: booking.boardingPoint.city,
                  to_city: booking.dropPoint.city,
                  from_station: booking.boardingPoint.address,
                  to_station: booking.dropPoint.address,
                  booking_source: booking.demandSource,
                  trip_date: moment(booking.boardingPoint.time).format(
                    "YYYY-MM-DD"
                  )
                });
              }}
            >
              Share Trip
            </button>
          </a>
        </div>
        <div
          ref={this.sosMenuRef}
          className={cx(
            styles.sosOptionsContainer,
            !showSOSMenu ? styles.hideSOSOptionsContainer : ""
          )}
        >
          <div className={styles.header}>
            <span>Select Emergency</span>
          </div>
          <div
            className={styles.options}
            onClick={() => this.raiseIssue(customerIssue.marshalBehaviour)}
          >
            <img src={alertIcon} alt="" />
            <div className="blue">Report Marshal Behaviour</div>
          </div>
          <a href="tel:102">
            <div
              className={styles.options}
              onClick={() => this.raiseIssue(customerIssue.healthEmergency)}
            >
              <img src={doctorIcon} alt="" />
              <div className="blue">Health Emergency</div>
            </div>
          </a>
          <a href="tel:100">
            <div
              className={styles.options}
              onClick={() => this.raiseIssue(customerIssue.contactPolice)}
            >
              <img src={phoneBookIcon} alt="" />
              <div className="blue">Contact Police</div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default Emergency;
