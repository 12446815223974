import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Tag, message } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import Fetch from "../../utils/fetch";

class PNRView extends Component {
  state = {
    loading: true,
    booking: {}
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const { pnr } = this.props.match.params;
      const bookingDetails = await this.getBookingDetails(pnr);

      this.setState({
        loading: false,
        booking: bookingDetails[0]
      });
    } catch (err) {
      console.log(err);
    }
  }

  getBookingDetails = pnr => {
    const url = `/bus-tracker/booking?pnr=${pnr}`;

    const options = {
      method: "get"
    };

    return Fetch(url, options);
  };

  copyCustomerTrackingLink = customerTrackingLink => {
    this.inputRef.current.value = customerTrackingLink;
    this.inputRef.current.select();

    document.execCommand("copy");
    message.success("Customer Link copied to clipboard");
  };

  render() {
    const { loading, booking } = this.state;

    if (loading) {
      return "Loading";
    }

    return (
      <div>
        <input
          ref={this.inputRef}
          readOnly={true}
          style={{ position: "absolute", left: "-9999px" }}
        />
        <table className="pnrTable">
          <tr>
            <th>PNR</th>
            <td>{booking.bookingCode}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{booking.name}</td>
          </tr>
          <tr>
            <th>Mobile Number</th>
            <td>{booking.mobileNo}</td>
          </tr>
          <tr>
            <th>From Station</th>
            <td>{booking.fromStation.name}</td>
          </tr>
          <tr>
            <th>To Station</th>
            <td>{booking.toStation.name}</td>
          </tr>
          <tr>
            <th>Actions</th>
            <td>
              <Link to={`/track/${booking.tripId}`} target="_blank">
                <Button>Track Bus</Button>
              </Link>
              <br />
              <a href={booking.trackingUrl} rel="noreferrer" target="_blank">
                <Button>Customer Link</Button>
              </a>
              <br />
              <Button
                onClick={() =>
                  this.copyCustomerTrackingLink(booking.trackingUrl)
                }
              >
                Share Customer Link
              </Button>
            </td>
          </tr>
          <tr>
            <th>Call Records:</th>
            <td>
              {booking.callDetails.length ? (
                booking.callDetails.map((call, index) => (
                  <>
                    <div key={index} className="mb-1">
                      {call.recording ? (
                        <>
                          <Link to={call.recording} target="_blank">
                            <PlayCircleOutlined />
                          </Link>
                          <br></br>
                          {call.date}
                        </>
                      ) : (
                        <div>
                          <Tag>{call.status}</Tag>
                          <br></br>
                          <Tag>{call.date}</Tag>
                        </div>
                      )}
                    </div>
                    <br />
                  </>
                ))
              ) : (
                <Tag color="red">No call records</Tag>
              )}
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default PNRView;
