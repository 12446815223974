import React, { Component } from "react";
import { Modal, Button, message, Input } from "antd";
import Fetch from "../../utils/fetch";

class StatusComment extends Component {
  state = {
    loading: true,
    station: [],
    tripId: "",
    selectedStationId: ""
  };

  async componentDidMount() {
    try {
      const { tripId } = this.props;
      this.setState({ tripId: tripId });
      {
        this.setState({ loading: false });
      }
    } catch (err) {
      message.error(err);
    }
  }
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  statusComment = async () => {
    try {
      let { tripId, commentInput, timeInput } = this.state;
      timeInput = new Date().getTime() + timeInput * 60 * 1000;
      let data = { tripId, commentInput, timeInput };
      const path = "/bus-tracker/statusComment";
      const options = {
        method: "post",
        data
      };
      const response = await Fetch(path, options);

      if (response) {
        console.log("Updated");
        window.location.reload(false);
      }
    } catch (err) {
      console.log(err);
      message.error("Wrong");
    }
  };

  render() {
    const { loading, timeInput, commentInput } = this.state;
    const name = `Add Comment`;

    return (
      <Modal
        title={name}
        visible={true}
        onCancel={this.props.onCancel}
        // onCancel={() =>
        //   onChange({ editBooking: false, bookingToEdit: {}, editField: "" })
        // }
        footer={null}
      >
        {loading ? (
          "...Loading"
        ) : (
          <div>
            <div className="row">
              <div className="col-3">Comment</div>
              <div className="col-7">
                <Input
                  type={"text"}
                  value={commentInput}
                  style={{ width: 200 }}
                  onChange={e =>
                    this.handleChange("commentInput", e.target.value)
                  }
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-3">Relay Time</div>
              <div className="col-5">
                <Input
                  type={"number"}
                  value={timeInput}
                  style={{ width: 200 }}
                  onChange={e => this.handleChange("timeInput", e.target.value)}
                />
                <span />
                mins
              </div>
            </div>
            <footer>
              <Button type="primary" onClick={this.statusComment}>
                Save
              </Button>
            </footer>
          </div>
        )}
      </Modal>
    );
  }
}

export default StatusComment;
