import React, { Component } from "react";
import { Modal, Button, message, Input, Upload } from "antd";
import Fetch from "../../utils/fetch";
import jwt from "jsonwebtoken";
import { UploadOutlined } from "@ant-design/icons";

const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text"
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
};

class RaiseIssue extends Component {
  state = {
    loading: true,
    station: [],
    selectedStationId: "",
    input: ""
  };

  async componentDidMount() {
    try {
      const { editField } = this.props;

      if (editField === "fromStation" || editField === "toStation") {
        const stationMap = {};

        const tripRoute = await this.fetchTripRoute();
        // for (let i = 0; i < tripRoute.length; ++i) {
        //   const route = tripRoute[i];
        //   stationMap[route[editField]._id] = route[editField];
        // }
        // this.setState({ stations: Object.values(stationMap), loading: false });
        this.setState({ stations: tripRoute, loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      message.error(err);
    }
  }

  fetchTripRoute = async () => {
    try {
      const { booking } = this.props;

      let url = `/bus-tracker/booking/stations/?tripId=${booking.tripId}&fromCityId=${booking.fromCityId}&toCityId=${booking.toCityId}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      }
    } catch (err) {
      message.error(err);
    }
  };

  updateStation = async () => {
    try {
      const { booking, editField } = this.props;
      const { selectedStationId } = this.state;
      let cityType;

      if (editField === "fromStation") {
        cityType = "fromCity";
      } else if (editField === "toStation") {
        cityType = "toCity";
      }

      const url = `/bus-tracker/booking/stations`;

      const options = {
        method: "patch",
        data: {
          bookingCode: booking.bookingCode,
          cityType,
          pickedStationId: selectedStationId
        }
      };

      const response = await Fetch(url, options);

      if (response) {
        message.success("Station Updated");
        this.afterUpdate();
      }
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  isInputValid = () => {
    const { editField } = this.props;
    const { input } = this.state;

    switch (editField) {
      case "mobileNo": {
        if (input.length !== 10) {
          message.error("Mobile number is not valid");

          return false;
        }

        return true;
      }

      default: {
        return true;
      }
    }
  };

  updateBooking = async () => {
    try {
      const { editField, booking } = this.props;
      const { input } = this.state;

      if (this.isInputValid()) {
        const url = `/bus-tracker/booking`;

        const options = {
          method: "patch",
          data: {
            bookingId: booking._id,
            [editField]: input
          }
        };

        if (editField === "remark") {
          const token = localStorage.getItem("idToken");
          const decodedToken = jwt.decode(token);
          let remark = booking.remark ? `${booking.remark} | ` : "";

          options.data[editField] = `${remark}${decodedToken.name}: ${input}`;
        }

        const response = await Fetch(url, options);

        if (response) {
          console.log(response);
          message.success("Booking Updated");
          this.afterUpdate();
        }
      }
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  afterUpdate = () => {
    const { onChange, closeParentModal } = this.props;

    onChange({
      editBooking: false
    });

    closeParentModal();
  };

  render() {
    const { loading, issueInput, subIssueInput, remarkInput } = this.state;
    const name = `Raise Issue`;

    return (
      <Modal
        title={name}
        visible={true}
        onCancel={this.props.onCancel}
        footer={null}
      >
        {loading ? (
          "...Loading"
        ) : (
          <div>
            <div className="row">
              <div className="col-3">Issue Type</div>
              <div className="col-7">
                <Input
                  type={"text"}
                  value={issueInput}
                  style={{ width: 200 }}
                  onChange={e =>
                    this.handleChange("issueInput", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3">Sub Issue Type</div>
              <div className="col-7">
                <Input
                  type={"text"}
                  value={subIssueInput}
                  style={{ width: 200 }}
                  onChange={e =>
                    this.handleChange("subIssueInput", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3">Remark</div>
              <div className="col-7">
                <Input
                  type={"text"}
                  value={remarkInput}
                  style={{ width: 200 }}
                  onChange={e =>
                    this.handleChange("remarkInput", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3">Attachment</div>
              <div className="col-7">
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </div>
            </div>
            <footer>
              <Button type="primary">Save</Button>
            </footer>
          </div>
        )}
      </Modal>
    );
  }
}

export default RaiseIssue;
