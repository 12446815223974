import React, { Component } from "react";
import {
  Select,
  Tag,
  Table,
  Button,
  Modal,
  message,
  Input,
  Checkbox,
  AutoComplete,
  DatePicker
} from "antd";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";

import Loader from "../loader";
import Fetch from "../../utils/fetch";
import { getSeatNumberFromTripChart } from "../../utils/common";

import moment from "moment";
import {
  EditOutlined,
  BorderInnerOutlined,
  PlayCircleOutlined,
  CopyOutlined,
  MoreOutlined
} from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
const wordBreakStyle = { wordBreak: "breakAll" };
function msToTime(duration) {
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + " hrs :" + minutes + " min :" + seconds + " sec";
}

const serviceNameFetched = [];
const raisedByNameFetched = [];

const columns = [
  {
    title: "Complain Category",
    dataIndex: "complainCategory",
    key: "complainCategory",
    width: "10%",
    render: ({
      subIssueName,
      _id,
      name,
      selectCategory,
      bookingId,
      serviceId,
      mobileNo,
      bookingCode,
      serviceName,
      recordingUrl,
      tripId
    }) => {
      if (subIssueName == "IVR Call Related") {
        return (
          <div>
            <div>{name}</div>
            <BorderInnerOutlined
              onClick={() =>
                selectCategory(
                  bookingId,
                  serviceId,
                  mobileNo,
                  bookingCode,
                  _id,
                  recordingUrl,
                  serviceName,
                  tripId
                )
              }
            />
          </div>
        );
      } else {
        return <div>{name}</div>;
      }
    }
  },
  {
    title: "Mobile No",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "10%"
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "10%",

    render: ({ systemAlertCount, bookingCode, showRelatedComplains }) => {
      return (
        <>
          <div style={{ color: systemAlertCount > 3 ? "red" : "black" }}>
            {bookingCode}
            {bookingCode ? (
              <MoreOutlined
                onClick={() =>
                  showRelatedComplains({
                    bookingCode
                  })
                }
              />
            ) : null}
          </div>
        </>
      );
    }
  },
  {
    title: "Service Name",
    dataIndex: "serviceName",
    key: "serviceName",

    width: "10%"
  },
  {
    title: "Complain Comments",
    dataIndex: "complainComments",
    key: "complainComments",
    width: "10%"
  },
  {
    title: "Call Logs",
    dataIndex: "callLogs",
    key: "callLogs",
    width: "10%",
    render: ({
      showCallLogs,
      recordingUrl,
      bookingCode,
      subIssueName,
      _id
    }) => {
      if (subIssueName == "IVR Call Related" || recordingUrl) {
        return (
          <div>
            <Button onClick={() => window.open(recordingUrl)}>
              Show Recording
            </Button>
          </div>
        );
      } else {
        return (
          <div>
            <Button
              disabled={bookingCode ? false : true}
              onClick={() => showCallLogs({ bookingCode, _id })}
            >
              Show Call Logs
            </Button>
          </div>
        );
      }
    }
  },
  {
    title: "Raised By",
    dataIndex: "raisedBy",
    key: "raisedBy",
    width: "10%",
    filterSearch: true,
    filters: raisedByNameFetched,
    onFilter: (value, record) => record.raisedBy.name.includes(value),
    render: ({ name, date }) => {
      return (
        <div>
          {name}
          <br />
          {date}
        </div>
      );
    }
  },
  {
    title: "Click to call",
    dataIndex: "clickTocall",
    key: "clickTocall",
    width: "15%",
    render: ({
      tripId,
      bookingCode,
      marshallConnect,
      _id,
      userConnect,
      showBookings,
      upcomingStation,
      affectedPnrs,
      mobileNo,
      directCall
    }) => {
      return (
        <div>
          <Button onClick={() => marshallConnect({ tripId, _id })}>
            Connect with Marshal
          </Button>

          {affectedPnrs ? (
            <Button
              onClick={() =>
                showBookings({ tripId, affectedPnrs, _id, upcomingStation })
              }
            >
              show all Bookings
            </Button>
          ) : mobileNo ? (
            <Button onClick={() => directCall(mobileNo)}>Call Customer</Button>
          ) : (
            <Button
              disabled={bookingCode ? false : true}
              onClick={() => userConnect({ bookingCode, _id })}
            >
              Connect with Passenger
            </Button>
          )}
        </div>
      );
    }
  },
  {
    title: "Outbound remark",
    dataIndex: "outboundRemarks",
    key: "outboundRemarks",
    width: "10%",
    render: ({
      value,
      _id,
      raisedBy,
      allOutBoundsRemarks,
      outboundRemarksUpdate
    }) => (
      <>
        <EditOutlined
          onClick={() =>
            outboundRemarksUpdate({ allOutBoundsRemarks, value, _id, raisedBy })
          }
        />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
    width: "10%",
    render: ({ department, raisedBy, _id, updateDepartment }) => {
      return (
        <Select
          value={department}
          style={{ width: 120, margin: "0 0.2rem", whiteSpace: "pre-line" }}
          onChange={e =>
            updateDepartment({
              department: e,
              raisedBy,
              _id
            })
          }
        >
          <Option value="Crew Team">Crew Team</Option>
          <Option value="CX Team">CX Team</Option>
        </Select>
      );
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ status, raisedBy, _id, updateStatus }) => {
      return (
        <Select
          value={status}
          style={{ width: 120, margin: "0 0.2rem", whiteSpace: "pre-line" }}
          onChange={e =>
            updateStatus({
              status: e,
              raisedBy,
              _id
            })
          }
        >
          <Option value="CLOSED">CLOSED</Option>
          <Option value="CREATED">CREATED</Option>
        </Select>
      );
    }
  }
];

const bookingcolumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "8%",
    render: ({ value, copyCustomerDetails }) => (
      <>
        <span style={wordBreakStyle}>{value}&ensp;</span>
        <CopyOutlined onClick={copyCustomerDetails} />
      </>
    )
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        {/* <EditOutlined onClick={onChange} /> */}
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "PNR",
    dataIndex: "bookingCode",
    width: "8%",
    key: "bookingCode"
  },
  {
    title: "Email ID",
    dataIndex: "email",
    key: "email",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        <span style={wordBreakStyle}>{value}</span>
        {/* <EditOutlined onClick={onChange} /> <br /> */}
      </>
    )
  },
  {
    title: "Seats",
    dataIndex: "seatChart",
    width: "8%",
    key: "seatChart"
  },
  {
    title: "From Station",
    dataIndex: "fromStation",
    width: "8%",
    key: "fromStation",
    render: ({ name, onChange, date }) => (
      <>
        {/* <EditOutlined onClick={onChange} /> */}
        <span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "To Station",
    dataIndex: "toStation",
    width: "8%",
    key: "toStation",
    render: ({ name, onChange, date }) => (
      <>
        {/* <EditOutlined onClick={onChange} /> */}
        <span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "Demand Source",
    dataIndex: "demandSource",
    key: "demandSource",
    width: "8%"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "10%",
    render: ({
      tripId,
      customerTrackingLink,
      copyCustomerTrackingLink,
      showModal
    }) => {
      return (
        <>
          <Link to={`/track/${tripId}`} target="_blank">
            <Button className="mb-1">Track Bus</Button>
          </Link>
          <a href={customerTrackingLink} target="_blank" rel="noreferrer">
            <Button className="mb-1">Customer Link</Button>
          </a>
          <Button onClick={copyCustomerTrackingLink}>
            Share Customer Link
          </Button>
          <Button onClick={showModal}>Show more Details</Button>
        </>
      );
    }
  }
];

const moreDetailsColumns = [
  {
    title: "Status",
    dataIndex: "status",
    width: "8%",
    key: "status"
  },
  {
    title: "Bus Number",
    dataIndex: "busNumber",
    width: "8%",
    key: "busNumber"
  },
  {
    title: "Service Name",
    dataIndex: "service",
    width: "8%",
    key: "service"
  },

  {
    title: "Cash Collection",
    dataIndex: "amountToBeCollected",
    width: "8%",
    key: "amountToBeCollected",
    render: ({ value, agentId, onChange }) =>
      agentId ? (
        <>
          {/* <EditOutlined onClick={onChange} /> */}
          <span style={wordBreakStyle}>{value}</span>
        </>
      ) : (
        <>
          <span style={wordBreakStyle}>{value}</span>
        </>
      )
  },
  {
    title: "Call attempted",
    dataIndex: "callAttempted",
    key: "callAttempted",
    width: "8%"
  },
  {
    title: "Call Records",
    dataIndex: "callRecords",
    key: "callRecords",
    width: "8%",
    render: ({ data = [], from }) => {
      if (data.length) {
        return data.map((call, index) => (
          <div key={index} className="mb-1">
            {call.recording ? (
              <>
                <Link to={call.recording} target="_blank">
                  <PlayCircleOutlined />
                </Link>
                <br></br>
                {call.callfrom == from ? (
                  <div>
                    <>
                      {"I-"}
                      {moment(call.date).format("lll")}
                      <br />
                      {call.callDuration}
                    </>
                  </div>
                ) : (
                  <div>
                    <>
                      {"O-"}
                      {moment(call.date).format("lll")}
                      <br />
                      {call.callDuration}
                    </>
                  </div>
                )}
              </>
            ) : (
              <div>
                <Tag>{call.status}</Tag>
                <br></br>
                <Tag>{call.date}</Tag>
              </div>
            )}
          </div>
        ));
      } else {
        return <Tag color="red">No Call Records</Tag>;
      }
    }
  },
  {
    title: "Refunds",
    dataIndex: "refunds",
    width: "8%",
    key: "refunds",
    render: ({ data, sendRefundMessage }) => (
      <>
        {data.length > 0 && data[0].status ? (
          <div>
            {data[0].status == "INPROGRESS" ? (
              <>
                {data[0].status}
                <Button onClick={sendRefundMessage}>Resend Message</Button>
              </>
            ) : (
              data[0].status
            )}
          </div>
        ) : (
          <Tag color="red">No Records</Tag>
        )}
      </>
    )
  },
  {
    title: "Not Boarded Reason",
    dataIndex: "notBoarededReason",
    key: "notBoarededReason",
    width: "8%"
  },
  {
    title: "Remark",
    dataIndex: "remark",
    width: "8%",
    key: "remark",
    render: ({ value = "", onChange }) => {
      if (!value) {
        value = "";
      }
      value = value.split("|");

      let remarkStr = "";

      for (let i = 0; i < value.length; ++i) {
        remarkStr += value[i];
      }

      return (
        <>
          {/* <EditOutlined onClick={onChange} /> */}
          {remarkStr.length ? (
            <span style={wordBreakStyle}>{remarkStr}</span>
          ) : (
            <span>No Remark</span>
          )}
        </>
      );
    }
  },
  {
    title: "Invoice Action",
    dataIndex: "invoice",
    key: "invoice",
    width: "10%",
    render: ({
      date,
      todaysdate,
      tripStatus,
      downloadEmail,
      sendEmail,
      status
    }) => (
      <>
        {tripStatus === "COMPLETED" ||
        date <= todaysdate ||
        status != "CANCELLED" ? (
          <div>
            <Button className="ant-btn mb-1" onClick={sendEmail}>
              Send Invoice
            </Button>
            <Button className="ant-btn mb-1" onClick={downloadEmail}>
              Download Invoice
            </Button>
          </div>
        ) : (
          <Tag color="red">Trip Not Completed</Tag>
        )}
      </>
    )
  },

  {
    title: "Action History",
    dataIndex: "actionHistory",
    width: "8%",
    key: "actionHistory"
  }
];

const pnrDetailedView = [
  {
    title: "Complain Category",
    dataIndex: "complainCategory",
    key: "complainCategory",
    width: "10%",
    render: ({
      subIssueName,
      _id,
      name,
      selectCategory,
      bookingId,
      serviceId,
      mobileNo,
      bookingCode,
      serviceName,
      recordingUrl,
      tripId
    }) => {
      if (subIssueName == "IVR Call Related") {
        return (
          <div>
            <div>{name}</div>
            <BorderInnerOutlined
              onClick={() =>
                selectCategory(
                  bookingId,
                  serviceId,
                  mobileNo,
                  bookingCode,
                  _id,
                  recordingUrl,
                  serviceName,
                  tripId
                )
              }
            />
          </div>
        );
      } else {
        return <div>{name}</div>;
      }
    }
  },
  {
    title: "Complain Comments",
    dataIndex: "complainComments",
    key: "complainComments",
    width: "10%"
  },
  {
    title: "Mobile No",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "10%"
  },
  {
    title: "Raised By",
    dataIndex: "raisedBy",
    key: "raisedBy",
    width: "10%",
    render: ({ name, date }) => {
      return (
        <div>
          {name}
          <br />
          {date}
        </div>
      );
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ status }) => {
      return <div>{status}</div>;
    }
  }
];

const callDetailsCol = [
  {
    title: "Call Placed by",
    dataIndex: "callPlacedBy",
    key: "callPlacedBy",
    width: "8%"
  },
  {
    title: "Time",
    dataIndex: "time",
    width: "10%",
    key: "time"
  },
  {
    title: "Duration",
    dataIndex: "duration",
    width: "10%",
    key: "duration"
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "10%",
    key: "status"
  },
  {
    title: "Recording",
    dataIndex: "recording",
    width: "10%",
    key: "recording",
    render: ({ recording }) => {
      return (
        <div >
          <audio className="audio_tag" src={recording} controls/>
        </div>
      );
    }
  }
];

const afftectedPnrs = [
  {
    title: "Name",
    dataIndex: "name",
    width: "8%",
    key: "name"
  },
  {
    title: "Booking Code",
    dataIndex: "bookingCode",
    width: "8%",
    key: "bookingCode"
  },
  {
    title: "Trip Date",
    dataIndex: "TripDate",
    width: "8%",
    key: "TripDate"
  },
  {
    title: "From Station",
    dataIndex: "from",
    width: "8%",
    key: "from"
  },
  {
    title: "to Station",
    dataIndex: "to",
    width: "8%",
    key: "to"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "10%",
    render: ({ showModal, call }) => {
      return (
        <>
          <Button onClick={call}>Call customer</Button>

          <Button onClick={showModal}>Show more Details</Button>
        </>
      );
    }
  }
];
class CallLogsCategory extends Component {
  state = {
    contentLoading: false,
    showCallLogs: false,
    loading: true,
    showMarshallCall: false,
    showCustomerCall: false,
    showComplainCategoryModal: false,
    enableOutBound: false,
    ivrSelectedDate: moment(),
    showAllCustomerCall: false,
    showEditRemark: false,
    showCallLogsModal: false,
    showOutBoundsEdit: false,
    showRelatedComplains: false,
    showRelatedComplainsList: [],
    complains: [],
    complainResponse: [],
    afftectedBooking: [],
    bookings: [],
    viewMoreList: [],
    showMoreDetails: false,
    showBookingDetails: false,
    bookingLoader: false,
    outboundRemarkInput: ""
  };

  async componentDidMount() {
    try {
      serviceNameFetched.length = 0;
      raisedByNameFetched.length = 0;
      const complainResponse = await this.fetchComplain();
      let serviceNameHashMap = {},
        raisedByHashMap = {};
      for (let i = 0; i < complainResponse.length; i++) {
        let fetchedData = complainResponse[i];
        serviceNameHashMap[fetchedData.serviceName] = [];
        raisedByHashMap[fetchedData.raisedBy.name] = [];
      }
      const serviceNames = this.props.serviceNames;
      this.setState({ serviceNames });

      for (let i in serviceNameHashMap) {
        let obj = {
          text: i,
          value: i
        };
        serviceNameFetched.push(obj);
      }

      for (let i in raisedByHashMap) {
        let obj = {
          text: i,
          value: i
        };
        raisedByNameFetched.push(obj);
      }

      this.setState({ complainResponse, loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  fetchComplain = async () => {
    try {
      const { tripId } = this.props;
      const url = `/bus-tracker/tripSOSSearch/${tripId}`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response && Array.isArray(response)) {
        const formattedData = this.formattedData(response);
        return formattedData;
      }
      return null;
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  formattedData = response => {
    try {
      return response.map(complainsObj => {
        let latestValue = complainsObj.remarks
          ? complainsObj.remarks.split("|")
          : "";

        latestValue = latestValue ? latestValue.filter(obj => obj != "") : "";
        const complainFormated = {
          _id: complainsObj._id,
          complainCategory: {
            subIssueName: complainsObj.subIssueName,
            name: complainsObj.complainCategory,
            selectCategory: this.selectCategory,
            recordingUrl: complainsObj.recordingUrl,
            _id: complainsObj._id,
            bookingId: complainsObj.bookingId,
            tripId: complainsObj.tripId,
            mobileNo: complainsObj.mobileNo,
            bookingCode: complainsObj.bookingCode,
            serviceName: complainsObj.serviceName,
            serviceId: complainsObj.serviceId
          },
          fromStationDate: complainsObj.fromStationDate,
          mobileNo: complainsObj.mobileNo,
          complainComments: complainsObj.complaintName,
          serviceName: complainsObj.serviceName,
          pnr: {
            priority:
              complainsObj.systemAlertCount > 3
                ? "high"
                : complainsObj.systemAlertCount > 1
                ? "medium"
                : "low",
            bookingId: complainsObj.bookingId,
            bookingCode: complainsObj.pnr,
            systemAlertCount: complainsObj.systemAlertCount
              ? complainsObj.systemAlertCount
              : -1,
            showRelatedComplains: this.showModalRelatedComplains
          },
          callLogs: {
            showCallLogs: this.showCallLogs,
            recordingUrl: complainsObj.recordingUrl,
            _id: complainsObj._id,
            subIssueName: complainsObj.subIssueName,
            bookingCode: complainsObj.pnr
          },
          raisedBy: {
            subIssueName: complainsObj.subIssueName,
            name:
              complainsObj.complaintCategory == "SOS SELF HELP"
                ? "system"
                : complainsObj.raisedBy,
            date: moment(complainsObj.createdOn).format("LLL")
          },
          clickTocall: {
            mobileNo: complainsObj.mobileNo,
            raisedBy:
              complainsObj.complaintCategory == "SOS SELF HELP"
                ? "system"
                : complainsObj.raisedBy,
            upcomingStation: complainsObj.upcomingStation,
            affectedPnrs: complainsObj.affectedPnrs,
            marshallConnect: this.callMarshall,
            userConnect: this.userConnect,
            showBookings: this.etaUserConnect,
            directCall: this.callUser,
            _id: complainsObj._id,
            tripId: complainsObj.tripId,
            bookingCode: complainsObj.bookingCode
          },
          status: {
            updateStatus: this.updateStatus,
            status: complainsObj.status,
            raisedBy:
              complainsObj.complaintCategory == "SOS SELF HELP"
                ? "system"
                : complainsObj.raisedBy,
            _id: complainsObj._id
          },
          department: {
            updateDepartment: this.updateDepartment,
            department: complainsObj.department,
            raisedBy:
              complainsObj.complaintCategory == "SOS SELF HELP"
                ? "system"
                : complainsObj.raisedBy,
            _id: complainsObj._id
          },
          outboundRemarks: {
            _id: complainsObj._id,
            value: latestValue[latestValue.length - 1],
            allOutBoundsRemarks: complainsObj.remarks,
            raisedBy:
              complainsObj.complaintCategory == "SOS SELF HELP"
                ? "system"
                : complainsObj.raisedBy,
            outboundRemarksUpdate: this.outboundRemarksUpdate
          }
        };
        return complainFormated;
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (key, value) => {
    if (key == "showBookingDetails") {
      this.setState({ bookings: [] });
    }
    if (key == "showAllCustomerCall") {
      this.setState({ afftectedBooking: [] });
    }
    this.setState({ [key]: value, viewMoreList: [] });
  };

  handleTextChange = (key, value) => {
    this.setState({ [key]: value });
  };

  updateStatus = async obj => {
    const { complainResponse } = this.state;
    const url = `/bus-tracker/SOSTripUpdate`;
    const toBeUpdateData = complainResponse.filter(comp => comp._id == obj._id);
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    let remark = toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks
      ? `${toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks} `
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}:moved from ${toBeUpdateData[0].status.status} to ${obj.status}|`;

    if (toBeUpdateData[0].pnr && toBeUpdateData[0].pnr.bookingId) {
      updateData = `${remark}${toBeUpdateData[0].complainCategory.name}-${
        decodedToken.name
      },${moment().format("LLL")}:moved from ${
        toBeUpdateData[0].status.status
      } to ${obj.status}|`;
    }
    const options = {
      method: "patch",
      data: {
        _id: obj._id,
        raisedBy: obj.raisedBy,
        status: obj.status,
        outboundRemark: updateData,
        bookingId: toBeUpdateData[0].pnr
          ? toBeUpdateData[0].pnr.bookingId
          : undefined
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedResponse = this.formattedData(response);
      const updatedData = complainResponse.map(obj => {
        const pObj = formattedResponse.find(pObj => pObj._id === obj._id);
        return pObj ? { ...obj, ...pObj } : obj;
      });
      this.setState({ complainResponse: updatedData });
      message.success("Data is updated");
    }
  };

  updateDepartment = async obj => {
    const { complainResponse } = this.state;
    const url = `/bus-tracker/SOSTripUpdate`;
    const toBeUpdateData = complainResponse.filter(comp => comp._id == obj._id);
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    let remark = toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks
      ? `${toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks} `
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}:moved from ${toBeUpdateData[0].department.department} to ${
      obj.department
    }|`;

    if (toBeUpdateData[0].pnr && toBeUpdateData[0].pnr.bookingId) {
      updateData = `${remark}${toBeUpdateData[0].complainCategory.name}-${
        decodedToken.name
      },${moment().format("LLL")}:moved from ${
        toBeUpdateData[0].department.department
      } to ${obj.department}|`;
    }
    const options = {
      method: "patch",
      data: {
        _id: obj._id,
        raisedBy: obj.raisedBy,
        department: obj.department,
        outboundRemark: updateData,
        bookingId: toBeUpdateData[0].pnr
          ? toBeUpdateData[0].pnr.bookingId
          : undefined
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedResponse = this.formattedData(response);
      const updatedData = complainResponse.map(obj => {
        const pObj = formattedResponse.find(pObj => pObj._id === obj._id);
        return pObj ? { ...obj, ...pObj } : obj;
      });
      this.setState({ complainResponse: updatedData });
      message.success("Data is updated");
    }
  };

  updateOutboundRemark = async obj => {
    const {
      complainResponse,
      outboundRemarkInput,
      outboundRemarksUpdateObj
    } = this.state;
    if (outboundRemarkInput.trim().length === 0) {
      message.error("please enter something");
      this.setState({
        showOutBoundsEdit: false,
        selectedId: "",
        outboundRemarkInput: ""
      });
      return;
    }
    const url = `/bus-tracker/SOSTripUpdate`;
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    const toBeUpdateData = complainResponse.filter(
      obj => outboundRemarksUpdateObj._id == obj._id
    );
    let remark = toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks
      ? `${toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks}`
      : "";
    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}: ${outboundRemarkInput}|`;
    if (toBeUpdateData[0].pnr && toBeUpdateData[0].pnr.bookingId) {
      updateData = `${remark}${toBeUpdateData[0].complainCategory.name}-${
        decodedToken.name
      },${moment().format("LLL")}:${outboundRemarkInput}|`;
    }
    const options = {
      method: "patch",
      data: {
        _id: outboundRemarksUpdateObj._id,
        raisedBy: outboundRemarksUpdateObj.raisedBy,
        outboundRemark: updateData,
        bookingId: toBeUpdateData[0].pnr
          ? toBeUpdateData[0].pnr.bookingId
          : undefined
      }
    };
    const response = await Fetch(url, options);

    if (response && Array.isArray(response)) {
      const formattedResponse = this.formattedData(response);
      const updatedData = complainResponse.map(obj => {
        const pObj = formattedResponse.find(pObj => pObj._id === obj._id);
        return pObj ? { ...obj, ...pObj } : obj;
      });
      this.setState({
        complainResponse: updatedData,
        outboundRemarkInput: "",
        showOutBoundsEdit: false
      });
      message.success("Data is updated");
    } else {
      message.error("Data not updated");
      this.setState({
        showOutBoundsEdit: false,
        selectedId: "",
        outboundRemarkInput: ""
      });
    }
  };

  outboundRemarksUpdate = async obj => {
    let allOutBoundsRemarksArray = obj.allOutBoundsRemarks
      ? obj.allOutBoundsRemarks.split("|")
      : "";
    allOutBoundsRemarksArray = allOutBoundsRemarksArray
      ? allOutBoundsRemarksArray.filter(obj => obj != "")
      : "";
    const outboundRemarksUpdateObj = {
      outboundRemark: obj.value,
      allOutBoundsRemarks: allOutBoundsRemarksArray,
      raisedBy: obj.raisedBy,
      _id: obj._id
    };
    this.setState({ showOutBoundsEdit: true, outboundRemarksUpdateObj });
  };

  showModalRelatedComplains = async obj => {
    const { complainResponse } = this.state;
    let filterPnrData = complainResponse.filter(
      data => data.pnr.bookingCode == obj.bookingCode
    );
    this.setState({
      showRelatedComplains: true,
      showRelatedComplainsList: filterPnrData
    });
  };

  selectCategory = async (
    bookingId,
    serviceId,
    mobileNo,
    bookingCode,
    _id,
    recordingUrl,
    serviceName,
    tripId
  ) => {
    const complains = await this.fetchComplainCategory();
    let selectedOutbound = {
      bookingId,
      serviceId,
      mobileNo,
      bookingCode,
      _id,
      serviceName,
      tripId,
      recordingUrl
    };
    this.setState({
      complains,
      showComplainCategoryModal: true,
      selectedOutbound
    });
  };

  fetchComplainCategory = async () => {
    try {
      this.setState({ cancelLoader: true });
      const url = `/bus-tracker/ivrComplaintCategory`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        this.setState({ cancelLoader: false });
        return response;
      } else {
        this.setState({ cancelLoader: false });
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  setValue = value => {
    const { complains } = this.state;
    let id = complains.filter(complain => complain.name === value);
    if (id.length) {
      this.getMandatoryFields(id[0]._id);
    }
  };
  getMandatoryFields = async id => {
    this.setState({ loader: true });
    let { complains, serviceNames, selectedBookings } = this.state;
    let getComplain = complains.filter(complain => complain._id == id);
    let tat;
    let getMandatoryFields = getComplain[0].entities;
    if ((getComplain[0].subIssue.issue.type = "CREWTICKETTRACER")) {
      tat = getComplain[0].tat;
    }
    if (
      getMandatoryFields.includes("bookingId") &&
      getMandatoryFields.includes("serviceId")
    ) {
      getMandatoryFields = getMandatoryFields.filter(
        item => item !== "serviceId"
      );
    }
    if (getMandatoryFields.includes("serviceId") && selectedBookings) {
      const serviceId = selectedBookings[0].booking.trip.serviceId;
      this.setState({ serviceId });
      getMandatoryFields = getMandatoryFields.filter(
        item => item !== "serviceId"
      );
    }
    if (serviceNames.length == 0) {
      serviceNames = await this.getServiceName();
      this.setState({ serviceNames });
    }

    this.setState({
      loader: false,
      mandatoryFields: getMandatoryFields,
      complaintCategoryId: id,
      tat
    });
  };

  callMarshall = async obj => {
    const url = `/bus-tracker/trip/${obj.tripId}/${obj._id}/1`;
    const options = {
      method: "get"
    };
    let response = await Fetch(url, options),
      marshalObj;
    if (response) {
      if (!response[0].busHost) {
        message.error("Host Not Assigned Yet");
        return;
      }
      marshalObj = {
        systemId: obj._id,
        name: response[0].busHost.name,
        service: response[0].service.name,
        number: response[0].busHost.mobileNo,
        TripDate: moment(response[0].tripDate).format("LL")
      };
    }
    this.setState({ marshalObj, showMarshallCall: true });
  };

  userConnect = async obj => {
    const url = `/bus-tracker/bookingCall?pnr=${obj.bookingCode}&updateId=${obj._id}&description=1`;
    const options = {
      method: "get"
    };
    let response = await Fetch(url, options),
      bookingcomplain;
    if (response) {
      bookingcomplain = {
        systemId: obj._id,
        name: response[0].name,
        bookingCode: response[0].bookingCode,
        number: response[0].mobileNo,
        service: response[0].trip.service.name,
        TripDate: moment(response[0].trip.tripDate).format("LL"),
        from: response[0].fromStation.name,
        to: response[0].toStation.name
      };
    }
    this.setState({
      bookingcomplain,
      showCustomerCall: true
    });
  };

  etaUserConnect = async obj => {
    const { afftectedBooking } = this.state;
    const url = `/bus-tracker/tripAfftectedBookings`; // in case of next station only change this to /tripbookings
    const bookingOptions = {
      method: "post",
      data: {
        tripId: obj.tripId,
        // pnr: obj.affectedPnrs,
        upcomingStation: obj.upcomingStation
      }
    };

    let response = await Fetch(url, bookingOptions);
    if (response) {
      response.map(data => {
        let res = {
          systemId: obj._id,
          name: data.name,
          bookingCode: data.bookingCode,
          number: data.mobileNo,
          TripDate: moment(data.trip.tripDate).format("LL"),
          from: data.fromStation.name,
          to: data.toStation.name,
          action: {
            call: () => this.callUser(data.mobileNo),
            showModal: () => this.showModal(data, obj._id)
          }
        };
        afftectedBooking.push(res);
      });
    }
    this.setState({
      afftectedBooking,
      showAllCustomerCall: true
    });
  };

  showModal = booking => {
    const { viewMoreList } = this.state;
    const bookingObj = {
      busNumber: booking.trip.bus ? (
        <span>{booking.trip.bus.rc}</span>
      ) : (
        <span>No Bus Assigned</span>
      ),
      service: booking.trip.service ? booking.trip.service.name : "",
      status: booking.status,
      refund: booking.refund,
      demandSource: booking.demandSource,
      amountToBeCollected: {
        value: booking.amountToBeCollected,
        agentId: booking.agentId ? booking.agentId : null,
        onChange: () =>
          this.setState({
            editField: "amountToBeCollected",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      notBoarededReason: booking.notBoardedReason
        ? booking.notBoardedReason
        : null,
      refunds: {
        data: booking.refund ? booking.refund : null,
        sendRefundMessage: () => this.sendRefundMessage(booking)
      },
      callAttempted: booking.callDetails.length,
      callRecords: {
        data: booking.callDetails,
        from: booking.mobileNo
      },
      IncomingcallRecords: booking.incomingCallDetails,
      remark: {
        value: booking.remark,
        onChange: () =>
          this.setState({
            editField: "remark",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      invoice: {
        status: booking.status,
        value: booking.email,
        bookingcode: booking.bookingCode,
        tripStatus: booking.trip.status,
        date: booking.toStationDate,
        todaysdate: Date.now(),
        sendEmail: () => this.sendEmail(booking.bookingCode, booking.email),
        downloadEmail: () => this.downloadEmail(booking.bookingCode),
        onChange: () =>
          this.setState({
            editField: "email",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      mobileNo: {
        value: booking.mobileNo,
        onChange: () =>
          this.setState({
            editField: "mobileNo",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      actionHistory: booking.actionHistory || ""
    };
    viewMoreList.push(bookingObj);
    this.setState({ showMoreDetails: true, viewMoreList });
  };

  showCallLogs = async obj => {
    this.setState({ showCallLogsModal: true });
    const url = `/bus-tracker/bookingCall?pnr=${obj.bookingCode}&updateId=${obj._id}&description=2`;
    const options = {
      method: "get"
    };
    let response = await Fetch(url, options);
    response.map(obj => {
      let callDetailsObj = [];
      if (
        obj.callDetails &&
        Array.isArray(obj.callDetails) &&
        obj.callDetails.length > 0
      ) {
        for (let o = 0; o < obj.callDetails.length; o++) {
          let data = obj.callDetails[o];
          let startTime = new Date(data.start_time).getTime();
          let endTime = new Date(data.end_time).getTime();
          const diff = endTime - startTime;
          let callDuration = msToTime(diff);
          let callData = {
            callPlacedBy: data.callfrom == obj.mobileNo ? "User" : "Marshal",
            time: data.start_time,
            duration: callDuration,
            status: data.status,
            recording: { recording: data.recording, status: data.status }
          };
          callDetailsObj.push(callData);
        }
      }
      this.setState({ callDetailsObj });
    });
  };

  call = async () => {
    const { bookingcomplain, marshalObj } = this.state;
    const url = `/bus-tracker/outboundCall`;
    const options = {
      method: "post",
      data: {
        number: marshalObj ? marshalObj.number : bookingcomplain.number,
        systemId: marshalObj ? marshalObj.systemId : bookingcomplain.systemId
      }
    };
    let response = await Fetch(url, options);
    if (response == "ok") {
      message.success("You will recieve call shortly");
      this.setState({
        showMarshallCall: false,
        showCustomerCall: false,
        marshalObj: undefined,
        bookingcomplain: []
      });
    } else if (response == "error") {
      message.error("There is some error");
      this.setState({
        showMarshallCall: false,
        showCustomerCall: false,
        marshalObj: undefined,
        bookingcomplain: []
      });
    }
  };

  callUser = async (number, systemId) => {
    const url = `/bus-tracker/outboundCall`;
    const options = {
      method: "post",
      data: {
        number: number,
        systemId: systemId
      }
    };
    let response = await Fetch(url, options);
    if (response == "ok") {
      message.success("You will recieve call shortly");
    } else if (response == "error") {
      message.error("There is some error");
    }
  };

  fetchBooking = async () => {
    try {
      this.setState({ bookingLoader: true });
      let { bookingcomplain } = this.state;
      if (bookingcomplain) {
        let url = `/bus-tracker/booking?pnr=${bookingcomplain.bookingCode}`;
        const options = {
          method: "get"
        };
        let response = await Fetch(url, options);
        if (response) {
          const bookingR = [];
          const stationMap = {};
          let station;
          let booking;
          let stationArray = [];
          let callDuration = "";
          let incomingcallDuration = "";

          for (let i = 0; i < response.length; ++i) {
            booking = response[i];
            stationArray.push(booking.tripId);
          }

          const urlEta = `/bus-tracker/bulkStations/${stationArray}`;
          let EtaResponse = await Fetch(urlEta, options);

          for (let i = 0; i < EtaResponse.length; ++i) {
            station = EtaResponse[i];
            stationMap[station.stationId] = station;
          }

          for (let i = 0; i < response.length; ++i) {
            const booking = response[i];
            station = stationMap[booking.fromStation._id];
            for (let i = 0; i < booking.callDetails.length; ++i) {
              let calls = booking.callDetails[i];
              if (calls) {
                if (calls.recording) {
                  let startTime = new Date(calls.start_time).getTime();
                  let endTime = new Date(calls.end_time).getTime();
                  const diff = endTime - startTime;
                  callDuration = msToTime(diff);
                } else {
                  callDuration = null;
                }
                booking.callDetails[i]["callDuration"] = callDuration;
              }
            }

            let seatsLabel = "";
            if (booking.tripChart && booking.tripChart.length > 0) {
              for (let i = 0; i < booking.tripChart.length; ++i) {
                for (let i = 0; i < booking.tripChart.length; ++i) {
                  const seat = booking.tripChart[i];

                  if (seat.status === "CONFIRMED") {
                    const seatNumber = seat.seat.seatLabel;
                    const seatGender = seat.gender ? seat.gender : "";

                    if (seatsLabel.length === 0) {
                      seatsLabel += `${seatNumber}(${seatGender})`;
                    } else {
                      seatsLabel += `,${seatNumber}(${seatGender})`;
                    }
                  }
                }
                break;
              }
            }
            if (
              booking.status === "CONFIRMED" ||
              booking.status === "FAILED" ||
              booking.status === "NOT REPORTED" ||
              booking.status === "CANCELLED"
            ) {
              const bookingObj = {
                key: booking._id,
                name: {
                  value: booking.name,
                  copyCustomerDetails: () =>
                    this.copyCustomerDetails(
                      booking.bookingCode,
                      booking.mobileNo,
                      seatsLabel,
                      booking.fromStation.name,
                      booking.fromStation.city.name,
                      booking.fromStationDate,
                      booking.toStation.name,
                      booking.toStation.city.name,
                      booking.trackingUrl,
                      booking.trip.bus
                    )
                },
                email: {
                  value: booking.email,
                  tripStatus: booking.trip.status,
                  onChange: () =>
                    this.setState({
                      editField: "email",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                invoice: {
                  status: booking.status,
                  value: booking.email,
                  bookingcode: booking.bookingCode,
                  tripStatus: booking.trip.status,
                  date: booking.toStationDate,
                  todaysdate: Date.now(),
                  sendEmail: () =>
                    this.sendEmail(booking.bookingCode, booking.email),
                  downloadEmail: () => this.downloadEmail(booking.bookingCode),
                  onChange: () =>
                    this.setState({
                      editField: "email",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                mobileNo: {
                  value: booking.mobileNo,
                  onChange: () =>
                    this.setState({
                      editField: "mobileNo",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                demandSource: ` ${booking.demandSource}(${
                  booking.otaPartner ? booking.otaPartner : ""
                })`,
                bookingCode: booking.bookingCode,
                seatChart: seatsLabel,
                fromStation: {
                  name: `${booking.fromStation.name}, ${booking.fromStation.city.name}`,
                  date: moment(booking.fromStationDate).format("lll"),
                  onChange: () =>
                    this.setState({
                      editField: "fromStation",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                seats: getSeatNumberFromTripChart(booking.tripChart),
                toStation: {
                  name: `${booking.toStation.name}, ${booking.toStation.city.name}`,
                  date: moment(booking.toStationDate).format("lll"),
                  onChange: () =>
                    this.setState({
                      editField: "toStation",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                busNumber: booking.trip.bus ? (
                  <span>{booking.trip.bus.rc}</span>
                ) : (
                  <span>No Bus Assigned</span>
                ),
                crewDetails: {
                  bus: booking.trip.bus,
                  busHost: booking.trip.busHost,
                  busDriver: booking.trip.busDriver
                },
                amountToBeCollected: {
                  value: booking.amountToBeCollected,
                  agentId: booking.agentId ? booking.agentId : null,
                  onChange: () =>
                    this.setState({
                      editField: "amountToBeCollected",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                call: booking.callDetails.length,
                // remark: booking.remark,
                service: booking.trip.service.name,
                status: booking.status,
                refunds: {
                  data: booking.refund ? booking.refund : null,
                  sendRefundMessage: () => this.sendRefundMessage(booking)
                },
                callAttempted: booking.callDetails.length,
                callRecords: {
                  data: booking.callDetails,
                  from: booking.mobileNo
                },

                IncomingcallRecords: booking.incomingCallDetails,
                action: {
                  tripId: booking.tripId,
                  customerTrackingLink: `http://${booking.trackingUrl}`,
                  copyCustomerTrackingLink: () =>
                    this.copyCustomerTrackingLink(booking.trackingUrl),
                  showModal: () => this.showModal(booking)
                },
                trackingUrl: booking.trackingUrl,
                booking,
                actionHistory: booking.actionHistory || "",
                notBoarededReason: booking.notBoardedReason
                  ? booking.notBoardedReason
                  : null,
                remark: {
                  value: booking.remark,
                  onChange: () =>
                    this.setState({
                      editField: "remark",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                _id: booking._id
              };
              bookingR.push(bookingObj);
            }
          }
          this.setState({
            showBookingDetails: true,
            bookings: bookingR,
            bookingLoader: false
          });
        }
      } else {
        this.setState({ bookingLoader: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ bookingLoader: false });
    }
  };

  updateData = response => {
    if (response && Array.isArray(response)) {
      raisedByNameFetched.length = 0;
      let serviceNameHashMap = {},
        raisedByHashMap = {};
      for (let i = 0; i < response.length; i++) {
        let fetchedData = response[i];
        serviceNameHashMap[fetchedData.serviceName] = [];
        raisedByHashMap[fetchedData.raisedBy] = [];
      }
      for (let i in serviceNameHashMap) {
        let obj = {
          text: i,
          value: i
        };
        serviceNameFetched.push(obj);
      }
      for (let i in raisedByHashMap) {
        let obj = {
          text: i,
          value: i
        };
        raisedByNameFetched.push(obj);
      }

      const formattedData = this.formattedData(response);
      this.setState({ complainResponse: formattedData, contentLoading: false });
    } else {
      this.setState({ complainResponse: [], contentLoading: false });
    }
  };

  handleSendClick = async () => {
    const { mandatoryFields, selectedOutbound } = this.state;
    let Bodyobj = {
      complaintCategoryId: this.state.complaintCategoryId,
      recordingUrl: selectedOutbound.recordingUrl,
      mobileNo: selectedOutbound.mobileNo,
      comment: this.state.Comment < 1 ? undefined : this.state.Comment,
      ivrComplaintId: selectedOutbound._id,
      tripId: selectedOutbound.tripId,
      bookingId: selectedOutbound.bookingCode
        ? selectedOutbound.bookingCode
        : this.state.bookingCode,
      serviceId: selectedOutbound.serviceId,
      enableOutBound: this.state.enableOutBound,
      raisedByIVR: true,
      tat: this.state.tat
    };
    mandatoryFields.map(complain => {
      if (this.state[complain]) {
        Bodyobj = {
          ...Bodyobj,
          [complain]: this.state[complain]
        };
      }
    });

    const url = `/bus-tracker/sendIvrComplain`;
    const options = {
      method: "post",
      data: { obj: Bodyobj }
    };

    const response = await Fetch(url, options);
    if (response) {
      message.success("Data is updated");

      mandatoryFields.map(complain => {
        this.setState({
          [complain]: undefined
        });
      });
      this.setState({
        showComplainCategoryModal: false,
        complains: [],
        mandatoryFields: undefined,
        complaintCategoryId: undefined
      });
    } else {
      message.error("something went wrong");
    }
  };

  handleSpecialCharacters = async event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    this.setState({ bookingCode: newCheck });
  };

  render() {
    const {
      loading,
      complainResponse,
      showMarshallCall,
      showCustomerCall,
      showCallLogsModal,
      marshalObj,
      bookingcomplain,
      bookings,
      bookingLoader,
      viewMoreList,
      showMoreDetails,
      showBookingDetails,
      showOutBoundsEdit,
      showRelatedComplains,
      showRelatedComplainsList,
      outboundRemarkInput,
      outboundRemarksUpdateObj,
      callDetailsObj,
      showAllCustomerCall,
      afftectedBooking,
      contentLoading,
      ivrSelectedDate,
      complains,
      enableOutBound,
      serviceNames,
      mandatoryFields,
      showComplainCategoryModal,
      bookingCode,
      date,
      loader,
      selectedOutbound
    } = this.state;
    console.log("complainResponse======", complainResponse);
    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div
            style={{
              overflow: "hidden",
              width: "100%"
            }}
          >
            <Table
              scroll={{ y: "70vh" }}
              columns={columns}
              loading={contentLoading}
              pagination={false}
              dataSource={complainResponse}
            />
          </div>
          <Modal
            title=" Marshal Details"
            visible={showMarshallCall}
            centered
            onCancel={() =>
              this.setState({
                showMarshallCall: false,
                marshalObj: undefined
              })
            }
            closable={false}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <div>
              <p>
                Marshal Name:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {marshalObj ? marshalObj.name : ""}
                </b>
              </p>
              <p>
                {" "}
                Service:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {marshalObj ? marshalObj.service : ""}
                </b>
              </p>
              <p>
                Trip Date:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {marshalObj ? marshalObj.TripDate : ""}
                </b>
              </p>
            </div>
            <b
              style={{
                fontSize: "15px"
              }}
            >
              {" "}
              Are you sure you want to place a Call to the Marshal ?
            </b>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                type="primary"
                onClick={this.call}
                style={{ marginRight: "5px" }}
              >
                Call
              </Button>
              <Button
                onClick={() =>
                  this.setState({
                    showMarshallCall: false,
                    marshalObj: undefined
                  })
                }
                type="ghost"
              >
                Cancel
              </Button>
            </div>
          </Modal>
          <Modal
            centered
            width={1500}
            title="Create complain"
            footer={null}
            visible={showComplainCategoryModal}
            onCancel={() =>
              this.setState({
                showComplainCategoryModal: false,
                mandatoryFields: "",
                complaintCategoryId: ""
              })
            }
          >
            <div
              className="row"
              style={{
                margin: "10px"
              }}
            >
              <div className="col-4">Select Complain Name</div>
              <div className="col-6 text-left">
                <AutoComplete
                  style={{
                    width: "100%"
                  }}
                  dataSource={complains.map(complain => complain.name)}
                  onChange={value => this.setValue(value)}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div>
            </div>
            {Array.isArray(mandatoryFields) ? (
              serviceNames.length > 0 ? (
                mandatoryFields.map(fields => (
                  <div
                    loading={loader}
                    className="row"
                    style={{
                      margin: "10px"
                    }}
                  >
                    <div className="col-4">
                      {fields == "serviceId"
                        ? "Service Name"
                        : fields == "bookingId"
                        ? "PNR"
                        : fields == "date"
                        ? "Service Date"
                        : null}{" "}
                    </div>
                    <div className="col-6 text-left">
                      {" "}
                      {fields == "serviceId" ? (
                        <AutoComplete
                          style={{
                            width: "100%"
                          }}
                          dataSource={serviceNames.map(service => service.name)}
                          onChange={value => {
                            let id = serviceNames.filter(
                              service => service.name === value
                            );
                            if (id.length) {
                              this.setState({
                                [fields]: id[0]._id
                              });
                            } else {
                              this.setState({
                                [fields]: null
                              });
                            }
                          }}
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      ) : fields == "bookingId" ? (
                        <TextArea
                          className="mb-1"
                          type="text"
                          disabled={selectedOutbound.bookingId ? true : false}
                          value={
                            selectedOutbound.bookingCode
                              ? selectedOutbound.bookingCode
                              : bookingCode
                          }
                          maxLength="20"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          style={{ width: "100%" }}
                          onChange={this.handleSpecialCharacters}
                        />
                      ) : fields == "date" ? (
                        <DatePicker
                          style={{ width: 150 }}
                          value={date}
                          onChange={date => this.setState({ date })}
                        />
                      ) : null}{" "}
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-6 text-left">
                  Please wait loading data........
                </div>
              )
            ) : null}
            {Array.isArray(mandatoryFields) && serviceNames.length > 0 ? (
              <>
                <div
                  className="row"
                  style={{
                    margin: "10px"
                  }}
                >
                  <div className="col-4">Comments</div>
                  <div className="col-6 text-left">
                    <TextArea
                      className="mb-1"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      style={{ width: "100%" }}
                      onChange={e =>
                        this.handleChange("Comment", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    margin: "10px"
                  }}
                >
                  <div className="col-4"></div>
                  <div className="col-6 text-left">
                    <Checkbox
                      value={enableOutBound}
                      onChange={e =>
                        this.setState({
                          enableOutBound: e.target.checked
                        })
                      }
                    >
                      Enable outbound Call{" "}
                    </Checkbox>
                  </div>
                </div>

                <Button
                  type="primary"
                  onClick={this.handleSendClick}
                  style={{ margin: "1% 0" }}
                >
                  Confirm Issue
                </Button>
              </>
            ) : null}
          </Modal>
          <Modal
            title=" Passenger Details"
            visible={showCustomerCall}
            centered
            onCancel={() =>
              this.setState({
                showCustomerCall: false,
                bookingcomplain: []
              })
            }
            closable={false}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <div>
              <p>
                Name:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.name : ""}
                </b>
              </p>
              <p>
                {" "}
                Service:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.service : ""}
                </b>
              </p>
              <p>
                Trip Date:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.TripDate : ""}
                </b>
              </p>
              <p>
                From:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.from : ""}
                </b>
              </p>{" "}
              <p>
                To:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.to : ""}
                </b>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse"
              }}
            >
              <Button
                onClick={this.fetchBooking}
                loading={bookingLoader}
                type="primary"
              >
                View More Details
              </Button>
            </div>
            <b
              style={{
                fontSize: "15px"
              }}
            >
              {" "}
              Are you sure you want to place a Call to the passenger ?
            </b>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                type="primary"
                onClick={this.call}
                style={{ marginRight: "5px" }}
              >
                Call
              </Button>
              <Button
                onClick={() =>
                  this.setState({
                    showCustomerCall: false,
                    bookingcomplain: []
                  })
                }
                type="ghost"
              >
                Cancel
              </Button>
            </div>
          </Modal>
          <Modal
            centered
            width={"100%"}
            title="Call Logs"
            visible={showCallLogsModal}
            footer={null}
            onCancel={() =>{
              const audioTag = document.getElementsByClassName('audio_tag');
              if(audioTag && audioTag.length){
                for(let tag of audioTag){
                  if (tag.duration && tag.duration > 0 && !tag.paused) {
                    tag.pause(); 
                  } 
                }
              }
              this.setState({
                showCallLogsModal: false,
                callDetailsObj: []
              })
              
            }}
          >
            <Table
              columns={callDetailsCol}
              dataSource={callDetailsObj}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </Modal>

          <Modal
            centered
            width={"100%"}
            title="All Complains"
            visible={showRelatedComplains}
            footer={null}
            onCancel={() =>
              this.setState({
                showRelatedComplains: false,
                showRelatedComplainsList: []
              })
            }
          >
            <Table
              columns={pnrDetailedView}
              dataSource={showRelatedComplainsList}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </Modal>
          <Modal
            title="OutBound Remarks"
            visible={showOutBoundsEdit}
            okText="Update"
            onOk={this.updateOutboundRemark}
            onCancel={() =>
              this.setState({
                outboundRemarkInput: "",
                showOutBoundsEdit: false
              })
            }
          >
            {outboundRemarksUpdateObj &&
            Array.isArray(outboundRemarksUpdateObj.allOutBoundsRemarks)
              ? outboundRemarksUpdateObj.allOutBoundsRemarks.map(text => {
                  return (
                    <div>
                      <p>{text}</p>
                    </div>
                  );
                })
              : null}

            <Input
              value={outboundRemarkInput}
              placeholder="Enter Remark"
              onChange={e =>
                this.handleTextChange("outboundRemarkInput", e.target.value)
              }
            />
          </Modal>

          <Modal
            centered
            width={"100%"}
            title="More Details"
            visible={showMoreDetails}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => this.handleChange("showMoreDetails", false)}
            footer={null}
          >
            <Table
              columns={moreDetailsColumns}
              dataSource={viewMoreList}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </Modal>
          <Modal
            centered
            width={"100%"}
            title="More Details"
            visible={showBookingDetails}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => this.handleChange("showBookingDetails", false)}
            footer={null}
          >
            <Table
              columns={bookingcolumns}
              dataSource={bookings}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </Modal>
          <Modal
            centered
            width={"100%"}
            title="Afftected PNRs"
            visible={showAllCustomerCall}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => this.handleChange("showAllCustomerCall", false)}
            footer={null}
          >
            <Table
              columns={afftectedPnrs}
              dataSource={afftectedBooking}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </Modal>
        </div>
      );
    }
  }
}

export default CallLogsCategory;
