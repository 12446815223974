import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  EditOutlined,
  PlayCircleOutlined,
  CopyOutlined
} from "@ant-design/icons";
import SendMessage from "../sendMessage";
import SendRefund from "../sendRefund";
import {
  Input,
  Button,
  DatePicker,
  Select,
  Modal,
  message,
  Table,
  Tag
} from "antd";
import Fetch from "../../utils/fetch";
import EditBooking from "../editBooking";
import swapIcon from "../../images/swap-icon.svg";
import { getSeatNumberFromTripChart } from "../../utils/common";

const { Option } = Select;
const wordBreakStyle = { wordBreak: "breakAll" };
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "8%",
    render: ({ value, copyCustomerDetails }) => (
      <>
        <span style={wordBreakStyle}>{value}&ensp;</span>
        <CopyOutlined onClick={copyCustomerDetails} />
      </>
    )
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        <EditOutlined onClick={onChange} />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "Email ID",
    dataIndex: "email",
    key: "email",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        <span style={wordBreakStyle}>{value}</span>
        <EditOutlined onClick={onChange} /> <br />
      </>
    )
  },
  {
    title: "PNR",
    dataIndex: "bookingCode",
    width: "8%",
    key: "bookingCode"
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "8%",
    key: "status"
  },
  {
    title: "Bus Number",
    dataIndex: "busNumber",
    width: "8%",
    key: "busNumber"
  },
  {
    title: "Service Name",
    dataIndex: "service",
    width: "8%",
    key: "service"
  },
  {
    title: "Seats",
    dataIndex: "seatChart",
    width: "8%",
    key: "seatChart"
  },
  {
    title: "From Station",
    dataIndex: "fromStation",
    width: "8%",
    key: "fromStation",
    render: ({ name, bookingType, onChange, date }) => (
      <>
        {bookingType == "CONNECTINGSECONDLEG" ? null : (
          <EditOutlined onClick={onChange} />
        )}
        <span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "To Station",
    dataIndex: "toStation",
    width: "8%",
    key: "toStation",
    render: ({ name, bookingType, onChange, date }) => (
      <>
        {bookingType == "CONNECTINGFIRSTLEG" ? null : (
          <EditOutlined onClick={onChange} />
        )}
        |<span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "Cash Collection",
    dataIndex: "amountToBeCollected",
    width: "8%",
    key: "amountToBeCollected",
    render: ({ value, agentId, onChange }) =>
      agentId ? (
        <>
          <EditOutlined onClick={onChange} />
          <span style={wordBreakStyle}>{value}</span>
        </>
      ) : (
        <>
          <span style={wordBreakStyle}>{value}</span>
        </>
      )
  },
  {
    title: "Call Records",
    dataIndex: "callRecords",
    width: "8%",
    key: "callRecords",
    render: (data = []) => {
      if (data.length) {
        return data.map((call, index) => (
          <div key={index} className="mb-1">
            {call.recording ? (
              <>
                <Link to={call.recording} target="_blank">
                  <PlayCircleOutlined />
                </Link>
                <br></br>
                {call.callTime}
              </>
            ) : (
              <div>
                <Tag>{call.status}</Tag>
                <br></br>
                <Tag>{call.callTime}</Tag>
              </div>
            )}
          </div>
        ));
      } else {
        return <Tag color="red">No Call Records</Tag>;
      }
    }
  },
  {
    title: "Refunds",
    dataIndex: "refunds",
    width: "8%",
    key: "refunds",
    render: ({ data, sendRefundMessage }) => (
      <>
        {data.length > 0 && data[0].status ? (
          <div>
            {data[0].status == "INPROGRESS" ? (
              <>
                {data[0].status}
                <Button onClick={sendRefundMessage}>Resend Message</Button>
              </>
            ) : (
              data[0].status
            )}
          </div>
        ) : (
          <Tag color="red">No Records</Tag>
        )}
      </>
    )
  },
  {
    title: "Remark",
    dataIndex: "remark",
    width: "8%",
    key: "remark",
    render: ({ value = "", onChange }) => {
      if (!value) {
        value = "";
      }
      value = value.split("|");

      let remarkStr = "";

      for (let i = 0; i < value.length; ++i) {
        remarkStr += value[i];
      }

      return (
        <>
          <EditOutlined onClick={onChange} />
          {remarkStr.length ? (
            <span style={wordBreakStyle}>{remarkStr}</span>
          ) : (
            <span>No Remark</span>
          )}
        </>
      );
    }
  },
  {
    title: "Invoice Action",
    dataIndex: "invoice",
    key: "invoice",
    width: "8%",
    render: ({
      date,
      todaysdate,
      tripStatus,
      downloadEmail,
      sendEmail,
      status
    }) => (
      <>
        {tripStatus === "COMPLETED" ||
        date <= todaysdate ||
        status != "CANCELLED" ? (
          <div>
            <Button className="ant-btn mb-1" onClick={sendEmail}>
              Send Invoice
            </Button>
            <Button className="ant-btn mb-1" onClick={downloadEmail}>
              Download Invoice
            </Button>
          </div>
        ) : (
          <Tag color="red">Trip Not Completed</Tag>
        )}
      </>
    )
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "8%",
    render: ({ tripId, customerTrackingLink, copyCustomerTrackingLink }) => {
      return (
        <>
          <Link to={`/track/${tripId}`} target="_blank">
            <Button className="mb-1">Track Bus</Button>
          </Link>
          <a href={customerTrackingLink} rel="noreferrer" target="_blank">
            <Button className="mb-1">Customer Link</Button>
          </a>
          <Button onClick={copyCustomerTrackingLink}>
            Share Customer Link
          </Button>
        </>
      );
    }
  },
  {
    title: "Action History",
    dataIndex: "actionHistory",
    width: "8%",
    key: "actionHistory"
  }
];

class SearchForm extends Component {
  state = {
    showResult: false,
    result: [],
    fromCity: "",
    toCity: "",
    date: moment(),
    tripFilter: "ALL",
    editBooking: false,
    editField: "",
    bookingToEdit: {},
    bookingEdit: false,
    updatedField: "",
    selectKey: "1",
    bookingSearchValue: "",
    tripLoader: false,
    bookingLoader: false
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const issueResponse = await this.fetchIssue();
      this.setState({ customerIssue: issueResponse });
    } catch (err) {
      message.error(err);
    }
  }
  fetchIssue = async () => {
    try {
      const url = `/bus-tracker/customerIssue`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      return response;
    } catch (err) {
      message.error(err);
    }
  };

  msToTime = duration => {
    var minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + " hrs :" + minutes + " min";
  };

  fetchBooking = async () => {
    try {
      this.setState({ bookingLoader: true });
      let { selectKey = "1", bookingSearchValue } = this.state;
      if (bookingSearchValue) {
        let url = `/bus-tracker/booking?connecting=true&`;
        if (
          bookingSearchValue.slice(0, 2) == "zb" ||
          bookingSearchValue.slice(0, 2) == "ZB"
        ) {
          url += `pnr=${bookingSearchValue}`;
        } else {
          if (
            bookingSearchValue.length > 10 &&
            bookingSearchValue.slice(0, 2) == "91"
          ) {
            let mobile = bookingSearchValue.substring(2);
            url += `mobileNo=${mobile}`;
          } else {
            url += `mobileNo=${bookingSearchValue}`;
          }
        }

        const options = {
          method: "get"
        };

        let response = await Fetch(url, options);
        response = response.sort(
          (a, b) => b.fromStationDate - a.fromStationDate
        );
        if (response) {
          const bookingR = [];
          const stationMap = {};
          let station;
          let booking;
          let stationArray = [];
          let callTime = "";

          for (let i = 0; i < response.length; ++i) {
            booking = response[i];
            stationArray.push(booking.tripId);
          }

          const urlEta = `/bus-tracker/bulkStations/${stationArray}`;
          let EtaResponse = await Fetch(urlEta, options);

          for (let i = 0; i < EtaResponse.length; ++i) {
            station = EtaResponse[i];
            stationMap[station.stationId] = station;
          }

          for (let i = 0; i < response.length; ++i) {
            const booking = response[i];
            // const etaURL = `/bus-tracker/stations/${booking.tripId}`;
            // let etaResponse = await Fetch(etaURL, options);
            // function msToTime(duration) {
            //   var minutes = Math.floor((duration / (1000 * 60)) % 60),
            //     hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

            //   hours = hours < 10 ? "0" + hours : hours;
            //   minutes = minutes < 10 ? "0" + minutes : minutes;
            //   return hours + " hrs :" + minutes + " min";
            // }

            // for (let i = 0; i < etaResponse.length; ++i) {
            //   const station = etaResponse[i];
            //   stationMap[station.stationId] = station;
            // }
            // let callTime = "";
            station = stationMap[booking.fromStation._id];
            for (let i = 0; i < booking.callDetails.length; ++i) {
              const t = new Date(booking.callDetails[i].date);
              if (station) {
                if (station.reachedTime) {
                  const Kalyera = t.getTime();
                  const diff = Math.abs(Kalyera - station.reachedTime);
                  callTime = this.msToTime(diff);
                } else {
                  callTime = booking.callDetails[i].date;
                }
              }

              booking.callDetails[i]["callTime"] = callTime;
            }
            let seatsLabel = "";
            if (booking.tripChart && booking.tripChart.length > 0) {
              for (let i = 0; i < booking.tripChart.length; ++i) {
                for (let i = 0; i < booking.tripChart.length; ++i) {
                  const seat = booking.tripChart[i];

                  if (seat.status === "CONFIRMED") {
                    const seatNumber = seat.seat.seatLabel;
                    const seatGender = seat.gender ? seat.gender : "";

                    if (seatsLabel.length === 0) {
                      seatsLabel += `${seatNumber}(${seatGender})`;
                    } else {
                      seatsLabel += `,${seatNumber}(${seatGender})`;
                    }
                  }
                }
                break;
              }
            }
            if (
              booking.status === "CONFIRMED" ||
              booking.status === "FAILED" ||
              booking.status === "NOT REPORTED" ||
              booking.status === "CANCELLED"
            ) {
              const bookingObj = {
                key: booking._id,
                name: {
                  value: booking.name,
                  copyCustomerDetails: () =>
                    this.copyCustomerDetails(
                      booking.bookingCode,
                      booking.mobileNo,
                      seatsLabel,
                      booking.fromStation.name,
                      booking.fromCity.name,
                      booking.fromStationDate,
                      booking.toStation.name,
                      booking.toCity.name,
                      booking.trackingUrl,
                      booking.trip.bus
                    )
                },
                email: {
                  value: booking.email,
                  tripStatus: booking.trip.status,
                  onChange: () =>
                    this.setState({
                      editField: "email",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                invoice: {
                  status: booking.status,
                  value: booking.email,
                  bookingcode: booking.bookingCode,
                  tripStatus: booking.trip.status,
                  date: booking.toStationDate,
                  todaysdate: Date.now(),
                  sendEmail: () =>
                    this.sendEmail(booking.bookingCode, booking.email),
                  downloadEmail: () => this.downloadEmail(booking.bookingCode),
                  onChange: () =>
                    this.setState({
                      editField: "email",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                mobileNo: {
                  value: booking.mobileNo,
                  onChange: () =>
                    this.setState({
                      editField: "mobileNo",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                bookingCode: booking.bookingCode,
                seatChart: seatsLabel,
                fromStation: {
                  name: `${booking.fromStation.name}, ${booking.fromCity.name}`,
                  bookingType: booking.bookingType,
                  date: moment(booking.fromStationDate).format("lll"),
                  onChange: () =>
                    this.setState({
                      editField: "fromStation",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                seats: getSeatNumberFromTripChart(booking.tripChart),
                toStation: {
                  name: `${booking.toStation.name}, ${booking.toCity.name}`,
                  bookingType: booking.bookingType,
                  date: moment(booking.toStationDate).format("lll"),
                  onChange: () =>
                    this.setState({
                      editField: "toStation",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                busNumber: booking.trip.bus ? (
                  <span>{booking.trip.bus.rc}</span>
                ) : (
                  <span>No Bus Assigned</span>
                ),
                amountToBeCollected: {
                  value: booking.amountToBeCollected,
                  agentId: booking.agentId ? booking.agentId : null,
                  onChange: () =>
                    this.setState({
                      editField: "amountToBeCollected",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                call: booking.callDetails.length,
                service: booking.trip.service.name,
                status: booking.status,
                refunds: {
                  data: booking.refund ? booking.refund : null,
                  sendRefundMessage: () => this.sendRefundMessage(booking)
                },
                callRecords: booking.callDetails,
                action: {
                  tripId: booking.tripId,
                  customerTrackingLink: `http://${booking.trackingUrl}`,
                  copyCustomerTrackingLink: () =>
                    this.copyCustomerTrackingLink(booking.trackingUrl)
                },
                trackingUrl: booking.trackingUrl,
                booking,
                actionHistory: booking.actionHistory || "",
                remark: {
                  value: booking.remark,
                  onChange: () =>
                    this.setState({
                      editField: "remark",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                _id: booking._id
              };

              bookingR.push(bookingObj);
            }
          }
          this.setState({
            showResult: true,
            showBooking: true,
            result: response,
            bookings: bookingR,
            bookingLoader: false
          });
        }
      } else {
        this.setState({ bookingLoader: false });
        const errMsg =
          selectKey === "1"
            ? `PNR field is blank`
            : `Mobile Number field is blank`;
        message.error(errMsg);
        this.setState({ bookingLoader: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ bookingLoader: false });
    }
  };

  fetchTrip = async () => {
    try {
      this.setState({ tripLoader: true });
      const { fromCity, toCity, date, tripFilter } = this.state;

      if (fromCity && toCity && date) {
        const url = `/bus-tracker/searchRoute?fromCity=${fromCity}&toCity=${toCity}&date=${moment(
          date
        ).format("YYYY-MM-DD")}&tripFilter=${tripFilter}&connecting=true`;

        const options = {
          method: "get"
        };

        let response = await Fetch(url, options);
        // response = response.filter(
        //   tripsearchfilter => tripsearchfilter.status != "INACTIVE"
        // );
        if (response) {
          this.setState({ tripLoader: false }, () =>
            this.props.onTripSearch(response)
          );
        } else {
          this.setState({ tripLoader: false });
        }
      } else if (date) {
        const url = `/bus-tracker/allQLConnectingTrips`;

        const options = {
          method: "post",
          data: {
            date: moment(date).format("YYYY-MM-DD"),
            tripFilter
          }
        };

        const response = await Fetch(url, options);

        if (response !== "No data found") {
          this.setState({ tripLoader: false }, () =>
            this.props.onTripSearch(response)
          );
        } else {
          message.error("No trip found");
          this.setState({ tripLoader: false });
        }
      }
    } catch (err) {
      message.error(err);
      this.setState({ tripLoader: false });
    }
  };

  updateBookingStatus = async (booking, type) => {
    try {
      const url = `/bus-tracker/booking/boarding`;

      const options = {
        method: "patch",
        data: {
          bookings: [booking],
          type
        }
      };

      const response = await Fetch(url, options);

      const formatedBooking = this.formatBooking(response);

      this.setState({ result: formatedBooking });
    } catch (err) {
      message.error(err);
    }
  };

  showBooking = bookings => {
    return bookings.map(booking => {
      let popConfirmText = "";

      if (booking.isOnboarded) {
        popConfirmText = "Are you sure you want to mark customer not boarded";
      } else {
        popConfirmText = `Are you sure you want to mark customer boarded`;

        if (booking.amountToBeCollected) {
          popConfirmText += `customer has cash collection of ${booking.amountToBeCollected} `;
        }
      }
      return popConfirmText;
    });
  };

  sendRefundMessage = async bookingBody => {
    try {
      let refundlink = await this.fetchRefundLink(bookingBody.refund[0]);

      if (refundlink) {
        let parts2 =
          bookingBody.refund[0].refundRule.categoryName.match(
            /[\s\S]{1,30}/g
          ) || [];
        if (parts2[1] === undefined) {
          parts2[1] = "";
          parts2[2] = "";
        }
        let vars = [
          bookingBody.name,
          bookingBody.refund[0].amount.toString(),
          moment(bookingBody.fromStationDate).format("lll"),
          parts2[0],
          parts2[1],
          parts2[2],
          refundlink
        ];
        const url = `/bus-tracker/sendRefundmessage`;

        const options = {
          method: "post",
          data: {
            bookingBody,
            vars
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
        } else {
          message.error("Message not sent");
        }
      }
    } catch (err) {
      message.error(err);
    }
  };
  fetchRefundLink = async refundBody => {
    console.log("razorPayTransactionId", refundBody.razorPayTransactionId);
    const url = `/bus-tracker/fetchRefundLink?razorPayTransactionId=${refundBody.razorPayTransactionId}`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      return response.short_url;
    }
    return false;
  };
  sendRefund = async (
    selectedCategory,
    subIssueId,
    issueId,
    categorys,
    issueComment
  ) => {
    try {
      const { selectedBookings } = this.state;
      let refundID = selectedCategory[0]._id;
      let refundClassification = selectedCategory[0].refundClassification;

      if (selectedBookings.length) {
        const url = `/bus-tracker/sendRefund`;

        const options = {
          method: "post",
          data: {
            subIssueId: subIssueId,
            issueId: issueId,
            bookingType: "ZINGBUS",
            refundRuleId: refundID,
            refundClassification: refundClassification
              ? refundClassification
              : "NORMAL",
            comment: issueComment,
            bookings: selectedBookings
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Refund Initiated");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };
  sendRefundviaPnr = async (
    selectedCategory,
    subIssueId,
    issueId,
    categorys,
    issueComment
  ) => {
    try {
      const { bookings } = this.state;
      let refundID = selectedCategory[0]._id;
      let refundClassification = selectedCategory[0].refundClassification;

      if (bookings) {
        const url = `/bus-tracker/sendRefund`;
        const options = {
          method: "post",
          data: {
            subIssueId: subIssueId,
            issueId: issueId,
            bookingType: "ZINGBUS",
            refundRuleId: refundID,
            refundClassification: refundClassification
              ? refundClassification
              : "NORMAL",
            comment: issueComment,
            bookings: bookings
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Refund Initiated");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };
  sendMessage = async (
    templateType,
    vars,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { bookings, result } = this.state;

      if (bookings.length) {
        // console.log("bookings.length", bookings.length);
        const url = `/bus-tracker/sendmessageandwhatsapp`;

        const options = {
          method: "post",
          data: {
            bookings: bookings.map(b => b.booking),
            templateType,
            result,
            vars,
            sendSMSMessage,
            sendWhatsAppMessage
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
          this.setState({ showResult: false });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };
  sendMessageNumber = async (
    templateType,
    vars,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { selectedBookings, result } = this.state;

      if (selectedBookings.length) {
        // console.log("bookings.length", selectedBookings.length);
        const url = `/bus-tracker/sendmessageandwhatsapp`;

        const options = {
          method: "post",
          data: {
            bookings: selectedBookings.map(b => b.booking),
            templateType,
            vars,
            sendSMSMessage,
            result,
            sendWhatsAppMessage
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
          this.setState({ selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  copyCustomerDetails = (
    bookingCode,
    mobileNo,
    seatsLabel,
    fromStation,
    fromCity,
    fromStationDate,
    toStation,
    toCity,
    trackingUrl,
    rc
  ) => {
    let busNumber = "";
    if (rc) {
      busNumber = rc.rc;
    } else {
      busNumber = "No Bus Assigned";
    }
    let time = moment(fromStationDate).format("lll");

    let text = `PNR :${bookingCode} \n Mobile Number: ${mobileNo} \n Seat Number: ${seatsLabel} \n From Station: ${fromStation}, ${fromCity}, ${time} \n To Station: ${toStation}, ${toCity} \n Tracking URL: ${trackingUrl} \n Bus Number: ${busNumber} `;
    // console.log("text", text);

    this.inputRef.current.value = text;
    this.inputRef.current.select();
    document.execCommand("copy");
    message.success("Customer Details copied to clipboard");
  };

  copyCustomerTrackingLink = customerTrackingLink => {
    this.inputRef.current.value = customerTrackingLink;
    this.inputRef.current.select();

    document.execCommand("copy");
    message.success("Customer Link copied to clipboard");
  };

  sendEmail = async (bookingCode, email) => {
    const url = `/bus-tracker/emailSent`;

    const options = {
      method: "post",
      data: {
        pnr: bookingCode,
        email: email
      }
    };
    // console.log("options-----", options);
    const response = await Fetch(url, options);

    // console.log("response", response);

    if (response) {
      message.success("Email Sent Successfully");
    }
  };

  downloadEmail = async customerpnr => {
    const url = `/bus-tracker/downloadEmail?pnr=${customerpnr}`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      message.success("Invoice downloaded");
    }
    const linkSource = response;
    const downloadLink = document.createElement("a");
    const fileName = `invoice-${customerpnr}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  handleSpecialCharacters = event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    this.setState({ bookingSearchValue: newCheck });
  };
  handleChangeInput = event => {
    const { value, maxLength } = event.target;
    const message = value.slice(0, maxLength);
    this.setState({ bookingSearchValue: message });
  };
  handleSwap = () => {
    const { fromCity, toCity } = this.state;

    this.setState({ fromCity: toCity, toCity: fromCity });
  };

  render() {
    const {
      showResult,
      editBooking,
      bookingSearchValue,
      editField,
      customerIssue,
      bookingToEdit,
      fromCity,
      toCity,
      selectKey,
      bookingLoader,
      tripLoader,
      tripFilter,
      selectedBookings,
      date,
      bookings
    } = this.state;
    const { cities } = this.props;

    // console.log(result);

    return (
      <>
        <div className="searchForm">
          {/* input tag is for copying customer link */}
          <input
            ref={this.inputRef}
            readOnly={true}
            style={{
              position: "absolute",
              left: "-9999px"
            }}
          />
          <div className="pnrSearch">
            <Input
              type="text"
              maxLength="20"
              placeholder="PNR / Mobile Number Search"
              style={{ width: 270, margin: "0 0.2rem" }}
              value={bookingSearchValue}
              onChange={this.handleSpecialCharacters}
            />

            <Button
              onClick={this.fetchBooking}
              loading={bookingLoader}
              type="primary"
            >
              Search
            </Button>
          </div>
          <div className="tripSearch">
            <div className="citySelect">
              <Select
                showSearch
                style={{ width: 150 }}
                value={fromCity}
                onChange={value => this.setState({ fromCity: value })}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cities.map(city => (
                  <Option value={city.name} key={city._id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
              <button className="swapButton" onClick={this.handleSwap}>
                <img src={swapIcon} alt="swapicon" />
              </button>
              <Select
                showSearch
                style={{ width: 150 }}
                value={toCity}
                onChange={value => this.setState({ toCity: value })}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cities.map(city => (
                  <Option value={city.name} key={city._id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="optionSelect">
              <Select
                style={{ width: "170px" }}
                value={tripFilter}
                onChange={value => this.setState({ tripFilter: value })}
              >
                <Option value="ONGOING">Ongoing</Option>
                <Option value="COMPLETED">Completed</Option>
                <Option value="ALL">All</Option>
              </Select>
              {tripFilter === "ALL" ||
              tripFilter === "COMPLETED" ||
              tripFilter === "ONGOING" ? (
                <DatePicker
                  style={{ width: 150 }}
                  value={date}
                  onChange={date => this.setState({ date })}
                />
              ) : null}
            </div>
            <div>
              <Button
                loading={tripLoader}
                onClick={this.fetchTrip}
                style={{ width: 100 }}
                type="primary"
              >
                Search
              </Button>
            </div>
          </div>
        </div>
        <hr />
        <Modal
          title="Search Result"
          visible={showResult}
          onCancel={() => this.setState({ showResult: false })}
          footer={null}
          width="80"
        >
          {selectKey === "1" ? (
            <div className="bookingListFooter">
              <div className="bookingListFooterItemOne">
                <SendMessage
                  selectedBookings={bookings}
                  onClick={this.sendMessage}
                  // selectedCount={selectedBookings.length}
                />
              </div>
              <div className="bookingListFooterItemOne">
                {" "}
                <SendRefund
                  issues={customerIssue}
                  isSearchForm="true"
                  selectedBookings={bookings}
                  onClick={this.sendRefundviaPnr}
                />
              </div>
            </div>
          ) : (
            <div className="bookingListFooter">
              <div className="bookingListFooterItemOne">
                <SendMessage
                  selectedBookings={selectedBookings}
                  onClick={this.sendMessageNumber}
                  // selectedCount={selectedBookings.length}
                />
              </div>
              <div className="bookingListFooterItemOne">
                <SendRefund
                  issues={customerIssue}
                  isSearchForm="true"
                  selectedBookings={selectedBookings}
                  onClick={this.sendRefund}
                />
              </div>
            </div>
          )}
          {selectKey === "1" ? (
            <Table
              columns={columns}
              dataSource={bookings}
              pagination={false}
              scroll={{ y: 300, x: "200%" }}
            />
          ) : (
            <Table
              columns={columns}
              dataSource={bookings}
              pagination={false}
              rowSelection={{
                type: "radio",
                columnWidth: "4%",
                onChange: (keys, booking) => {
                  this.setState({
                    selectedBookings: booking
                  });
                }
              }}
              scroll={{ y: 300, x: "200%" }}
            />
          )}
          {editBooking ? (
            <EditBooking
              onChange={obj => this.setState(obj)}
              editField={editField}
              booking={bookingToEdit}
              closeParentModal={() => this.setState({ showResult: false })}
            />
          ) : null}
        </Modal>
      </>
    );
  }
}

export default SearchForm;
