import React, { Component } from "react";
import { Modal, Table, message } from "antd";
import Fetch from "../../utils/fetch";
import moment from "moment";

const columns = [
  {
    title: "Purchase Date",
    dataIndex: "createdOn",
    key: "createdOn",
    render: ({ createdOn, isBold }) => {
      return (
        <div>
          <div style={{ fontWeight: isBold ? "bold" : "initial" }}>
            {createdOn}
          </div>
        </div>
      );
    }
  },
  {
    title: "Expires On",
    dataIndex: "expiresOn",
    key: "expiresOn",
    render: ({ expiresOn, isBold }) => {
      return (
        <div>
          <div style={{ fontWeight: isBold ? "bold" : "initial" }}>
            {expiresOn}
          </div>
        </div>
      );
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: ({ status, isBold }) => {
      return (
        <div>
          <div style={{ fontWeight: isBold ? "bold" : "initial" }}>
            {status}
          </div>
        </div>
      );
    }
  },
  {
    title: "Bookings Allowed",
    dataIndex: "noOfBookingValidFor",
    key: "noOfBookingValidFor",
    render: ({ noOfBookingValidFor, isBold }) => {
      return (
        <div>
          <div style={{ fontWeight: isBold ? "bold" : "initial" }}>
            {noOfBookingValidFor}
          </div>
        </div>
      );
    }
  },
  {
    title: "Cancellation Count",
    dataIndex: "totalCancellations",
    key: "totalCancellations",
    render: ({ totalCancellations, isBold }) => {
      return (
        <div>
          <div style={{ fontWeight: isBold ? "bold" : "initial" }}>
            {totalCancellations}
          </div>
        </div>
      );
    }
  },
  {
    title: "Bookings Done",
    dataIndex: "bookingsAgainstPassIds",
    key: "bookingsAgainstPassIds",
    render: ({ bookingsAgainstPassIds, isBold }) => {
      return (
        <div>
          <div style={{ fontWeight: isBold ? "bold" : "initial" }}>
            {bookingsAgainstPassIds}
          </div>
        </div>
      );
    }
  },
  {
    title: "Max Discount",
    dataIndex: "maxDiscount",
    key: "maxDiscount",
    render: ({ maxDiscount, isBold }) => {
      return (
        <div>
          <div style={{ fontWeight: isBold ? "bold" : "initial" }}>
            {maxDiscount}
          </div>
        </div>
      );
    }
  }
];
class EditBooking extends Component {
  state = {
    loading: false,
    details: []
  };

  async componentDidMount() {
    try {
      const { selectedBooking } = this.props;
      if (selectedBooking) {
        const booking = await this.fetchPrimeDetails(selectedBooking);
        this.setState({ details: booking, loading: true });
      } else {
        this.setState({ loading: true });
      }
    } catch (err) {
      message.error(err);
    }
  }

  fetchPrimeDetails = async selectedBooking => {
    try {
      let url = `/booking/primeDetails/?userId=${selectedBooking.userId}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        let formattedData = this.formatData(response);
        return formattedData;
      }
    } catch (err) {
      message.error(err);
    }
  };

  formatData = response => {
    let returnData = [];
    const { selectedBooking } = this.props;
    for (let i = 0; i < response.length; ++i) {
      let booking = response[i];
      let isBold = false;

      const selectedBookingId = selectedBooking.zingStorePassRewardId;
      let passIdsArray = booking.bookingsAgainstPassIds
        ? booking.bookingsAgainstPassIds
        : [];

      if (booking._id === selectedBookingId) {
        isBold = true;
      }
      let data = {
        createdOn: {
          createdOn: moment(booking.createdOn).format("lll"),
          isBold
        },
        expiresOn: {
          expiresOn: moment(booking.expiresOn).format("lll"),
          isBold
        },
        status: {
          status: booking.status,
          isBold
        },
        maxDiscount: {
          maxDiscount: booking.maxDiscount,
          isBold
        },
        noOfBookingValidFor: {
          noOfBookingValidFor: booking.noOfBookingValidFor,
          isBold
        },
        totalCancellations: {
          totalCancellations: booking.totalCancellations
            ? booking.totalCancellations
            : 0,
          isBold
        },
        bookingsAgainstPassIds: {
          bookingsAgainstPassIds: passIdsArray.length,
          isBold
        },
        maxDiscount: {
          maxDiscount: booking.maxDiscount,
          isBold
        }
      };
      returnData.push(data);
    }
    return returnData;
  };

  render() {
    const { details, loading } = this.state;
    return (
      <Modal
        centered
        title="Details"
        onCancel={this.props.onCancel}
        width={"100%"}
        visible={true}
        footer={null}
      >
        {!loading ? (
          <h1>......loading</h1>
        ) : (
          <Table
            dataSource={details}
            columns={columns}
            pagination={false}
            scroll={{ y: 400, x: "20%" }}
          />
        )}
      </Modal>
    );
  }
}

export default EditBooking;
