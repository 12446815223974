import React, { Component } from "react";
import OntripMap from "./onTripMap.js";
import ActiveMap from "./activeMap";
import CompletedMap from "./completedMap.js";
import AssignedMap from "./assignedMap.js";
import CancelledMap from "./cancelledBookingMap.js";

import moment from "moment";

import styles from "./map.module.scss";

class CustomerTrack extends Component {
  // Moengage.track_event("Invoke Chat", {
  //   "mobile" : "",
  //  });

  getComponentToRender = () => {
    const {
      booking,
      tripStatus,
      updateJourneyDetails,
      wayPoints,
      updateETA,
      checkLastTime,
      TrackingWorking,
      upcomingStation,
      isStationMarked,
      station,
      updateUpcomingStation
    } = this.props;
    if (booking.status == "CONFIRMED") {
      if (upcomingStation) {
        switch (tripStatus) {
          case "ONTRIP": {
            return (
              <OntripMap
                isStationMarked={isStationMarked}
                tripId={booking.tripId}
                lastPoint={this.props.lastlocation}
                isTrackingWorking={TrackingWorking}
                fromstation={booking.boardingPoint.address}
                dropPoint={booking.dropPoint.address}
                station={station}
                mapCenter={
                  booking.isOnboarded
                    ? this.props.lastlocation
                    : booking.boardingPoint
                }
                booking={booking}
                isOnboarded={booking.isOnboarded}
                path={wayPoints}
                updateJourneyDetails={updateJourneyDetails}
                checkLastTime={checkLastTime}
                updateETA={updateETA}
                onPassengerBoarded={this.props.onPassengerBoarded}
                upcomingStation={upcomingStation}
                updateUpcomingStation={updateUpcomingStation}
              />
            );
          }

          case "ACTIVE": {
            //bus is not attached
            return <ActiveMap fromstationTime={booking.boardingPoint.time} />;
          }

          case "ASSIGNED": {
            return <AssignedMap wayPoints={wayPoints} />;
          }

          case "COMPLETED": {
            return <CompletedMap />;
          }

          default: {
            return null;
          }
        }
      } else {
        return <AssignedMap wayPoints={wayPoints} isTrackingWorking={true} />;
      }
    } else {
      return (
        <CancelledMap
          wayPoints={wayPoints}
          isTrackingWorking={true}
          bookingStatus={booking.status}
        />
      );
    }
  };

  render() {
    return <>{this.getComponentToRender()}</>;
  }
}

export default CustomerTrack;
