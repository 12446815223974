import React, { Component } from "react";
import CurrentLocation from "../../../images/completedPin.svg";

const google = window.google;

class completedMap extends Component {
  state = {
    loading: true
  };

  directionsService = null;
  directionsRenderer = [];

  render() {
    return (
      <>
        <button
          onClick={this.moveToCurrentLocation}
          className="completedLocation"
        >
          <img src={CurrentLocation} />
          <p
            style={{
              fontSize: "15px",
              fontWeight: "500",
              fontFamily: "SFPROTEXT",
              color: "#292929"
            }}
          >
            Your Trip has been Completed. Thank You for riding with Zingbus.
          </p>
        </button>
      </>
    );
  }
}

export default completedMap;
