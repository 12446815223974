import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Track from "../../components/gdsTrack";

import { Card } from "antd";
import Fetch from "../../utils/fetch";
import moment from "moment";
import {
  getBrowserVisibilityProp,
  getBrowserVisibilityEvent
} from "../../utils/common";
import { gdsLastLocation, gdsSavedGPSData } from "../../network";

class TeamTrack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tripId: props.match.params.tripId,
      redirect: false,
      tripStatus: null,
      visible: true,
      lastLocation: {},
      savedLocation: []
    };
  }

  async componentDidMount() {
    await this.refreshData();

    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    const pageHiddenKey = getBrowserVisibilityProp();

    this.setState({ visible: document[pageHiddenKey] });
  }

  componentWillUnmount() {
    const visibilityChange = getBrowserVisibilityEvent();

    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }

  handleVisibilityChange = async () => {
    const pageHiddenKey = getBrowserVisibilityProp();
    document.title = document[pageHiddenKey] ? "hidden" : "yo";

    if (!this.state.visible && !document[pageHiddenKey]) {
      console.log("refreshing page on active");
      await this.refreshData();
    }
  };

  fetchBusRoute = async tripId => {
    try {
      const url = `/gds/trip/route/${tripId}`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (Array.isArray(response) && response.length > 0) {
        const trip = response[0];
        const isTripAssigned = trip.isTripAssigned;
        const tripStatus = trip.status;
        const tripDate = trip.startDate;

        if (isTripAssigned) {
          const busNumber = trip.busAssignmentDetails.busRc;
          const serviceName = trip.gdsOperatorService.name;
          return Promise.resolve({
            tripStatus,
            busNumber,
            serviceName,
            tripDate,
            isTripAssigned
          });
        } else {
          return Promise.resolve({
            tripStatus
          });
        }
      } else {
        throw new Error("No response");
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

  refreshData = async () => {
    try {
      console.log("refreshing data");
      const { tripId } = this.state;
      const {
        tripStatus,
        busNumber,
        serviceName,
        tripDate,
        isTripAssigned
      } = await this.fetchBusRoute(tripId);

      const lastlocationPromise = gdsLastLocation(tripId);

      const savedLocationPromise = gdsSavedGPSData(tripId);

      const [
        lastLocationPromiseResult,
        savedLocationPromiseResult
      ] = await Promise.allSettled([lastlocationPromise, savedLocationPromise]);

      let lastLocation = lastLocationPromiseResult.value || {};
      const savedLocation = savedLocationPromiseResult.value || [];

      if (!lastLocation.lat && savedLocation.length) {
        lastLocation = savedLocation[savedLocation.length - 1];
      }

      const busnumber = busNumber;
      const servicename = serviceName;
      const tripdate = tripDate;

      if (isTripAssigned) {
        const stateObj = {
          loading: false,
          busnumber,
          tripdate,
          servicename,
          savedLocation,
          isTripAssigned
        };

        if (lastLocation.lat) {
          stateObj.lastLocation = lastLocation;
        }

        this.setState(stateObj);
      } else {
        this.setState({
          loading: false,
          isTripAssigned,
          tripStatus
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      loading,
      tripId,
      busnumber,
      servicename,
      tripdate,
      lastLocation,
      wayPoints,
      redirect,
      savedLocation,
      isTripAssigned
    } = this.state;
    if (loading) {
      return <h1>...Loading</h1>;
    }

    if (!isTripAssigned) {
      return <h1>Sorry! Bus is not assigned</h1>;
    }

    if (redirect) {
      return <Redirect to={`/report/?tripId=${tripId}`} />;
    }

    return (
      <>
        <div className="site-card-border-less-wrapper">
          <Card
            title="Trip Details"
            bordered={true}
            style={{
              zIndex: 1,
              position: "absolute",
              fontSize: 200,
              right: "00",
              float: "right",
              margin: 10,
              width: "auto",
              padding: 0,
              height: "auto"
            }}
          >
            <p>Service Name: {servicename} </p>
            <p>Trip Date: {moment(tripdate).format("MMM Do")}</p>
            <p>Bus Number: {busnumber}</p>
          </Card>
        </div>

        <br></br>
        <Track
          tripId={tripId}
          lastPoint={lastLocation}
          path={wayPoints}
          savedLocation={savedLocation}
          refreshData={this.refreshData}
        />
      </>
    );
  }
}

export default TeamTrack;
