import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import WebHeader from "./components/webHeader";
import Home from "./pages/home";
import TeamTrack from "./pages/teamTrack";
import GdsTeamTrack from "./pages/gdsTeamTrack";
import Report from "./pages/report";
import RedbusReport from "./pages/redbusReport";
import SelfHelp from "./pages/selfHelp";
import SelfHelpGds from "./pages/selfHelpForGds";
import LiveView from "./pages/liveView";
import ErrorBoundary from "./components/errorBoundary";
import Login from "./pages/login";
import AccountDel from "./pages/account-delete";
import MarshalDel from "./pages/marshal-delete";

import AgreementForm from "./pages/agreementForm";
import policy from "./pages/cancellationPolicy";
import TrackTesting from "./pages/trackTesting";
import WaitingLounge from "./pages/waitingLounge";
import ProtectedComponentWrapper from "./components/HOC/protectedComponent";
import PNRView from "./pages/pnr";
import "./App.css";

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/delete" component={AccountDel} />
      <Route exact path="/marshal-delete" component={MarshalDel} />

      <Route
        exact
        path="/customerTracking/:bookingId"
        render={props => (
          <ErrorBoundary>
            <SelfHelp {...props} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/customerTrackingGds/:bookingCode"
        component={SelfHelpGds}
      />

      {/* <Route component={WebHeader} /> */}
    </Switch>
    <Switch>
      <Route exact path="/" component={ProtectedComponentWrapper(Home)} />
      <Route
        exact
        path="/track/live-view"
        component={ProtectedComponentWrapper(LiveView)}
      />
      <Route
        exact
        path="/gdstrack/:tripId"
        component={ProtectedComponentWrapper(GdsTeamTrack)}
      />
      <Route
        exact
        path="/track/:tripId"
        component={ProtectedComponentWrapper(TeamTrack)}
      />
      <Route
        exact
        path="/report/"
        component={ProtectedComponentWrapper(Report)}
      />
      <Route
        exact
        path="/redbus/tracking/report/"
        component={ProtectedComponentWrapper(RedbusReport)}
      />
      <Route exact path="/marshal-agreement/" component={AgreementForm} />
      <Route exact path="/cancellationPolicy/:bookingId" component={policy} />

      <Route
        exact
        path="/track/test/:tripId"
        component={ProtectedComponentWrapper(TrackTesting)}
      />
      <Route
        exact
        path="/pnr/:pnr"
        component={ProtectedComponentWrapper(PNRView)}
      />
      <Route
        exact
        path="/track/station/:stationId"
        component={ProtectedComponentWrapper(WaitingLounge)}
      />
    </Switch>
  </Router>
);

export default App;
