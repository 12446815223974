import React, { Component } from "react";
import {
  Button,
  Input,
  DatePicker,
  Select,
  Modal,
  AutoComplete,
  message,
  Checkbox
} from "antd";
import moment from "moment";

import Fetch from "../../utils/fetch";

const { TextArea } = Input;
const { Option } = Select;

class InitiateComplain extends Component {
  state = {
    showInitiateComplain: false,
    Comment: "",
    date: moment(),
    serviceNames: [],
    busNumberSearch: [],
    stationName: [],
    tripServiceNames: [],
    selectedBookings: [],
    agentName: [],
    busDriverName: [],
    busHost: [],
    loader: false,
    enableOutBound: false
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  getServiceName = async () => {
    try {
      const url = `/bus-tracker/serviceNameEs`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getMandatoryFields = async id => {
    this.setState({ loader: true });
    let { complains, serviceNames, selectedBookings } = this.state;
    let getComplain = complains.filter(complain => complain._id == id);
    let tat;
    let getMandatoryFields = getComplain[0].entities;
    if ((getComplain[0].subIssue.issue.type = "CREWTICKETTRACER")) {
      tat = getComplain[0].tat;
    }
    if (
      getMandatoryFields.includes("bookingId") &&
      getMandatoryFields.includes("serviceId")
    ) {
      getMandatoryFields = getMandatoryFields.filter(
        item => item !== "serviceId"
      );
    }
    if (getMandatoryFields.includes("serviceId") && selectedBookings) {
      const serviceId = selectedBookings[0].booking.trip.serviceId;
      this.setState({ serviceId });
      getMandatoryFields = getMandatoryFields.filter(
        item => item !== "serviceId"
      );
    }
    if (serviceNames.length == 0) {
      serviceNames = await this.getServiceName();
      this.setState({ serviceNames });
    }

    this.setState({
      loader: false,
      mandatoryFields: getMandatoryFields,
      complaintCategoryId: id,
      tat
    });
  };

  toggleMessageOption = () => {
    const { complains, selectedBookings } = this.props;
    if (!complains) {
      return message.error("Please wait");
    }

    this.setState({
      loading: false,
      selectedBookings: selectedBookings,
      preSelectedbookingId: selectedBookings
        ? selectedBookings[0].bookingCode
        : null,
      complains: complains,
      showInitiateComplain: true
    });
  };

  handleSpecialCharacters = async event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    this.setState({ bookingId: newCheck });
  };

  onDateChange = async tripdate => {
    const tripServiceNames = await this.getTrips(tripdate);
    this.setState({ tripServiceNames, tripdate, tripId: "" });
  };

  handleSendClick = () => {
    const { onClick } = this.props;
    let Bodyobj = {
      complaintCategoryId: this.state.complaintCategoryId,
      comment: this.state.Comment < 1 ? undefined : this.state.Comment,
      bookingId: this.state.preSelectedbookingId
        ? this.state.preSelectedbookingId
        : this.state.bookingId,
      enableOutBound: this.state.enableOutBound,
      tat: this.state.tat
    };
    let { mandatoryFields } = this.state;
    mandatoryFields.map(complain => {
      if (this.state[complain]) {
        Bodyobj = {
          ...Bodyobj,
          [complain]: this.state[complain]
        };
      }
    });

    onClick(Bodyobj);

    mandatoryFields.map(complain => {
      this.setState({
        [complain]: null
      });
    });
    this.setState({
      showInitiateComplain: false,
      mandatoryFields: "",
      complaintCategoryId: ""
    });
  };

  setValue = value => {
    const { complains } = this.state;
    let id = complains.filter(complain => complain.name === value);
    if (id.length) {
      this.getMandatoryFields(id[0]._id);
    }
  };

  render() {
    const {
      showInitiateComplain,
      mandatoryFields,
      date,
      complains,
      loader,
      bookingId,
      preSelectedbookingId,
      serviceNames,
      enableOutBound
    } = this.state;
    const { isloading } = this.props;
    return (
      <div>
        {showInitiateComplain ? (
          <Modal
            centered
            width={1500}
            title="Create complain"
            footer={null}
            visible={true}
            onCancel={() =>
              this.setState({
                showInitiateComplain: false,
                mandatoryFields: "",
                complaintCategoryId: ""
              })
            }
          >
            <div
              className="row"
              style={{
                margin: "10px"
              }}
            >
              <div className="col-4">Select Complain Name</div>
              <div className="col-6 text-left">
                <AutoComplete
                  style={{
                    width: "100%"
                  }}
                  dataSource={complains.map(complain => complain.name)}
                  onChange={value => this.setValue(value)}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div>
            </div>
            {Array.isArray(mandatoryFields) ? (
              serviceNames.length > 0 ? (
                mandatoryFields.map(fields => (
                  <div
                    loading={loader}
                    className="row"
                    style={{
                      margin: "10px"
                    }}
                  >
                    <div className="col-4">
                      {fields == "serviceId"
                        ? "Service Name"
                        : fields == "bookingId"
                        ? "PNR"
                        : fields == "date"
                        ? "Service Date"
                        : null}{" "}
                    </div>
                    <div className="col-6 text-left">
                      {" "}
                      {fields == "serviceId" ? (
                        <AutoComplete
                          style={{
                            width: "100%"
                          }}
                          dataSource={serviceNames.map(service => service.name)}
                          onChange={value => {
                            let id = serviceNames.filter(
                              service => service.name === value
                            );
                            if (id.length) {
                              this.setState({
                                [fields]: id[0]._id
                              });
                            } else {
                              this.setState({
                                [fields]: null
                              });
                            }
                          }}
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      ) : fields == "bookingId" ? (
                        <TextArea
                          className="mb-1"
                          type="text"
                          disabled={preSelectedbookingId ? true : false}
                          value={
                            preSelectedbookingId
                              ? preSelectedbookingId
                              : bookingId
                          }
                          maxLength="20"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          style={{ width: "100%" }}
                          onChange={this.handleSpecialCharacters}
                        />
                      ) : fields == "date" ? (
                        <DatePicker
                          style={{ width: 150 }}
                          value={date}
                          onChange={date => this.setState({ date })}
                        />
                      ) : null}{" "}
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-6 text-left">
                  Please wait loading data........
                </div>
              )
            ) : null}
            {Array.isArray(mandatoryFields) && serviceNames.length > 0 ? (
              <>
                <div
                  className="row"
                  style={{
                    margin: "10px"
                  }}
                >
                  <div className="col-4">Comments</div>
                  <div className="col-6 text-left">
                    <TextArea
                      className="mb-1"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      style={{ width: "100%" }}
                      onChange={e =>
                        this.handleChange("Comment", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    margin: "10px"
                  }}
                >
                  <div className="col-4"></div>
                  <div className="col-6 text-left">
                    <Checkbox
                      value={enableOutBound}
                      onChange={e =>
                        this.setState({
                          enableOutBound: e.target.checked
                        })
                      }
                    >
                      Enable outbound Call{" "}
                    </Checkbox>
                  </div>
                </div>

                <Button
                  type="primary"
                  onClick={this.handleSendClick}
                  style={{ margin: "1% 0" }}
                >
                  Confirm Issue
                </Button>
              </>
            ) : null}
          </Modal>
        ) : (
          <Button
            style={{ width: "150px" }}
            loading={isloading}
            onClick={this.toggleMessageOption}
          >
            Initiate complain
          </Button>
        )}
      </div>
    );
  }
}

export default InitiateComplain;
