import React, { Component } from "react";
import { Button, Input, Select, Modal, message, Checkbox } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const columns = [
  {
    title: "Comments",
    dataIndex: "comment",
    key: "comment",
    width: "2%"
  }
];

class SendSelfHelpMessage extends Component {
  state = {
    showModalOption: false,
    comment: "",
    timeInputhrs: 0,
    timeInputMins: 20,
    setExpiryTime: true,
    selectedBookings: []
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleSendClick = () => {
    let { comment, setExpiryTime, timeInputhrs, timeInputMins } = this.state;
    const { onClick } = this.props;
    let time;
    if (setExpiryTime) {
      timeInputhrs = parseInt(timeInputhrs);
      timeInputMins = parseInt(timeInputMins);
      if (timeInputhrs > 48) {
        message.error("hrs cannot be greater than 48");
        this.setState({
          timeInputhrs: 0,
          timeInputMins: 20
        });
      } else if (timeInputMins > 60) {
        message.error("mins cannot be greater than 60");
        this.setState({
          timeInputhrs: 0,
          timeInputMins: 20
        });
      } else {
        time =
          new Date().getTime() +
          timeInputMins * 60 * 1000 +
          timeInputhrs * 60 * 60 * 1000;

        onClick(comment, time);
        this.setState({
          showModalOption: false,
          comment: "",
          timeInputhrs: 0,
          timeInputMins: 20,
          time: ""
        });
      }
    } else {
      onClick(comment);
      this.setState({
        showModalOption: false,
        comment: "",
        timeInputhrs: 0,
        timeInputMins: 20,
        time: ""
      });
    }
  };

  onCheckboxClicked = e => {
    this.setState({ setExpiryTime: e.target.checked });
  };

  toggleMessageOption = () => {
    let { selectedBookings } = this.props;
    const selectedPublishBookings = [];

    const { showModalOption } = this.state;

    if (!showModalOption && !selectedBookings) {
      return message.error("Please select booking or station");
    }

    for (let i = 0; i < selectedBookings.length; ++i) {
      const booking = selectedBookings[i];
      if (booking.status === "CONFIRMED") {
        const bookingObj = {
          key: booking._id
        };
        selectedPublishBookings.push(bookingObj);
      }
    }
    this.setState({
      loading: false,
      selectedPublishBookings
    });

    this.setState({
      showModalOption: !showModalOption,
      selectedPublishBookings
    });
  };
  handleCancel = () => {
    this.setState({
      showModalOption: false,
      comment: ""
    });
  };

  render() {
    const {
      showModalOption,
      setExpiryTime,
      timeInputhrs,
      timeInputMins
    } = this.state;
    return (
      <div>
        {showModalOption ? (
          <Modal
            centered
            title="Publish Self-help Note"
            width={1000}
            footer={[
              <div style={{ transform: "translate(-43%, 0%)" }}>
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleSendClick}
                >
                  Publish
                </Button>
                ,
                <Button key="back" onClick={this.handleCancel}>
                  Return
                </Button>
              </div>
            ]}
            visible={true}
            onCancel={() =>
              this.setState({
                showModalOption: false,
                comment: ""
              })
            }
          >
            <div className="row">
              <div className="col-4">comment</div>
              <div className="col-8 text-left">
                <TextArea
                  className="mb-1"
                  autoSize={{ minRows: 1, maxRows: 2 }}
                  maxLength={100}
                  placeholder="max Character is 100"
                  style={{ width: "100%" }}
                  onChange={e => this.handleChange("comment", e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-4"></div>
              <div className="col-8 text-left">
                <Checkbox
                  checked={setExpiryTime}
                  onChange={this.onCheckboxClicked}
                >
                  expiry time
                </Checkbox>
              </div>
            </div>
            {setExpiryTime ? (
              <div className="row">
                <div className="col-4">message expiry time</div>
                <div className="col-8 text-left">
                  <TextArea
                    type={"number"}
                    maxLength={2}
                    rows={1}
                    value={timeInputhrs}
                    style={{ width: 70 }}
                    onChange={e =>
                      this.handleChange("timeInputhrs", e.target.value)
                    }
                  />{" "}
                  :{" "}
                  <TextArea
                    type={"number"}
                    maxLength={2}
                    rows={1}
                    value={timeInputMins}
                    style={{ width: 70 }}
                    onChange={e =>
                      this.handleChange("timeInputMins", e.target.value)
                    }
                  />{" "}
                  hrs
                </div>
              </div>
            ) : null}
          </Modal>
        ) : (
          <Button
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={this.toggleMessageOption}
          >
            Publish Self-Help Note
          </Button>
        )}
      </div>
    );
  }
}

export default SendSelfHelpMessage;
