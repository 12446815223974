import React, { Component, useState } from "react";
import moment from "moment";
import styles from "./marketing.module.css";
import { b2cURL, mixPanelToken, isStage } from "../../config";
import { analytics } from "../../utils/analytics";
import { googleLayerAnalytics } from "../../utils/googleDataLayer";
import mixpanel from "mixpanel-browser";
const MarketingCard = props => {
  const { data, booking } = props;

  const [showVideo, toggleVideo] = useState(false);

  const onPlayerReady = event => {
    event.target.playVideo();
  };

  const onPlayerStateChange = () => {};

  const handleClick = data => {
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Promotion Banner Clicked", {
      promotion_description: data.description,
      mobile_no: booking.mobileNo,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    googleLayerAnalytics("view_promotion", {
      promotion_description: data.description,
      mobile_no: booking.mobileNo,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    switch (data.linkType) {
      case "HYPERLINK": {
        if (data.hyperlink) {
          if (data.hyperlink.includes("youtube")) {
            toggleVideo(true);

            const url = new URL(data.hyperlink);
            const videoIdIndex = url.pathname.split("/").indexOf("embed") + 1;
            const videoId = url.pathname.split("/")[videoIdIndex];

            new window.YT.Player(`player-${data._id}`, {
              height: "100%",
              width: "100%",
              videoId,
              events: {
                onReady: onPlayerReady,
                onStateChange: onPlayerStateChange
              }
            });
          } else {
            const url = new URL(data.hyperlink);
            window.open(url, "_blank");
          }
        }
        break;
      }

      case "SEARCH": {
        const url = `${b2cURL}/bus-tickets/${data.fromCity.name}-to-${
          data.toCity.name
        }-bus-route?trip=1&fromCity=${data.fromCity.name}&toCity=${
          data.toCity.name
        }&date=${moment().format("YYYY-MM-DD")}`;

        window.open(url, "_blank");
        //www.zingbus.com/bus-tickets/Gorakhpur-to-Delhi-bus-route?trip=1&fromCity=Gorakhpur&toCity=Delhi&date=2021-12-30
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <div
      className={styles.marketingCard}
      onClick={() => {
        handleClick(data);
        analytics("Campaign Click", {
          mobile: booking.mobileNo
        });
      }}
    >
      <div className={styles.contentContainer}>
        <div className={showVideo ? "" : styles.hideYoutubeIframe}>
          <div id={`player-${data._id}`} />
        </div>
        {!showVideo ? (
          data.assetType === "IMAGE" ? (
            <img src={data.link} />
          ) : (
            <iframe src={data.link} />
          )
        ) : null}
      </div>
    </div>
  );
};

class MarketingBar extends Component {
  render() {
    const { data, booking } = this.props;

    const sortedMarketingCards = data.sort((a, b) => a.priority - b.priority);

    return (
      <div className={styles.marketingBar}>
        {sortedMarketingCards.map(d => (
          <MarketingCard data={d} booking={booking} key={d._id} />
        ))}
      </div>
    );
  }
}

export default MarketingBar;
