import React, { Component } from "react";
import CurrentLocation from "../../../images/activePin.svg";
import moment from "moment";

const google = window.google;

class assignedMap extends Component {
  state = {
    loading: true
  };

  render() {
    const { fromstationTime } = this.props;
    const timeActive = fromstationTime - 4 * 60 * 60 * 1000;
    const dateFormat = "MMM DD hh:mm A";

    return (
      <>
        <button
          onClick={this.moveToCurrentLocation}
          className="assignedLocation"
        >
          <img src={CurrentLocation} />
          <p
            style={{
              marginTop: "9px",
              fontSize: "15px",
              fontWeight: "500",
              fontFamily: "SFPROTEXT",
              color: "#292929"
            }}
          >
            Your Tracking link will be active after{" "}
            {moment(timeActive).format(dateFormat)}.
          </p>
        </button>
      </>
    );
  }
}

export default assignedMap;
