import React, { Component } from "react";
import moment from "moment";

import { Button, DatePicker, Select, message, AutoComplete, Input } from "antd";
import Fetch from "../../utils/fetch";
import { ThunderboltFilled } from "@ant-design/icons";
import { gdsLoungeName } from "../../config";

const { Option } = Select;

class SearchForm extends Component {
  state = {
    date: moment()
  };

  showResult = async () => {
    const { date } = this.state;
    this.props.showLoader();

    let url = `/gds/booking/getLoungeBookings/`;
    if (date) {
      url += `?date=${moment(date).format("YYYY-MM-DD")}`;
    }

    const options = {
      method: "get"
    };
    const response = await Fetch(url, options);
    if (response && Array.isArray) {
      this.props.onSearch(response);
    } else {
      this.props.onSearch(null);
    }
  };

  showResultPnrSearch = async () => {
    const { bookingSearchValue } = this.state;
    this.props.showLoader();

    let url = `/gds/booking/getLoungeBookings/`;

    if (bookingSearchValue) {
      url += `?bookingCode=${bookingSearchValue}`;
    } else {
      message.error("Please enter PNR");
      return;
    }
    const options = {
      method: "get"
    };
    const response = await Fetch(url, options);
    if (response && Array.isArray) {
      this.props.onSearch(response);
    } else {
      this.props.onSearch(null);
    }
  };

  setValue = async value => {
    const { date } = this.state;

    let url = `/gds/booking/getLoungeBookings/`;

    if (value) {
      url += `?loungeId=${value}`;
    }
    if (date) {
      url += `&date=${moment(date).format("YYYY-MM-DD")}`;
    }
    const options = {
      method: "get"
    };
    const response = await Fetch(url, options);
    if (response && Array.isArray) {
      this.props.onSearch(response);
    } else {
      this.props.onSearch(null);
    }
  };

  handleSpecialCharacters = event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    this.setState({ bookingSearchValue: newCheck });
  };

  render() {
    const { date, bookingSearchValue } = this.state;
    return (
      <>
        <div className="callTrigger">
          <div className="complainSelector">
            <Select
              style={{ width: "100%" }}
              onChange={value => this.setValue(value)}
            >
              {gdsLoungeName.map(obj => (
                <Option value={obj._id} label={obj.value}>
                  <div>{obj.value}</div>
                </Option>
              ))}
            </Select>
          </div>
          <div className="pnrSearchOutbound">
            <Input
              type="text"
              maxLength="20"
              placeholder="PNR Search"
              style={{ width: 270, margin: "0 0.2rem" }}
              value={bookingSearchValue}
              onChange={this.handleSpecialCharacters}
            />

            <Button onClick={this.showResultPnrSearch} type="primary">
              Search
            </Button>
          </div>

          <DatePicker
            style={{ width: 150 }}
            value={date}
            onChange={date => this.setState({ date })}
          />

          <Button type="primary" onClick={this.showResult}>
            Search / Refresh
          </Button>
        </div>
        <hr />
      </>
    );
  }
}

export default SearchForm;
