import React from "react";
import Header from "../header";
import Spinner from "../spinner";
import styles from "./appRedirect.module.scss";
import happyImage from "../../images/happy.svg";
import { socketURL } from "../../config";

const redirect = booking => {
  const bookingId = booking.id;
  const clientURL = socketURL;

  const url = `https://zingbusbtoc.page.link/?link=${clientURL}customerTracking/${bookingId}&apn=com.zingbusbtoc.zingbus&afl=https://play.google.com/store/apps/details?id=com.zingbusbtoc.zingbus&hl=en&ofl=${clientURL}customerTracking/${bookingId}&ifl=${clientURL}customerTracking/${bookingId}`;

  console.log("redirectURL", url);

  window.location.replace(url);
};

const AppRedirectScreen = props => {
  const { booking } = props;

  setTimeout(() => redirect(booking), 5000);

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={happyImage} alt="happy" />
        </div>
        <div className={styles.textContainer}>
          <p>Thank you for submiting your response.</p>
          <p>Together we will ensure safety against Coronavirus.</p>
          <p>
            Redirecting you to our app for better communication related to your
            journey.
          </p>
          <Spinner type="secondary" />
        </div>
      </div>
    </>
  );
};

export default AppRedirectScreen;
