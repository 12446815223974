import React from "react";
import { Modal, Table } from "antd";

const columns = [
  {
    title: "Category",
    dataIndex: "category",
    key: "category"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status"
  }
];

const formatDataForTable = details => {
  const dataSource = [];

  dataSource.push({
    category: "SafetyKit",
    recieved: details.noOfSafetyKitReceived,
    status: details.safetyKitCountRemaining
  });

  dataSource.push({
    category: "Bus Sanitized",
    status: `${details.isBusSanitized}`
  });

  return dataSource;
};

const InventoryDetails = ({ details, onCancel }) => {
  return (
    <Modal
      title="Inverntory Details"
      visible={true}
      onCancel={onCancel}
      footer={null}
    >
      <Table
        columns={columns}
        dataSource={formatDataForTable(details)}
        pagination={false}
      />
      <br />
    </Modal>
  );
};

export default InventoryDetails;
