import React, { Component } from "react";
import { Table, Modal, message, Tag } from "antd";
import Fetch from "../../utils/fetch";

const priorityOrder = { OPEN: 1, REJECTED: 2, CLOSED: 3 };

const columns = [
  {
    title: "Trip Name",
    dataIndex: "tripName",
    key: "tripName",
    width: "20%",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount"
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "20%",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Station Name",
    dataIndex: "station",
    key: "station",
    width: "20%",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    key: "createdBy"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: status => {
      let tagColor = "";

      switch (status) {
        case "OPEN": {
          tagColor = "blue";
          break;
        }

        case "REJECTED": {
          tagColor = "red";
          break;
        }

        case "CLOSED": {
          tagColor = "green";
        }
      }

      return <Tag color={tagColor}>{status}</Tag>;
    }
  }
];

class TaskList extends Component {
  state = {
    tasks: []
  };

  async componentDidMount() {
    try {
      const tasks = await this.fetchTask();

      this.setState({ tasks });
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  }

  fetchTask = async () => {
    try {
      const { tripId } = this.props;
      const url = `/bus-tracker/task/${tripId}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        const tasks = response.map(task => {
          return {
            tripName: task.trip.service.name,
            type: task.type,
            amount: task.amount,
            description: task.description,
            station: task.station ? task.station.name : "",
            createdBy: `${task.createdByUser.name}, ${task.createdByUser.mobileNo}`,
            status: task.status,
            priority: priorityOrder[task.status]
          };
        });

        return tasks.sort((a, b) => a.priority - b.priority);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  render() {
    const { tasks } = this.state;

    return (
      <Modal
        title="Task List"
        visible={true}
        onCancel={this.props.onCancel}
        footer={null}
        width={"90%"}
      >
        <Table columns={columns} dataSource={tasks} pagination={false} />
      </Modal>
    );
  }
}

export default TaskList;
