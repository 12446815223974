import React, { useState } from "react";
import cx from "classnames";
import { getHoursAndMinFromMillis, getWayPoints } from "../../utils/common";
import styles from "./header.module.scss";
import { isStage } from "../../config";

const getSeatBooked = tripChart => {
  let seatBooked = "";
  tripChart = tripChart.filter(obj => obj.status == "CONFIRMED");
  if (tripChart.length > 0) {
    for (let i = 0; i < tripChart.length; ++i) {
      const { seatLabel, status } = tripChart[i];

      if (i !== 0) {
        seatBooked += ",";
      }
      if (status === "CONFIRMED") {
        seatBooked += seatLabel;
      }
    }
  }
  return seatBooked;
};
const openPlayStore = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") !== -1;
  if (isAndroid) {
    window.open(
      `https://play.google.com/store/apps/details?id=com.zingbusbtoc.zingbus`
    );
  } else {
    window.open(
      `https://apps.apple.com/in/app/zingbus-book-bus-ticket-online/id1600049529`
    );
  }
};

const changeBooking = id => {
  let url = isStage
    ? `https://testtracker.zingmobility.com/customerTracking/${id}`
    : `https://tracer.zingbus.com/customerTracking/${id}`;

  window.location.replace(url);
};

const Header = props => {
  const { booking, isAndroid, updateNote, SOS } = props;
  if (
    booking.showselfHelpComment &&
    booking.selfHelpCommentRelayTime &&
    booking.selfHelpCommentRelayTime - Date.now() > 0
  ) {
    setTimeout(
      () => updateNote(booking),
      booking.selfHelpCommentRelayTime - Date.now()
    );
  } else if (
    booking.showselfHelpComment &&
    booking.selfHelpCommentRelayTime &&
    booking.selfHelpCommentRelayTime - Date.now() < 0
  ) {
    updateNote(booking);
  }

  let toCities, fromCities;
  if (booking.connectingLegType === "SECONDLEG") {
    toCities = `${booking.connectingBooking.fromCity.name} to ${booking.connectingBooking.toCity.name}`;
    fromCities = `${booking.boardingPoint.city} to ${booking.dropPoint.city}`;
  }
  if (booking.connectingLegType === "FIRSTLEG") {
    fromCities = `${booking.connectingBooking.fromCity.name} to ${booking.connectingBooking.toCity.name}`;
    toCities = `${booking.boardingPoint.city} to ${booking.dropPoint.city}`;
  }

  let showShow = booking.dropPoint.time + 6 * 60 * 60 * 1000 > Date.now();
  return (
    <div id="header" className={cx(styles.header, "pb-2")}>
      <div className={styles.heading}>
        <span>Track Your Bus</span>
        {showShow ? (
          <div
            onClick={() => (booking.isSOSAlertInserted ? null : SOS())}
            className={
              booking.isSOSAlertInserted ? styles.sosInitiated : styles.sos
            }
          >
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/test/selfhelp/SOS.svg"
              alt="bell"
            />
            <span>{booking.isSOSAlertInserted ? "SOS Initiated" : "SOS"}</span>
          </div>
        ) : null}
      </div>
      {booking.connectingBookingCode ? (
        <div className={styles.toggle}>
          <button
            onClick={() =>
              booking.connectingLegType == "SECONDLEG"
                ? changeBooking(booking.connectingBooking._id)
                : null
            }
            className={
              booking.connectingLegType == "FIRSTLEG"
                ? styles.isSelected
                : styles.isNotSelected
            }
          >
            {toCities}
          </button>
          <button
            onClick={() =>
              booking.connectingLegType == "FIRSTLEG"
                ? changeBooking(booking.connectingBooking._id)
                : null
            }
            className={
              booking.connectingLegType == "SECONDLEG"
                ? styles.isSelected
                : styles.isNotSelected
            }
          >
            {fromCities}
          </button>
        </div>
      ) : null}

      <ul style={{ paddingLeft: "0", listStyle: "none", display: "grid" }}>
        <li className="row">
          <div
            className="col-6 border-right"
            style={{
              fontSize: "13px",
              fontWeight: "600",
              fontFamily: "SFPROTEXT"
            }}
          >
            PNR: {booking.bookingCode}
          </div>
          {booking.tripChart ? (
            <div
              className="col-6"
              style={{
                fontSize: "13px",
                fontWeight: "600",
                fontFamily: "SFPROTEXT"
              }}
            >
              {booking.tripChart.length}{" "}
              {booking.tripChart.length > 1 ? "Seats" : "Seat"}{" "}
              {`(${getSeatBooked(booking.tripChart)})`}
            </div>
          ) : null}
        </li>
        {booking.showselfHelpComment ? (
          <li className="row">
            <div
              className="col-12"
              style={{
                padding: "0px"
              }}
            >
              <div
                style={{
                  padding: "0px 15px",
                  minWidth: "40px",
                  display: "inline-block"
                }}
              >
                <p
                  style={{
                    padding: "0px 10px",
                    background: "#6270F0",
                    borderRadius: "20px",
                    fontSize: "12px",
                    color: "white",
                    fontWeight: "500",
                    fontFamily: "SFPROTEXT"
                  }}
                >
                  {booking.selfHelpComment}
                </p>
              </div>
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default Header;

{
  /* <div>
        {`${title}`}
        <br />
        {`${nextStation} in `}
        {getHoursAndMinFromMillis(etaTime)}
      </div> */
}
