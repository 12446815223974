import React, { Component } from "react";
import Fetch from "../../utils/fetch";

class AgreementForm extends Component {
  state = {
    isAgreed: true,
    loading: true,
    userId: this.props.match.params.tripId
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const htmlData = await this.fetchDate();
      const htmlPage = htmlData[0].text;
      this.setState({
        htmlData: htmlPage,
        loading: false
      });
    } catch (err) {
      console.log(err);
    }
  }
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  onChange = e => {
    this.setState({
      isAgreed: e.target.checked
    });
  };

  fetchDate = async () => {
    const url = `/bus-tracker/agreementForm`;
    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);
    return response;
  };
  render() {
    const { loading, htmlData } = this.state;
    if (loading) {
      return "Loading";
    } else {
      return (
        <div
          style={{ padding: "2%" }}
          dangerouslySetInnerHTML={{ __html: htmlData }}
        />
      );
    }
  }
}

export default AgreementForm;
