import React, { Component } from "react";
import LiveViewMap from "../../components/liveViewMap";
import Fetch from "../../utils/fetch";

class LiveView extends Component {
  state = {
    loading: true,
    trips: []
  };

  async componentDidMount() {
    try {
      const trips = await this.fetchOntrip();
      this.setState({ loading: false, trips });
    } catch (err) {
      console.log(err);
    }
  }

  fetchOntrip = async () => {
    try {
      const url = `/bus-tracker/allQLTrips`;
      const options = {
        method: "post"
      };

      const response = await Fetch(url, options);

      if (response) {
        const trips = response.filter(trip => trip.status === "ONTRIP");

        return trips;
      } else {
        throw new Error("No response");
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  onTripCountChange = async () => {
    try {
      const trips = await this.fetchOntrip();
      this.setState({ loading: false, trips });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { trips, loading } = this.state;

    return (
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <LiveViewMap
              trips={trips}
              onTripCountChange={this.onTripCountChange}
            />{" "}
          </>
        )}
      </div>
    );
  }
}

export default LiveView;
