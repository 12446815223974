import React, { Component, useState, useEffect } from "react";
import { Button, message } from "antd";
import whatsAppIcon from "../../../images/whatsapp_icon.svg";
import closeIcon from "../../../images/closeModal.svg";
import Fetch from "../../../utils/fetch";
import mixpanel from "mixpanel-browser";
import moment from "moment";

import { mixPanelToken, isStage } from "../../../config";

class Otpinput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      disable: true,
      showError: false,
      timer: 30
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.tick = setInterval(this.onTick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.tick);
  }

  onTick = () => {
    if (this.state.timer == 0) {
      clearInterval(this.tick);
    } else {
      this.setState({ timer: this.state.timer - 1 });
    }
  };

  handleChange(value1, event) {
    this.setState({ [value1]: event.target.value });
  }

  handleSubmit(event) {
    const data = new FormData(event.target);
    event.preventDefault();
  }

  inputfocus = elmnt => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      let seletectedCol = elmnt.target.tabIndex;
      let otpVal = `otp${seletectedCol}`;
      this.setState({ [otpVal]: "" });
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else if (
      elmnt.key === "0" ||
      elmnt.key === "1" ||
      elmnt.key === "2" ||
      elmnt.key === "3" ||
      elmnt.key === "4" ||
      elmnt.key === "5" ||
      elmnt.key === "6" ||
      elmnt.key === "7" ||
      elmnt.key === "8" ||
      elmnt.key === "9"
    ) {
      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex - 1;
      elmnt.target.form.elements[next].focus();
    }
  };

  verifyOTP = async () => {
    if (
      this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 ==
      0
    ) {
      message.error("Please enter OTP");
      return;
    }
    const { openModal, mobileNo, type, booking } = this.props;
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Confirm Button Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    const url = `/bus-tracker/verifySelfOtp`;
    let otp =
      this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4;
    const options = {
      method: "post",
      data: {
        otp,
        type,
        bookingId: booking.id
      }
    };

    const response = await Fetch(url, options);

    if (response == "ok") {
      message.success("OTP is verified");
      openModal(true);
    } else {
      // openModal(false);
      this.setState({ showError: true });
    }
  };

  resendOTP = () => {
    this.setState({ timer: 30 });
    this.tick = setInterval(this.onTick, 1000);
    const { resend } = this.props;
    resend();
  };

  close = () => {
    const { closeWindow } = this.props;
    closeWindow();
  };

  render() {
    const { otp1, otp2, otp3, otp4, timer } = this.state;
    const { maskedNumber } = this.props;
    return (
      <>
        <div
          style={{
            position: "absolute",
            top: "2%",
            right: "3%"
          }}
        >
          <img onClick={this.close} src={closeIcon}></img>
        </div>
        <form onSubmit={this.handleSubmit}>
          <p
            style={{
              fontSize: "24px",
              color: "#292929",
              fontFamily: "SFPROTEXT",
              marginLeft: "2%"
            }}
          >
            Awesome
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "SFPROTEXT",
              color: "#454545",
              fontSize: "12px"
            }}
          >
            Please enter the OTP that has been sent to {maskedNumber}{" "}
          </p>
          <div className="otpContainer">
            <input
              name="otp1"
              type="text"
              inputmode="numeric"
              autoComplete="off"
              className="otpInput"
              pattern="\d*"
              value={otp1}
              onKeyPress={this.keyPressed}
              onChange={e =>
                e.target.validity.valid ? this.handleChange("otp1", e) : null
              }
              tabIndex="1"
              maxLength="1"
              onKeyUp={e => this.inputfocus(e)}
            />
            <input
              name="otp2"
              type="text"
              pattern="\d*"
              autoComplete="off"
              inputmode="numeric"
              className="otpInput"
              value={otp2}
              onChange={e =>
                e.target.validity.valid ? this.handleChange("otp2", e) : null
              }
              tabIndex="2"
              maxLength="1"
              onKeyUp={e => this.inputfocus(e)}
            />
            <input
              name="otp3"
              type="text"
              autoComplete="off"
              pattern="\d*"
              inputmode="numeric"
              className="otpInput"
              value={otp3}
              onChange={e =>
                e.target.validity.valid ? this.handleChange("otp3", e) : null
              }
              tabIndex="3"
              maxLength="1"
              onKeyUp={e => this.inputfocus(e)}
            />
            <input
              name="otp4"
              type="text"
              pattern="\d*"
              inputmode="numeric"
              autoComplete="off"
              className="otpInput"
              value={otp4}
              onChange={e =>
                e.target.validity.valid ? this.handleChange("otp4", e) : null
              }
              tabIndex="4"
              maxLength="1"
              onKeyUp={e => this.inputfocus(e)}
            />
          </div>
          {this.state.showError ? (
            <p
              style={{
                textAlign: "center",
                fontFamily: "SFPROTEXT",
                color: "#E93F3C",
                fontSize: "12px",
                marginLeft: "1%"
              }}
            >
              WRONG OTP ENTERED
            </p>
          ) : (
            <div
              style={{
                textAlign: "center"
              }}
            >
              <p
                className="d-inline"
                style={{
                  textAlign: "center",
                  fontFamily: "SFPROTEXT",
                  textAlign: "center",
                  color: "#454545",
                  fontSize: "12px"
                }}
              >
                Didn't receive OTP?
              </p>
              {this.state.timer == 0 ? (
                <p
                  onClick={this.resendOTP}
                  className="d-inline"
                  style={{
                    cursor: "pointer",
                    fontFamily: "SFPROTEXT",
                    color: "#7b2bff",
                    fontSize: "12px",
                    marginLeft: "1%"
                  }}
                >
                  Resend OTP
                </p>
              ) : (
                <p
                  className="d-inline"
                  style={{
                    fontFamily: "SFPROTEXT",
                    color: "#454545",
                    fontSize: "12px",
                    marginLeft: "1%"
                  }}
                >
                  Resend in 00:
                  {timer}
                </p>
              )}
            </div>
          )}

          <div
            style={{
              textAlign: "center"
            }}
          >
            <p
              className="d-inline"
              style={{
                fontFamily: "SFPROTEXT",
                color: "#777777",
                fontSize: "12px",
                marginLeft: "1%"
              }}
            >
              {this.state.showError
                ? "Please enter the correct OTP & try again"
                : "OTP will be resent on SMS and WhatsApp"}
            </p>
            {this.state.showError ? null : (
              <img
                style={{ width: "14px", height: "14px", margin: "2%" }}
                src={whatsAppIcon}
              />
            )}
          </div>

          <Button
            onClick={this.verifyOTP}
            className="primary"
            type="submit"
            style={{
              fontFamily: "SFPROTEXT",
              color: "white",
              height: "45px",
              background: "#7B2AFF",
              borderRadius: "10px",
              marginTop: "1.5%",
              width: "100%",
              "margin-bottom": "8%"
            }}
          >
            {this.state.showError ? "Retry" : "Confirm"}
          </Button>
        </form>
      </>
    );
  }
}

export default Otpinput;
