import React from "react";
import { Link } from "react-router-dom";
import "./error504.scss";
import error504 from "../../images/error504.svg";
import logo from "../../images/logo-zingbus.svg";

const Error504 = () => {
  return (
    <div className="container text-center error-container">
      <div className="row w-100">
        <div className="col-12">
          <img className="img-fluid" src={error504} alt="Oops!! Timeout." />
        </div>
        <div className="col-12">
          <div className="w-100 text-center pl-4">
            <span className="medium red">
              Oops!! Something went wrong <b>:(</b>
            </span>
            <p className="py-2">
              An error occured while processing your request. Try again later.
              <br />
              If the error persist, contact{" "}
              <span className="purple bold">zingbus</span>.
            </p>
            <div>
              <span className="bold">
                <Link to="/">Go to Home Page</Link>
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 pt-2">
          <Link to="/">
            <img src={logo} alt="zingbus" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error504;
