import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Track from "../../components/track";
import { Card } from "antd";
import Fetch from "../../utils/fetch";
import moment from "moment";
import {
  getBrowserVisibilityProp,
  getBrowserVisibilityEvent
} from "../../utils/common";

class TeamTrack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tripId: props.match.params.tripId,
      redirect: false,
      busRoute: [],
      tripStatus: null,
      visible: true,
      debug: false
    };
  }

  async componentDidMount() {
    await this.refreshData();

    // const visibilityChange = getBrowserVisibilityEvent();

    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    const pageHiddenKey = getBrowserVisibilityProp();

    this.setState({ visible: document[pageHiddenKey] });
  }

  componentWillUnmount() {
    const visibilityChange = getBrowserVisibilityEvent();

    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }

  handleVisibilityChange = async () => {
    console.log("I am working");

    const pageHiddenKey = getBrowserVisibilityProp();
    document.title = document[pageHiddenKey] ? "hidden" : "yo";

    if (!this.state.visible && !document[pageHiddenKey]) {
      console.log("refreshing page on active");
      await this.refreshData();
    }
  };

  fetchBusRoute = async tripId => {
    try {
      const url = `/bus-tracker/route/${tripId}`;
      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        const tripStatus = response.status;
        const busNumber = response.bus.rc;
        const serviceName = response.service.name;
        const tripDate = response.tripDate;
        const stationsGeoFencingData = response.stationsGeoFencingData;

        if (tripStatus === "ONTRIP") {
          let route = response.busRoute.filter(
            station => station.status === "ACTIVE"
          );

          route = route.map(stopStation => {
            return stopStation.locationType === "DEFAULT"
              ? {
                  name: stopStation.station.name,
                  cityName: stopStation.cityName,
                  coordinates: {
                    lat: stopStation.station.lat,
                    lng: stopStation.station.lng
                  },
                  stationMarked: stopStation.stationMarked,
                  reachingTime: stopStation.reachingTime,
                  stationId: stopStation.station._id,
                  reachedDate: stopStation.reachedDate,
                  stationType: stopStation.station.type,
                  millisFromStart: stopStation.millisFromStart
                }
              : {
                  name: stopStation.station.name,
                  cityName: stopStation.cityName,
                  coordinates: {
                    lat: stopStation.station.oppLat,
                    lng: stopStation.station.oppLng
                  },
                  stationMarked: stopStation.stationMarked,
                  reachingTime: stopStation.reachingTime,
                  stationId: stopStation.station._id,
                  reachedDate: stopStation.reachedDate,
                  stationType: stopStation.station.type,
                  millisFromStart: stopStation.millisFromStart
                };
          });

          return Promise.resolve({
            tripStatus,
            route,
            busNumber,
            serviceName,
            tripDate,
            stationsGeoFencingData
          });
        } else {
          // trip is completed or not started
          return Promise.resolve({
            tripStatus,
            route: [],
            stationsGeoFencingData
          });
        }
      } else {
        throw new Error("No response");
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

  fetchLastLocation = async tripId => {
    try {
      const url = `/bus-tracker/lastlocation/${tripId}`;
      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        throw new Error(response);
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

  refreshData = async () => {
    try {
      console.log("refreshing data");
      const { tripId } = this.state;
      const {
        route,
        tripStatus,
        busNumber,
        serviceName,
        tripDate,
        stationsGeoFencingData
      } = await this.fetchBusRoute(tripId);

      const lastlocation = await this.fetchLastLocation(tripId);
      const busRoute = route;
      const busnumber = busNumber;
      const servicename = serviceName;
      const tripdate = tripDate;
      const query = new URLSearchParams(this.props.location.search);
      const debug = query.get("debug") === "true";

      if (tripStatus === "ONTRIP") {
        const startPoint = busRoute[0];
        const endPoint = busRoute[busRoute.length - 1];
        const wayPoints = busRoute.slice(1, busRoute.length - 1);

        this.setState({
          loading: false,
          busRoute,
          busnumber,
          tripdate,
          servicename,
          lastlocation,
          startPoint,
          endPoint,
          wayPoints,
          stationsGeoFencingData,

          debug
        });
      } else if (tripStatus === "COMPLETED") {
        this.setState({
          redirect: true,
          loading: false,
          debug,
          stationsGeoFencingData
        });
      } else {
        this.setState({
          loading: false,
          tripStatus,
          debug,
          stationsGeoFencingData
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      loading,
      tripId,
      startPoint,
      endPoint,
      busnumber,
      servicename,
      tripdate,
      lastlocation,
      wayPoints,
      redirect,
      tripStatus,
      debug,
      stationsGeoFencingData
    } = this.state;
    if (loading) {
      return <h1>...Loading</h1>;
    }

    if (tripStatus === "ACTIVE") {
      return (
        <>
          <h1>Bus has not started yet</h1>;
        </>
      );
    }

    if (redirect) {
      return <Redirect to={`/report/?tripId=${tripId}`} />;
    }

    return (
      <>
        <div className="site-card-border-less-wrapper">
          <Card
            title="Trip Details"
            bordered={true}
            style={{
              zIndex: 1,
              position: "absolute",
              fontSize: 200,
              right: "00",
              float: "right",
              margin: 10,
              width: "auto",
              padding: 0,
              height: "auto"
            }}
          >
            <p>Service Name: {servicename} </p>
            <p>Trip Date: {moment(tripdate).format("MMM Do")}</p>
            <p>Bus Number: {busnumber}</p>
          </Card>
        </div>
        {/* <div>
          <h2>Trip Details</h2>
          <span>Service Name: Chandhigarh- Lucknow</span>
          <span>Trip Date: 20-09-2020</span>
          <span>Bus Number: ABCZY</span>
        </div> */}
        <br></br>
        <Track
          tripId={tripId}
          startPoint={startPoint}
          endPoint={endPoint}
          lastPoint={lastlocation}
          path={wayPoints}
          refreshData={this.refreshData}
          debug={debug}
          stationsGeoFencingData={stationsGeoFencingData}
        />
      </>
    );
  }
}

export default TeamTrack;
