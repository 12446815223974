import React from "react";
import { googleLayerAnalytics } from "../../../../client/src/utils/googleDataLayer";
import moment from "moment";

const Footer = props => {
  const { booking } = props;
  return (
    <div className="text-center container pt-3">
      <a
        href="https://www.zingbus.com/policies"
        className="blue h4 semi-bold mb-2"
      >
        <button
          className="transparent_btn"
          onClick={() => {
            googleLayerAnalytics("view_policies", {
              mobile_no: booking.mobileNo,
              pnr: booking.bookingCode,
              from_city: booking.boardingPoint.city,
              to_city: booking.dropPoint.city,
              from_station: booking.boardingPoint.address,
              to_station: booking.dropPoint.address,
              booking_source: booking.demandSource,
              trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD")
            });
          }}
        >
          Policies
        </button>
      </a>
      <div className="row mt-2">
        <div className="col-12 bg-light text-center py-3">
          <p className="h4 dark-gray">
            Copyrights Zingbus {new Date().getFullYear()}
          </p>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Footer;
