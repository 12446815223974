import React, { Component } from "react";
import OntripMap from "./onTripMap";
import ActiveMap from "./activeMap";
import moment from "moment";

import styles from "./map.module.scss";

import whatsAppIcon from "../../../images/whatsapp_icon.svg";
import { analytics } from "../../../utils/analytics";
import { googleLayerAnalytics } from "../../../utils/googleDataLayer";

const ga = window.ga;

class CustomerTrack extends Component {
  state = {
    helpButtonClicked: false
  };

  toggleHelpButton = () => {
    const { helpButtonClicked } = this.state;
    this.setState({ helpButtonClicked: !helpButtonClicked });
  };

  contactMenu = () => {
    const { booking } = this.props;
    let enableMessageView = {};
    if (this.props.tourValue === 0) {
      enableMessageView = { zIndex: 101 };
    }

    return (
      <div className={styles.contactMenu} style={enableMessageView}>
        {/* <button className={styles.dropbtn} onClick={this.toggleHelpButton}>
          {helpButtonClicked ? (
            <img className={styles.closeMenu} src={CloseMenuIcon} />
          ) : (
            <img className={styles.open} src={ContactMenuIcon} />
          )}
        </button> */}

        {/* {helpButtonClicked ? ( */}
        {/* <div className={styles.contactMenuContent}> */}
        {/* <a href="tel:7961730277">
              <button
                className={styles.contentBtn}
                onClick={() =>
                  ga("send", "event", "Call Support", "Button Click", {
                    nonInteraction: 1
                  })
                }
              >
                <img src={CallButton} />
              </button>
            </a> */}
        <a href="https://api.whatsapp.com/send?phone=918287009889&text=zingbus">
          <button
            className={styles.contentBtn}
            onClick={() => {
              ga(
                "send",
                "event",
                "Chat Support",
                "Button Clicked",
                "click to text",
                1,
                { nonInteraction: 1 }
              );
              analytics("Invoke Chat", {
                mobile: booking.mobileNo
              });
              googleLayerAnalytics("initiate_chat", {
                mobile_no: booking.mobileNo,
                pnr: booking.bookingCode,
                from_city: booking.boardingPoint.city,
                to_city: booking.dropPoint.city,
                from_station: booking.boardingPoint.address,
                to_station: booking.dropPoint.address,
                booking_source: booking.demandSource,
                trip_date: moment(booking.boardingPoint.time).format(
                  "YYYY-MM-DD"
                )
              });
            }}
          >
            <img src={whatsAppIcon} className={styles.whatsappIcon} />
          </button>
        </a>
        {/* </div> */}
        {/* ) : null} */}
      </div>
    );
  };
  // Moengage.track_event("Invoke Chat", {
  //   "mobile" : "",
  //  });

  getComponentToRender = () => {
    const {
      booking,
      tripStatus,
      isTrackingWorking,
      updateJourneyDetails,
      wayPoints,
      updateETA,
      upcomingStation,
      updateUpcomingStation
    } = this.props;

    switch (tripStatus) {
      case "ONTRIP": {
        return (
          <OntripMap
            tripId={booking.tripId}
            lastPoint={this.props.lastlocation}
            isTrackingWorking={isTrackingWorking}
            fromstation={booking.boardingPoint.address}
            dropPoint={booking.dropPoint.address}
            mapCenter={
              booking.isOnboarded
                ? this.props.lastlocation
                : booking.boardingPoint
            }
            isOnboarded={booking.isOnboarded}
            path={wayPoints}
            updateJourneyDetails={updateJourneyDetails}
            updateETA={updateETA}
            onPassengerBoarded={this.props.onPassengerBoarded}
            upcomingStation={upcomingStation}
            updateUpcomingStation={updateUpcomingStation}
          />
        );
      }

      case "ASSIGNED": {
        return (
          <ActiveMap
            wayPoints={wayPoints}
            isTrackingWorking={isTrackingWorking}
            dropPoint={booking.dropPoint.address}
            fromstation={booking.boardingPoint.address}
          />
        );
      }
      case "ACTIVE": {
        return (
          <ActiveMap
            wayPoints={wayPoints}
            isTrackingWorking={isTrackingWorking}
            dropPoint={booking.dropPoint.address}
            fromstation={booking.boardingPoint.address}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  render() {
    return (
      <>
        {this.contactMenu()}
        {this.getComponentToRender()}
      </>
    );
  }
}

export default CustomerTrack;
