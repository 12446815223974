import React, { useState, useEffect } from "react";
import { Tag } from "antd";

const formatTime = millis => {
  const mins = Math.floor(millis / (1000 * 60));
  const secs = Math.floor((millis / 1000) % 60);

  let timeString = "";

  if (mins < 10) {
    timeString += `0${mins}:`;
  } else {
    timeString += `${mins}:`;
  }

  if (secs < 10) {
    timeString += `0${secs}`;
  } else {
    timeString += `${secs}`;
  }

  return timeString;
};

const Timer = props => {
  const [timeLeft, updateTime] = useState(props.expireAt - Date.now());
  let intervalId;

  useEffect(() => {
    if (timeLeft > 0) {
      intervalId = setInterval(() => {
        const newTime = props.expireAt - Date.now();

        if (newTime < 0) {
          return clearInterval(intervalId);
        }

        updateTime(props.expireAt - Date.now());
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {timeLeft > 1000 ? formatTime(timeLeft) : <Tag color="red">Expired</Tag>}
    </div>
  );
};

export default Timer;
