function googleLayerAnalytics(eventName, data) {
  try {
    if (window) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ parameters: null });
      window.dataLayer.push({
        event: eventName,
        parameters: data
      });
    }
  } catch (error) {
    console.log(error);
  }
}
export { googleLayerAnalytics };
